// Viewer library version 1.0.0 20240820
//
// 1. Removed typograph_init and loading of it
// 2. Converted to ES6 module
// 3. Removed quote from startupscreen_html
// 4. Changed window.dispatchEvent in `window.dispatchEvent(event);` to g_typograph_document.canvas_view.canvas_element.dispatchEvent
//
// (() => {
  // src/colors.ts
  var colorlibrary = class {
    constructor() {
      this.colors = [];
    }
    empty() {
      this.colors = [];
    }
    length() {
      return this.colors.length;
    }
    addColor(color2) {
      if (!this.hasColor(color2))
        this.colors.push(color2);
    }
    addRGBA(rgba) {
      let c = { name: rgba, rgba };
      if (!this.hasColor(c))
        this.addColor(c);
    }
    addNameRGBA(name, rgba) {
      let c = { name, rgba };
      if (!this.hasColor(c))
        this.addColor(c);
    }
    setcolors(colors) {
      this.colors = colors;
    }
    hasColor(c) {
      for (let i = 0; i < this.colors.length; i++) {
        if (this.colors[i].name == c.name && this.colors[i].rgba == c.rgba) {
          return true;
        }
      }
      return false;
    }
    get_index(name) {
      for (let i = 0; i < this.colors.length; i++) {
        if (this.colors[i].name == name)
          return i;
      }
      return -1;
    }
    get_rgba(index) {
      return this.colors[index].rgba;
    }
  };

  // src/colorconversion.ts
  function HEXtoRGB(hex) {
    if (hex.indexOf("rgb(") == 0) {
      let s = hex.substr(4, hex.length);
      s = s.replace(/ /g, "");
      s = s.substr(0, s.length - 1);
      let rgb_strings = s.split(",");
      let rgb = [];
      for (let i = 0; i < rgb_strings.length; i++)
        rgb.push(parseInt(rgb_strings[i]));
      if (rgb_strings.length < 3) {
        for (let i = rgb_strings.length - 1; i < 3; i++) {
          rgb.push(0);
        }
      }
      return rgb;
    }
    if (hex.length == 4) {
      let s = hex[0];
      s += hex[1];
      s += hex[1];
      s += hex[2];
      s += hex[2];
      s += hex[3];
      s += hex[3];
      hex = s;
    }
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      let rgb = [];
      rgb[0] = parseInt(result[1], 16);
      rgb[1] = parseInt(result[2], 16);
      rgb[2] = parseInt(result[3], 16);
      return rgb;
    }
    return null;
  }

  // src/gradient.ts
  var Sample = class _Sample {
    constructor(pos, color2, opacity = 1) {
      this.pos = pos;
      this.color = color2;
      this.opacity = opacity;
    }
    copy() {
      let sample = new _Sample(this.pos, this.color, this.opacity);
      return sample;
    }
  };
  var Gradient = class _Gradient {
    constructor(type, rotation, samples) {
      this.type = type;
      this.rotation = rotation;
      this.samples = samples;
    }
    copy() {
      let sampels = [];
      for (let i = 0; i < this.samples.length; i++) {
        let sample = this.samples[i].copy();
        sampels.push(sample);
      }
      let gradient = new _Gradient(this.type, this.rotation, sampels);
      return gradient;
    }
    update(s) {
      this.samples = [];
      for (let i = 0; i < s.length; i++) {
        let sample = s[i];
        this.samples.push(sample);
      }
    }
    cssbackground() {
      this.samples.sort((a, b) => a.pos > b.pos ? 1 : -1);
      let type = this.type + "-gradient";
      let rotation = this.rotation + "deg";
      let shape = "";
      if (this.type == "radial") {
        shape = "circle at center";
        rotation = "";
      }
      let colors = "";
      for (let s = 0; s < this.samples.length; s++) {
        let sample = this.samples[s];
        let color2 = sample.color;
        let rgb = HEXtoRGB(color2.rgba);
        if (rgb == null)
          rgb = [0, 0, 0];
        colors += "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + "," + sample.opacity + ") " + sample.pos + "%";
        if (s < this.samples.length - 1)
          colors += ",";
      }
      let cssgradient = type + "(" + rotation + shape + "," + colors + ")";
      return cssgradient;
    }
    canvasgradiant() {
      this.samples.sort((a, b) => a.pos > b.pos ? 1 : -1);
    }
  };

  // src/control_defaults.ts
  var kDefaultFontFamaliy = "'Source Sans Pro', LucidaGrande, Helvetica, sans-serif";
  var kDefaultFontName = "Source Sans Pro";
  var kDefaultFontStyle = "regular";
  var kDefaultFontColor = "#000";
  var kDefaultCornerRadius = 4;
  var kDefaultActiveColor = "#1071e5";
  var kAlertSVGURI = "%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg%3E%3Cpath d='M10.7,3.2c0.6-1,2-1,2.6,0l8.9,15.5c0.6,1-0.1,2.2-1.3,2.2H3.1c-1.2,0-1.9-1.2-1.3-2.2L10.7,3.2z' fill-rule='evenodd' clip-rule='evenodd' fill='%23FFFFFF' stroke='%23E72100' stroke-width='1.5' stroke-miterlimit='10'%3E%3C/path%3E%3Cpath d='M11.5,17.3c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.3 c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5C11.3,17,11.4,17.2,11.5,17.3z' stroke='%23000000' stroke-width='0.5' stroke-miterlimit='10'%3E%3C/path%3E%3Cpath d='M12.5,10.1c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5l0.1,4.1c0,0.2,0.2,0.4,0.4,0.4 c0.2,0,0.4-0.2,0.4-0.4L12.5,10.1z' stroke='%23000000' stroke-width='0.5' stroke-miterlimit='10'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
  var kAddClippingSVGURI = "%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='22' viewBox='0 0 28 22'%3E%3Cg%3E%3Cpath d='M2,20V7c0-2.8,2.2-5,5-5h14c2.8,0,5,2.2,5,5v13' fill='%23FFFFFF' stroke='%2351C1FD' stroke-width='1' stroke-miterlimit='10'%3E%3C/path%3E%3Cpath d='M14.7,4.6v5.8h5.6v1.5h-5.6v5.9h-1.6v-5.9H7.5v-1.5h5.6V4.6H14.7z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
  var kAddClippingDisabledSVGURI = "%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='22' viewBox='0 0 28 22'%3E%3Cg%3E%3Cpath d='M2,20V7c0-2.8,2.2-5,5-5h14c2.8,0,5,2.2,5,5v13' fill='%23FFFFFF' stroke='%2351C1FD' stroke-width='1' stroke-miterlimit='10'%3E%3C/path%3E%3Cpath d='M14.7,4.6v5.8h5.6v1.5h-5.6v5.9h-1.6v-5.9H7.5v-1.5h5.6V4.6H14.7z' fill='%23DDDDDD'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
  var kRemoveClippingRightSVGURI = "%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='26' viewBox='0 0 20 26'%3E%3Cg%3E%3Cpath d='M1,1h13c2.8,0,5,2.2,5,5v14c0,2.8-2.2,5-5,5H1' fill='%23FFFFFF' stroke='%2351C1FD' stroke-width='1' stroke-miterlimit='10'%3E%3C/path%3E%3Cpath d='M3.5 12.4H16.3V13.9H3.5z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";

  // src/richtext.ts
  var ElementManager = class _ElementManager {
    constructor(defaultFont) {
      this.defaultFont = defaultFont;
      this.elements = [];
      this.current = null;
    }
    copy() {
      let elelementmanager = new _ElementManager(this.defaultFont);
      for (let e = 0; e < this.elements.length; e++) {
        let el = this.elements[e];
        if (this.elements[e] == this.current)
          elelementmanager.current = el;
        let new_element = { font: el.font, text: el.text, words: el.words, enumerable: el.enumerable, writable: el.writable, max_word_height: el.max_word_height, max_ascent: el.max_ascent, max_descent: el.max_descent, url: el.url, hover_attributes: el.hover_attributes };
        this.reword_element(new_element);
        elelementmanager.elements.push(new_element);
      }
      return elelementmanager;
    }
    length() {
      return this.elements.length;
    }
    at(index) {
      return this.elements[index];
    }
    addElement(el) {
      this.elements.push(el);
    }
    createElement(font3) {
      if (!font3)
        font3 = this.defaultFont;
      return this.new_element(font3);
    }
    getCurrentElement(font3 = null) {
      if (!this.current) {
        this.current = this.createElement(font3);
        this.elements.push(this.current);
      }
      return this.current;
    }
    getPreviousElement(el) {
      let index = this.elements.indexOf(el);
      if (index)
        return this.elements[index - 1];
      return null;
    }
    getNextElement(el) {
      let index = this.elements.indexOf(el);
      if (index < this.elements.length)
        return this.elements[index + 1];
      return null;
    }
    removeElement(el) {
      let index = this.elements.indexOf(el);
      if (index != -1)
        this.elements.splice(index, 1);
    }
    splitElement(el, offset) {
      let newEl = this.new_element(el.font);
      let index = this.elements.indexOf(el);
      this.elements.splice(index + 1, 0, newEl);
      newEl.text = el.text.substr(offset);
      el.text = el.text.substr(0, offset);
      this.reword_element(el);
      this.reword_element(newEl);
      return newEl;
    }
    new_word(prefix, word, offset, font3) {
      let prefixMetrics = null;
      let wordMetrics = null;
      let endOffset = offset;
      let width = 0;
      let height = 0;
      if (prefix.length || word.length) {
        prefixMetrics = RichText.measureText(prefix, font3.text);
        wordMetrics = RichText.measureText(word, font3.text);
        if (prefix.length)
          offset -= prefix.length;
        if (word.length)
          offset -= word.length;
        endOffset = offset;
        width = prefixMetrics.width + wordMetrics.width;
        height = Math.max(prefixMetrics.height, wordMetrics.height);
      }
      let text = prefix + word;
      let wrapped = false;
      let element3 = null;
      let lineBreak = false;
      if (text.length == 0)
        lineBreak = true;
      let wordobject3 = { prefix, word, offset, font: font3, prefix_metrics: prefixMetrics, word_metrics: wordMetrics, end_offset: endOffset, width, height, text, line_break: lineBreak, wrapped, element: element3 };
      return wordobject3;
    }
    new_element(font3) {
      let text = "";
      let words = [];
      let enumerable = false;
      let writable = true;
      let max_word_height = 0;
      let max_ascent = 0;
      let max_descent = 0;
      let url = "";
      let hover_attributes = [];
      let element3 = { font: font3, text, words, enumerable, writable, max_word_height, max_ascent, max_descent, url, hover_attributes };
      return element3;
    }
    reword_element(el) {
      el.words = [];
      el.max_word_height = 0;
      el.max_ascent = 0;
      el.max_descent = 0;
      let prefix = "";
      let word = "";
      for (let i = 0; i < el.text.length; ++i) {
        let c = el.text.charAt(i);
        if (c == " ") {
          if (word.length) {
            let wordobj = this.new_word(prefix, word, i, el.font);
            wordobj.element = el;
            el.max_ascent = Math.max(el.max_ascent, wordobj.word_metrics.ascent);
            el.max_descent = Math.max(el.max_descent, wordobj.word_metrics.descent);
            el.words.push(wordobj);
            prefix = "";
            word = "";
          }
          prefix += c;
        } else {
          if (c == "\n") {
            if (prefix.length || word.length) {
              let wordobj2 = this.new_word(prefix, word, i, el.font);
              wordobj2.element = el;
              el.max_ascent = Math.max(el.max_ascent, wordobj2.word_metrics.ascent);
              el.max_descent = Math.max(el.max_descent, wordobj2.word_metrics.descent);
              el.words.push(wordobj2);
            }
            let wordobj = this.new_word("", "", 0, null);
            wordobj.element = el;
            el.words.push(wordobj);
            prefix = "";
            word = "";
          } else {
            word += c;
          }
        }
      }
      if (prefix.length || word.length) {
        let wordobj = this.new_word(prefix, word, el.text.length, el.font);
        el.max_ascent = Math.max(el.max_ascent, wordobj.word_metrics.ascent);
        el.max_descent = Math.max(el.max_descent, wordobj.word_metrics.descent);
        el.words.push(wordobj);
      }
    }
    insertElementAt(el, loc3) {
      if (loc3.element.text.length == loc3.offset) {
        let index = this.elements.indexOf(loc3.element);
        this.elements.splice(index + 1, 0, el);
      } else {
        if (loc3.offset == 0) {
          let index = this.elements.indexOf(loc3.element);
          this.elements.splice(Math.min(index - 1, 0), 0, el);
        } else {
          this.splitElement(loc3.element, loc3.offset);
          let index = this.elements.indexOf(loc3.element);
          this.elements.splice(index + 1, 0, el);
        }
      }
    }
    isElementInsideRange(el, fromElement, toElement) {
      let fromIndex = this.elements.indexOf(fromElement);
      let toIndex = this.elements.indexOf(toElement);
      if (fromIndex == -1 || toIndex == -1)
        return false;
      let index = this.elements.indexOf(el);
      if (index >= fromIndex && index <= toIndex)
        return true;
      return false;
    }
    deleteRange(fromLoc, toLoc) {
      let elements = [];
      let newLoc = { element: null, x: 0, y: 0, offset: 0, line: null };
      for (let i = 0; i < this.elements.length; i++) {
        let el = this.elements[i];
        if (this.isElementInsideRange(el, fromLoc.element, toLoc.element)) {
          if (el != fromLoc.element && el != toLoc.element) {
          } else {
            if (el == fromLoc.element && el == toLoc.element) {
              let text = fromLoc.element.text;
              fromLoc.element.text = text.substr(0, fromLoc.offset) + text.substr(toLoc.offset, text.length - toLoc.offset);
              this.reword_element(fromLoc.element);
              elements.push(fromLoc.element);
              newLoc.element = fromLoc.element;
              newLoc.offset = fromLoc.offset;
            } else {
              if (el == fromLoc.element) {
                let text = fromLoc.element.text;
                fromLoc.element.text = text.substr(0, fromLoc.offset);
                this.reword_element(fromLoc.element);
                elements.push(fromLoc.element);
                newLoc.element = fromLoc.element;
                newLoc.offset = fromLoc.offset;
              } else {
                if (el == toLoc.element) {
                  let text = toLoc.element.text;
                  toLoc.element.text = text.substr(toLoc.offset, text.length - toLoc.offset);
                  this.reword_element(toLoc.element);
                  elements.push(toLoc.element);
                }
              }
            }
          }
        } else {
          elements.push(el);
        }
      }
      this.elements = elements;
      return newLoc;
    }
    applyFontToRange(font3, fromLoc, toLoc) {
      let elements = [];
      let newLoc = { element: null, x: 0, y: 0, offset: 0, line: null };
      let newFromElement = this.new_element(this.defaultFont);
      let newFromOffset = 0;
      let newToElement = this.new_element(this.defaultFont);
      let newToOffset = 0;
      for (let i = 0; i < this.elements.length; ++i) {
        let el = this.elements[i];
        if (this.isElementInsideRange(el, fromLoc.element, toLoc.element)) {
          if (el != fromLoc.element && el != toLoc.element) {
            el.font = font3;
            this.reword_element(el);
            elements.push(el);
          } else {
            if (el == fromLoc.element && el == toLoc.element) {
              if (fromLoc.offset == 0 && toLoc.offset == el.text.length) {
                el.font = font3;
                this.reword_element(el);
                elements.push(el);
                newLoc.element = el;
                newLoc.offset = el.text.length;
              } else {
                let text = el.text;
                if (fromLoc.offset) {
                  el.text = text.substr(0, fromLoc.offset);
                  this.reword_element(el);
                  elements.push(el);
                }
                let middleEl = this.createElement(font3);
                middleEl.text = text.substr(fromLoc.offset, toLoc.offset - fromLoc.offset);
                this.reword_element(middleEl);
                elements.push(middleEl);
                newFromOffset = 0;
                newFromElement = middleEl;
                if (text.length - toLoc.offset) {
                  let endEl = this.createElement(toLoc.element.font);
                  endEl.text = text.substr(toLoc.offset, text.length - toLoc.offset);
                  this.reword_element(endEl);
                  elements.push(endEl);
                }
                newToOffset = middleEl.text.length;
                newToElement = middleEl;
                newLoc.element = middleEl;
                newLoc.offset = middleEl.text.length;
              }
            } else {
              if (el == fromLoc.element) {
                let text = el.text;
                if (fromLoc.offset == 0)
                  el.font = font3;
                else {
                  if (fromLoc.offset > 0)
                    el.text = text.substr(0, fromLoc.offset);
                }
                this.reword_element(el);
                elements.push(el);
                if (fromLoc.offset > 0) {
                  let middleEl = this.createElement(font3);
                  middleEl.text = text.substr(fromLoc.offset, text.length - fromLoc.offset);
                  this.reword_element(middleEl);
                  elements.push(middleEl);
                  newFromOffset = 0;
                  newFromElement = middleEl;
                }
              } else {
                if (el == toLoc.element) {
                  let text = el.text;
                  let oldFont = el.font;
                  el.text = text.substr(0, toLoc.offset);
                  el.font = font3;
                  this.reword_element(el);
                  elements.push(el);
                  newToOffset = el.text.length;
                  newToElement = el;
                  newLoc.offset = el.text.length;
                  newLoc.element = el;
                  if (toLoc.offset < text.length) {
                    let middleEl = this.createElement(oldFont);
                    middleEl.text = text.substr(toLoc.offset, text.length - toLoc.offset);
                    this.reword_element(middleEl);
                    elements.push(middleEl);
                  }
                }
              }
            }
          }
        } else {
          elements.push(el);
        }
      }
      this.elements = elements;
      if (newFromElement) {
        fromLoc.element = newFromElement;
        fromLoc.offset = newFromOffset;
      }
      if (newToElement) {
        toLoc.element = newToElement;
        toLoc.offset = newToOffset;
      }
      return newLoc;
    }
    getRange(fromLoc, toLoc) {
      let elements = [];
      for (let i = 0; i < this.elements.length; i++) {
        let el = this.elements[i];
        if (this.isElementInsideRange(el, fromLoc.element, toLoc.element)) {
          if (el != fromLoc.element && el != toLoc.element) {
            elements.push(el);
          } else {
            if (el == fromLoc.element && el == toLoc.element) {
              if (fromLoc.offset == 0 && toLoc.offset == el.text.length) {
                elements.push(el);
              } else {
                let text = el.text;
                let element3 = this.createElement(el.font);
                element3.text = text.substr(fromLoc.offset, toLoc.offset - fromLoc.offset);
                elements.push(element3);
              }
            } else {
              if (el == fromLoc.element) {
                let text = el.text;
                let element3 = this.createElement(el.font);
                element3.text = text.substr(fromLoc.offset, text.length - fromLoc.offset);
                elements.push(element3);
              } else {
                if (el == toLoc.element) {
                  let text = el.text;
                  let element3 = this.createElement(el.font);
                  element3.text = text.substr(0, toLoc.offset);
                  elements.push(element3);
                }
              }
            }
          }
        }
      }
      return elements;
    }
    createFontForRange(fromLoc, toLoc) {
      let attr = { bold: false, italic: false };
      let format = { margin: [], tag: "" };
      let font3 = { name: "", size: 0, formatting: format, attributes: attr, style: "", text: "", font_style: "" };
      for (let i = 0; i < this.elements.length; i++) {
        let el = this.elements[i];
        if (this.isElementInsideRange(el, fromLoc.element, toLoc.element)) {
          let elFont = el.font;
          font3.name = elFont.name;
          font3.text = elFont.text;
          font3.size = elFont.size;
          font3.style = elFont.style;
          font3.attributes.bold = elFont.attributes.bold;
          font3.attributes.italic = elFont.attributes.italic;
          font3.font_style = elFont.font_style;
        }
      }
      return font3;
    }
    clean() {
      let elements = [];
      for (let i = 0; i < this.elements.length; i++) {
        let el = this.elements[i];
        if (el.words.length > 0)
          elements.push(el);
      }
      this.elements = elements;
    }
    rewordElement(el) {
      this.reword_element(el);
    }
  };
  var line = class _line {
    constructor() {
      this.words = [];
      this.max_height = 0;
      this.max_ascent = 0;
      this.max_descent = 0;
      this.max_width = 0;
      this.offset = 0;
      this.symbol = "";
    }
    copy() {
      let theline = new _line();
      for (let w = 0; w < this.words.length; w++) {
        let word = this.words[w];
        theline.words.push(word);
      }
      theline.max_height = this.max_height;
      theline.max_ascent = this.max_ascent;
      theline.max_descent = this.max_descent;
      theline.max_width = this.max_width;
      theline.offset = this.offset;
      theline.symbol = this.symbol;
      return theline;
    }
  };
  var Editor = class _Editor {
    constructor(size, defaultFont, tagList, elementmanager = null) {
      this.size = size;
      this.defaultFont = defaultFont;
      this.tagList = tagList;
      this.elementmanager = elementmanager;
      this.blinkStateChanged = Date.now();
      this.redrawAt = Date.now();
      this.scrollBarWidth = 0;
      this.screenOffsetX = 0;
      this.screenOffsetY = 0;
      this.linkStyle = "";
      this.scale = 1;
      this.screen_X = 0;
      this.screen_Y = 0;
      this.ignore_input = false;
      this.alignment = "Left";
      if (this.elementmanager == null)
        this.elementmanager = new ElementManager(defaultFont);
      this.selectionStyle = "#b2d0ee";
      this.readOnly = false;
      this.wordWrap = true;
      this.cursorLocation = { element: null, x: 0, y: 0, offset: 0, line: null };
      this.handleOffset = 0;
      this.handleDragOffset = 0;
      this._redraw = () => this.draw();
      this._fontChanged = () => {
      };
      this._contentChanged = () => {
      };
      this._gotoUrl = () => {
      };
      this._scrollBarFunc = () => {
      };
      this.vOffset = 0;
      this.focus = false;
      this.clearBackground = true;
      this.defaultFontHeight = RichText.measureText("H", defaultFont.text).height;
      this.mouseIsDown = false;
      this.blinkState = false;
      this.needsScrollBar = false;
      this.scrollAction = void 0;
      this.handleRect = { x: 0, y: 0, width: 0, height: 0 };
      this.hoverElement = null;
      this.width = this.size.width;
      this.height = this.size.height;
      this.text_over_flow_alert = new Image();
      this.text_over_flow_alert.src = "data:image/svg+xml," + kAlertSVGURI;
    }
    copy() {
      let elementmanager = this.elementmanager.copy();
      let editor = new _Editor(this.size, this.defaultFont, this.tagList, elementmanager);
      editor.selectionStyle = this.selectionStyle;
      editor.readOnly = this.readOnly;
      editor.wordWrap = this.wordWrap;
      editor.alignment = this.alignment;
      let theline = null;
      for (let l = 0; l < this.lines.length; l++) {
        let line3 = this.lines[l].copy();
        if (line3 == this.cursorLocation.line)
          theline = line3;
      }
      editor.cursorLocation = { element: this.cursorLocation.element, x: this.cursorLocation.x, y: this.cursorLocation.y, offset: this.cursorLocation.offset, line: theline };
      editor.handleOffset = this.handleOffset;
      editor.handleDragOffset = this.handleDragOffset;
      editor.vOffset = this.vOffset;
      editor.focus = this.focus;
      editor.clearBackground = this.clearBackground;
      editor.defaultFontHeight = this.defaultFontHeight;
      editor.alignment = this.alignment;
      editor.ignore_input = this.ignore_input;
      editor.width = this.width;
      editor.height = this.height;
      editor.linalyze();
      return editor;
    }
    setAlignment(alignment) {
      if (this.alignment != alignment) {
        this.alignment = alignment;
        this.linalyze();
        if (g_typograph_document.pages.length > 0)
          g_typograph_document.redraw();
      }
    }
    setFocus(focus) {
      if (this.focus == focus)
        return;
      this.focus = focus;
      this.mouseIsDown = false;
      if (focus) {
        this.resetBlinkState();
        this.updateBlinkState();
        g_typograph_document.redraw();
      } else {
        this.blinkState = false;
        g_typograph_document.canvas_view.canvas_element.dispatchEvent(new Event("typograph_edit_done"));
        g_typograph_document.redraw();
      }
    }
    hasFocus() {
      return this.focus;
    }
    //set the redraw callback
    setRedraw(func) {
      this._redraw = func;
    }
    setContentChanged(func) {
      this._contentChanged = func;
    }
    setGotoUrl(func) {
      this._gotoUrl = func;
    }
    setFontChanged(func) {
      this._fontChanged = func;
    }
    deleteSelection() {
      if (this.selection) {
        this.keyDown("Backspace");
      }
    }
    setScrollBar(width, func) {
      this.scrollBarWidth = width;
      this._scrollBarFunc = func;
    }
    setCursor(offset) {
      if (this.elementmanager.length()) {
        let loc3 = { element: null, x: 0, y: 0, offset, line: null };
        this.elementmanager.current = loc3.element;
        this.cursorLocation = loc3;
      }
    }
    setFont(font3) {
      if (this.selection) {
        this.cursorLocation = this.elementmanager.applyFontToRange(font3, this.selectionStart, this.selectionEnd);
        this.linalyze();
        this.elementmanager.current = this.cursorLocation.element;
        this.selectionEnd = this.getPositionForElementOffset(this.selectionEnd.element, this.selectionEnd.offset);
        this.cursorLocation = this.getPositionForElementOffset(this.cursorLocation.element, this.cursorLocation.offset);
        this.resetBlinkState();
        this._contentChanged(this, "Edit");
        this.selection = false;
      } else {
        if (this.elementmanager.elements.length == 0) {
          if (this.elementmanager.current && !this.elementmanager.current.words) {
            this.elementmanager.current.font = Object.assign({}, font3);
          } else {
            let el = this.elementmanager.createElement(font3);
            if (!this.cursorLocation.element)
              this.cursorLocation.element = el;
            this.elementmanager.insertElementAt(el, this.cursorLocation);
            this.elementmanager.current = el;
            this.cursorLocation.offset = 0;
          }
        } else {
          let elements = this.elementmanager.elements;
          this.selectionStart = {
            element: elements[0],
            x: 0,
            y: 0,
            offset: 0,
            line: this.lines[0]
          };
          let max_y = 0;
          let offset = 0;
          for (let i = 0; i < this.lines.length; i++) {
            let l = this.lines[i];
            max_y += l.max_height;
            offset += l.offset;
          }
          let end_x = this.lines[this.lines.length - 1].max_width;
          this.selectionEnd = {
            element: elements[elements.length - 1],
            x: end_x,
            y: max_y,
            offset: elements[elements.length - 1].text.length,
            line: this.lines[this.lines.length - 1]
          };
          this.cursorLocation = this.elementmanager.applyFontToRange(font3, this.selectionStart, this.selectionEnd);
          this.linalyze();
          this.elementmanager.current = this.cursorLocation.element;
          g_typograph_document.redraw();
        }
      }
    }
    resetBlinkState() {
      this.blinkState = true;
      this.blinkStateChanged = Date.now();
    }
    updateBlinkState() {
      if (this.readOnly)
        return;
      this.redrawAt = Date.now();
      setTimeout(function() {
        let time = Date.now();
        if (this.focus && time - this.blinkStateChanged >= 600) {
          this.blinkState = !this.blinkState;
          this.blinkStateChanged = time;
          g_typograph_document.redraw();
        }
      }.bind(this), 600);
    }
    /*
     * Finds the x, y position for the text offset inside the given element and returnd position
    */
    getPositionForElementOffset(el, offset) {
      let location = { element: null, x: 0, y: 0, offset: 0, line: null };
      let x = 0;
      let y = 0;
      let off = 0;
      let lastOffset;
      for (let l = 0; l < this.lines.length; l++) {
        let line3 = this.lines[l];
        x = line3.offset;
        for (let w = 0; w < line3.words.length; w++) {
          let lWord = line3.words[w];
          if (lWord.element == el) {
            let text = lWord.text;
            if (lWord.wrapped)
              x -= lWord.prefix_metrics.width;
            if (lWord.offset <= offset && lWord.offset + text.length >= offset) {
              for (let i = 0; i < text.length; i++) {
                let c = text.charAt(i);
                x += RichText.measureText(c, lWord.element.font.text).width;
                if (lWord.offset + i + 1 == offset) {
                  location.x = x;
                  location.y = y;
                  location.line = line3;
                  location.element = el;
                  location.offset = offset;
                  return location;
                }
              }
            }
            lastOffset = lWord.end_offset;
          }
          x += lWord.width;
        }
        y += line3.max_height;
        if (lastOffset)
          ++lastOffset;
        if (lastOffset == offset) {
          location.x = line3.offset;
          location.y = y;
          location.offset = offset;
          location.line = line3;
          location.element = el;
        }
        if (lastOffset == void 0) {
          location.x = line3.offset;
          location.y = y - line3.max_height;
          location.offset = line3.offset;
          location.line = line3;
          location.element = el;
        }
      }
      return location;
    }
    /*
     * Finds the text location for the given x, y coordinate.
    */
    getLocationForMousePos(pos) {
      let x = 0;
      let y = 0;
      pos.x /= this.scale;
      pos.y /= this.scale;
      let rc = { element: null, x: 0, y: 0, offset: 0, line: null };
      for (let l = 0; l < this.lines.length; ++l) {
        let line3 = this.lines[l];
        x = line3.offset;
        if (pos.y >= y && pos.y <= y + line3.max_height || l === this.lines.length - 1) {
          rc.line = line3;
          rc.y = y;
          for (let w = 0; w < line3.words.length; ++w) {
            let lWord = line3.words[w];
            let d;
            if (lWord.wrapped)
              d = lWord.word_metrics.width;
            else
              d = lWord.width;
            if (pos.x >= x && pos.x <= x + d) {
              rc.element = lWord.element;
              rc.offset = lWord.offset;
              let text = lWord.text;
              if (lWord.wrapped)
                x -= lWord.prefix_metrics.width;
              for (let i = 0; i < text.length; ++i) {
                let c = text.charAt(i);
                let cw = RichText.measureText(c, lWord.element.font.text).width;
                if (pos.x <= x + cw / 2) {
                  rc.x = x;
                  break;
                } else {
                  x += cw;
                  rc.offset++;
                  if (i == text.length - 1) {
                    rc.x = x;
                  }
                }
              }
            }
            x += d;
          }
          if (rc.element == null) {
            if (line3.words.length === 0) {
              let prev = l - 1;
              let lastWord = null;
              let lineOffset = 1;
              while (prev >= 0) {
                let prevLine = this.lines[prev];
                if (prevLine.words.length) {
                  lastWord = prevLine.words[prevLine.words.length - 1];
                  break;
                }
                lineOffset += 1;
                prev--;
              }
              if (lastWord) {
                rc.element = lastWord.element;
                rc.offset = lastWord.end_offset + lineOffset;
              } else {
                rc.element = void 0;
                rc.offset = 0;
              }
            } else {
              if (line3.words.length) {
                let lastWord = line3.words[line3.words.length - 1];
                rc.element = lastWord.element;
                rc.offset = lastWord.end_offset;
              }
            }
            rc.x = line3.offset + line3.max_width;
          }
          return rc;
        }
        if (l < this.lines.length - 1) {
          x = 0;
          y += line3.max_height;
        }
      }
      return rc;
    }
    getWordLocationForMousePos(pos) {
      let x = 0;
      let y = 0;
      pos.x /= this.scale;
      pos.y /= this.scale;
      for (let l = 0; l < this.lines.length; ++l) {
        let line3 = this.lines[l];
        x = line3.offset;
        if (pos.y >= y && pos.y <= y + line3.max_height || l === this.lines.length - 1) {
          for (let w = 0; w < line3.words.length; ++w) {
            let lWord = line3.words[w];
            let d;
            if (lWord.wrapped)
              d = lWord.word_metrics.width;
            else
              d = lWord.width;
            if (lWord.wrapped)
              x -= lWord.prefix_metrics.width;
            if (pos.x >= x && pos.x <= x + d) {
              return {
                element: lWord.element,
                start: x,
                end: x + d,
                y,
                line: line3,
                word: lWord
              };
            }
            x += d;
          }
        }
        if (l < this.lines.length - 1) {
          x = 0;
          y += line3.max_height;
        }
      }
      return null;
    }
    linalyze() {
      this.lines = [];
      this.maxHeight = 0;
      let width = this.width;
      let remaining = width;
      let myline = new line();
      let lastEl;
      let formatTag = "";
      this.elementmanager.clean();
      for (let i = 0; i < this.elementmanager.elements.length; ++i) {
        let el = this.elementmanager.elements[i];
        lastEl = el;
        let format = { margin: [], tag: "" };
        if (el.font.formatting != void 0) {
          if (el.font.formatting.tag && formatTag !== el.font.formatting.tag) {
            let formatting = el.font.formatting;
            formatTag = formatting.tag;
            this.pushLine(el, myline);
            myline = new line();
            let margin = formatting.margin;
            remaining = width - margin[0] - margin[2];
            myline.offset = formatting.margin[0];
            myline.symbol = "circle";
          }
        }
        if (!el.font.formatting && formatTag) {
          formatTag = void 0;
          this.pushLine(el, myline);
          myline = new line();
          this.pushLine(el, myline);
          myline = new line();
          remaining = width;
        }
        for (let w = 0; w < el.words.length; ++w) {
          let word = el.words[w];
          word.wrapped = false;
          if (word.line_break) {
            if (this.alignment == "Center") {
              myline.offset += remaining / 2;
            }
            if (this.alignment == "Right") {
              myline.offset += remaining;
            }
            this.pushLine(el, myline);
            myline = new line();
            remaining = width;
            if (el.font.formatting.tag != "" || el.font.formatting.margin.length != 0) {
              myline.offset = el.font.formatting.margin[0];
              remaining -= myline.offset + el.font.formatting.margin[2];
              myline.symbol = "circle";
            }
            continue;
          }
          if (this.wordWrap && word.width > remaining) {
            if (this.alignment == "Center") {
              myline.offset += remaining / 2;
            }
            if (this.alignment == "Right") {
              myline.offset += remaining;
            }
            this.lines.push(myline);
            this.maxHeight += myline.max_height;
            myline = new line();
            remaining = width;
            word.wrapped = true;
            if (el.font.formatting) {
              myline.offset = 0;
              remaining -= myline.offset;
            }
          }
          if (word.width <= remaining) {
            word.element = el;
            myline.words.push(word);
            myline.max_height = Math.max(myline.max_height, word.height);
            myline.max_ascent = Math.max(myline.max_ascent, el.max_ascent);
            myline.max_descent = Math.max(myline.max_descent, el.max_descent);
            if (word.wrapped) {
              remaining -= word.word_metrics.width;
              myline.max_width += word.word_metrics.width;
            } else {
              remaining -= word.width;
              myline.max_width += word.width;
            }
          }
        }
      }
      if (lastEl) {
        if (myline.max_ascent === 0)
          myline.max_ascent = lastEl.max_ascent;
        if (myline.max_descent === 0)
          myline.max_descent = lastEl.max_descent;
        if (myline.max_height === 0)
          myline.max_height = lastEl.max_word_height ? lastEl.max_word_height : lastEl.max_ascent;
      }
      if (lastEl) {
        if (lastEl.words.length === 0) {
          myline.max_ascent = this.defaultFontHeight;
          myline.max_height = this.defaultFontHeight;
        }
      }
      this.maxHeight += myline.max_height;
      if (this.alignment == "Center") {
        myline.offset += remaining / 2;
      }
      if (this.alignment == "Right") {
        myline.offset += remaining;
      }
      this.lines.push(myline);
    }
    pushLine(el, line3) {
      if (line3.max_ascent === 0)
        line3.max_ascent = el.max_ascent;
      if (line3.max_descent === 0)
        line3.max_descent = el.max_descent;
      if (line3.max_height === 0)
        line3.max_height = el.max_word_height ? el.max_word_height : el.max_ascent;
      this.maxHeight += line3.max_height;
      this.lines.push(line3);
    }
    textInput(text, sendNotification = true) {
      if (this.selection) {
        this.cursorLocation = this.elementmanager.deleteRange(this.selectionStart, this.selectionEnd);
        this.linalyze();
        this.selection = false;
        this.elementmanager.current = this.cursorLocation.element;
      }
      if (this.elementmanager.elements.length == 0) {
        this.elementmanager.current = null;
      }
      let el = this.elementmanager.getCurrentElement(this.defaultFont);
      el.text = this.insert(el.text, this.cursorLocation.offset, text);
      this.elementmanager.rewordElement(el);
      this.linalyze();
      this.resetBlinkState();
      this.cursorLocation.offset += text.length;
      this.cursorLocation.element = el;
      this.cursorLocation = this.getPositionForElementOffset(el, this.cursorLocation.offset);
    }
    insert(str, index, value) {
      return str.substr(0, index) + value + str.substr(index);
    }
    /*
     * Processes a keyDown event. The key is in a human readable string format like "Enter".
     */
    keyDown(key) {
      if (this.readOnly)
        return;
      if (this.selection) {
        this.cursorLocation = this.elementmanager.deleteRange(this.selectionStart, this.selectionEnd);
        this.linalyze();
        this.selection = false;
        this.elementmanager.current = this.cursorLocation.element;
        if (key === "Backspace" || key === "Delete") {
          this.cursorLocation = this.getPositionForElementOffset(this.cursorLocation.element, this.cursorLocation.offset);
          this.resetBlinkState();
          this._contentChanged(this, "Edit");
          g_typograph_document.redraw();
          return;
        }
      }
      let el = this.elementmanager.getCurrentElement();
      let insert = (str, index, value) => {
        return str.substr(0, index) + value + str.substr(index);
      };
      if (key === "Enter") {
        el.text = insert(el.text, this.cursorLocation.offset, "\n");
        this.elementmanager.rewordElement(el);
        this.linalyze();
        this.cursorLocation.offset += 1;
        this.cursorLocation.element = el;
        this.cursorLocation = this.getPositionForElementOffset(el, this.cursorLocation.offset);
        this._contentChanged(this, "Edit");
      } else if (key === "Backspace") {
        let checkPrevious = () => {
          if (!this.cursorLocation.offset) {
            let previous = this.elementmanager.getPreviousElement(el);
            if (!el.text.length && this.elementmanager.length() > 1)
              this.elementmanager.removeElement(el);
            if (previous) {
              this.cursorLocation.offset = previous.text.length;
              this.cursorLocation.element = previous;
              this.elementmanager.current = previous;
              el = previous;
              this.cursorLocation = this.getPositionForElementOffset(el, this.cursorLocation.offset);
            }
          }
        };
        checkPrevious();
        if (this.cursorLocation.offset) {
          el.text = el.text.slice(0, this.cursorLocation.offset - 1) + el.text.slice(this.cursorLocation.offset);
          this.elementmanager.rewordElement(el);
          this.linalyze();
          this.cursorLocation.offset -= 1;
          this.cursorLocation.element = el;
          this.cursorLocation = this.getPositionForElementOffset(el, this.cursorLocation.offset);
          this._contentChanged(this, "Edit");
        }
        checkPrevious();
      } else if (key === "ArrowLeft") {
        if (this.cursorLocation.offset)
          --this.cursorLocation.offset;
        else {
          let element3 = this.elementmanager.getPreviousElement(this.cursorLocation.element);
          if (element3) {
            this.cursorLocation.element = element3;
            this.cursorLocation.offset = element3.text.length;
          }
        }
        this.cursorLocation = this.getPositionForElementOffset(this.cursorLocation.element, this.cursorLocation.offset);
      } else if (key === "ArrowRight") {
        if (this.cursorLocation.element == null) {
          this.cursorLocation.element = this.elementmanager.elements[0];
        }
        if (this.cursorLocation.offset <= this.cursorLocation.element.text.length) {
          this.cursorLocation.offset++;
        } else {
          let element3 = this.elementmanager.getNextElement(this.cursorLocation.element);
          if (element3) {
            this.cursorLocation.element = element3;
            this.cursorLocation.offset = 0;
          }
        }
        this.cursorLocation = this.getPositionForElementOffset(this.cursorLocation.element, this.cursorLocation.offset);
      } else if (key === "ArrowUp") {
        this.cursorLocation.y -= this.cursorLocation.line.max_height / 2;
        this.cursorLocation.y = Math.max(this.cursorLocation.y, 0);
        this.cursorLocation = this.getLocationForMousePos(this.cursorLocation);
      } else if (key === "ArrowDown") {
        this.cursorLocation.y += this.cursorLocation.line.max_height + 1;
        this.cursorLocation = this.getLocationForMousePos({ x: this.cursorLocation.x, y: this.cursorLocation.y });
      }
      this.resetBlinkState();
      g_typograph_document.redraw();
    }
    mouseDown(pos) {
      if (pos.x < 0 || pos.y < 0)
        return;
      this.scrollAction = void 0;
      this.handleDragOffset = 0;
      if (this.needsScrollBar && this.scrollBarWidth) {
        if (pos.x >= this.handleRect.x && pos.x <= this.handleRect.x + this.handleRect.width) {
          if (pos.y >= this.handleRect.y && pos.y <= this.handleRect.y + this.handleRect.height) {
            this.scrollAction = "Vertical Handle Move";
            this.scrollStartX = pos.x;
            this.scrollStartY = pos.y;
            this.handleDragOffset = this.handleRect.y;
          }
          this.mouseMove(pos);
          return;
        }
        pos.y += this.vOffset;
      }
      let rc = this.getLocationForMousePos(pos);
      if (rc) {
        this.cursorLocation = rc;
        this.elementmanager.current = rc.element;
        if (rc.element) {
          if (!this.selection)
            this._fontChanged(rc.element.font);
          else
            this._fontChanged(this.elementmanager.createFontForRange(this.selectionStart, this.selectionEnd));
        }
      }
      this.resetBlinkState();
      this.mouseIsDown = true;
      this.selection = false;
      g_typograph_document.redraw();
    }
    mouseMove(pos) {
      pos.x = Math.max(pos.x, 0);
      pos.y = Math.max(pos.y, 0);
      if (this.scrollAction === "Vertical Handle Move") {
        this.handleOffset = pos.y - this.scrollStartY;
        if (this.handleOffset + this.handleDragOffset < 0)
          this.handleOffset = -this.handleDragOffset;
        if (this.handleOffset + this.handleDragOffset + this.handleRect.height > this.height)
          this.handleOffset = this.height - this.handleRect.height - this.handleDragOffset;
        this.vOffset = (this.handleOffset + this.handleDragOffset) * this.maxHeight / this.height;
        g_typograph_document.redraw();
        return;
      }
      if (this.needsScrollBar && this.scrollBarWidth) {
        pos.y += this.vOffset;
      }
      let rc = this.getLocationForMousePos(pos);
      if (rc && !this.mouseIsDown) {
        if (this.hoverElement !== rc.element) {
          this.hoverElement = rc.element;
          let view = g_typograph_document.get_view();
          g_typograph_document.redraw();
        }
        return;
      }
      if (rc) {
        this.selection = true;
        if (rc.y < this.cursorLocation.y) {
          this.selectionStart = rc;
          this.selectionEnd = this.cursorLocation;
        } else if (rc.y === this.cursorLocation.y) {
          if (rc.x <= this.cursorLocation.x) {
            this.selectionStart = rc;
            this.selectionEnd = this.cursorLocation;
          } else {
            this.selectionStart = this.cursorLocation;
            this.selectionEnd = rc;
          }
          if (rc.x === this.cursorLocation.x)
            this.selection = false;
        } else {
          this.selectionStart = this.cursorLocation;
          this.selectionEnd = rc;
        }
        this._fontChanged(this.elementmanager.createFontForRange(this.selectionStart, this.selectionEnd));
        g_typograph_document.redraw();
      }
    }
    mouseUp() {
      this.mouseIsDown = false;
      this.scrollAction = void 0;
    }
    mouseWheel(step) {
      if (this.needsScrollBar && this.scrollBarWidth) {
        this.handleOffset = this.handleOffset - step * 5;
        if (this.handleOffset + this.handleDragOffset < 0)
          this.handleOffset = -this.handleDragOffset;
        if (this.handleOffset + this.handleDragOffset + this.handleRect.height > this.height)
          this.handleOffset = this.height - this.handleRect.height - this.handleDragOffset;
        this.vOffset = (this.handleOffset + this.handleDragOffset) * this.maxHeight / this.height;
        this._redraw();
      }
    }
    layout(width, height) {
      this.width = width;
      this.height = height;
      this.linalyze();
      if (this.cursorLocation.element) {
        this.cursorLocation = this.getPositionForElementOffset(this.cursorLocation.element, this.cursorLocation.offset);
      }
    }
    export(format = "text", selection2) {
      let rc = "";
      let elements = this.elementmanager.elements;
      if (selection2) {
        if (this.selection)
          elements = this.elementmanager.getRange(this.selectionStart, this.selectionEnd);
        for (let i = 0; i < elements.length; i++) {
          let el = elements[i];
          if (format == "text") {
            rc += el.text;
          }
        }
      }
      return rc;
    }
    selectWord(wordloaction) {
      if (wordloaction == null)
        return;
      this.selectionStart = {
        element: wordloaction.element,
        x: wordloaction.start + wordloaction.word.prefix_metrics.width,
        y: wordloaction.y,
        offset: wordloaction.word.offset + 1,
        line: wordloaction.line
      };
      this.selectionEnd = {
        element: wordloaction.element,
        x: wordloaction.end,
        y: wordloaction.y,
        offset: wordloaction.word.end_offset,
        line: wordloaction.line
      };
      this.selection = true;
      g_typograph_document.redraw();
    }
    selectall() {
      if (this.elementmanager.elements.length > 0) {
        let elements = this.elementmanager.elements;
        this.selectionStart = {
          element: elements[0],
          x: 0,
          y: 0,
          offset: 0,
          line: this.lines[0]
        };
        let max_y = 0;
        let offset = 0;
        for (let i = 0; i < this.lines.length; i++) {
          let l = this.lines[i];
          max_y += l.max_height;
          offset += l.offset;
        }
        let end_x = this.lines[this.lines.length - 1].max_width;
        this.selectionEnd = {
          element: elements[elements.length - 1],
          x: end_x,
          y: max_y,
          offset: elements[elements.length - 1].text.length,
          line: this.lines[this.lines.length - 1]
        };
        this.selection = true;
        g_typograph_document.redraw();
      }
    }
    clear(sendNotification) {
      this.elementmanager.elements = [];
      this.elementmanager.current = void 0;
      this.cursorLocation = { element: null, x: 0, y: 0, offset: 0, line: null };
      this.selection = false;
      this.vOffset = 0;
      this.handleDragOffset = 0;
      this.handleOffset = 0;
      if (sendNotification)
        this._contentChanged(this, "Clear");
    }
    setScale(s) {
      this.scale = s;
    }
    /**
     * test funtioon to catch none printing lines  because no word warp is possible...
     * @returns true if there are none printing lines..
     *
     * Not the correct solution must be moved to the word wrapping in linalize
     * We leaf it like this for now because it is an 'easy' fix for the current problem in price texts for Menuez
     * A proper fix will take more time because some restructering must be done
     */
    test_for_empty_lines_and_none_empty_text() {
      let element_words = [];
      let line_words = [];
      for (let e = 0; e < this.elementmanager.elements.length; e++) {
        let element3 = this.elementmanager.elements[e];
        for (let w = 0; w < element3.words.length; w++) {
          let word = element3.words[w];
          if (word.text != " " && word.text != "")
            element_words.push(word);
        }
      }
      for (let l = 0; l < this.lines.length; l++) {
        let line3 = this.lines[l];
        for (let w = 0; w < line3.words.length; w++) {
          let word = line3.words[w];
          if (word.text != " " && word.text != "")
            line_words.push(word);
        }
      }
      if (line_words.length == 0 && element_words.length > 0)
        return true;
      return false;
    }
    /**
     * Draws the text at the given screen coordinates. The screen coordinates are only necessary if you don't want to draw the text at 0, 0
     * of your 2D canvas.
     * @param {*} screenX The X offset inside the 2D canvas. 0 by default.
     * @param {*} screenY The Y offset inside the 2D canvas. 0 by default.
     */
    draw(view = null, screenX = 0, screenY = 0) {
      if (view == null)
        return;
      if (this.test_for_empty_lines_and_none_empty_text()) {
        this.draw_exclamation_mark(view, screenX, screenY);
        return;
      }
      this.screenOffsetX = screenX;
      this.screenOffsetY = screenY;
      let startX = screenX, startY = screenY;
      let ctx = view.context;
      let x = 0, y = 0;
      ctx.save();
      if (this.clearBackground) {
        ctx.fillStyle = "#fff";
        ctx.fillRect(startX, startY, this.width, this.height);
      }
      screenY -= this.vOffset;
      ctx.fillStyle = "black";
      ctx.textBaseline = "alphabetic";
      let formatTag;
      for (let l = 0; l < this.lines.length; ++l) {
        let line3 = this.lines[l];
        if (this.focus == false) {
          if (y - this.vOffset > this.height - line3.max_ascent) {
            if (view.show_handels) {
              this.draw_exclamation_mark(view, this.screenOffsetX, this.screenOffsetY);
            }
            continue;
          }
        }
        screenX = this.screenOffsetX;
        x += line3.offset;
        screenX += line3.offset * this.scale;
        if (line3.symbol) {
          if (line3.symbol === "circle") {
            ctx.beginPath();
            let radius = Math.min(line3.max_height, 3);
            let centerX = line3.offset - 4 - radius;
            let centerY = Math.ceil(line3.max_height / 2);
            centerX *= this.scale;
            centerY *= this.scale;
            radius *= this.scale;
            ctx.beginPath();
            ctx.arc(screenX - 14 - radius, screenY + centerY, 3, 0, 2 * Math.PI, false);
            ctx.fillStyle = this.defaultFont.style;
            ctx.strokeStyle = this.defaultFont.style;
            ctx.lineWidth = 1;
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
          }
        }
        if (!line3.words.length && this.selection && (y >= this.selectionStart.y && y <= this.selectionEnd.y) && this.hasFocus() && view.show_guids == true) {
          ctx.fillStyle = this.selectionStyle;
          ctx.fillRect(startX + x, startY + (y - this.vOffset) * this.scale, 5 * this.scale, line3.max_height * this.scale);
        }
        for (let w = 0; w < line3.words.length; ++w) {
          let lWord = line3.words[w];
          let size = lWord.element.font.size * (96 / 72 * this.scale);
          let name = lWord.element.font.name;
          let font_style = lWord.element.font.font_style;
          let text = size + "px " + name + " " + font_style;
          if (font_style.match(/^\d/)) {
            text = font_style + " " + size + "px " + name;
          }
          ctx.font = text;
          let textToDraw, textToDrawWidth;
          if (lWord.wrapped) {
            textToDraw = lWord.word;
            textToDrawWidth = lWord.word_metrics.width;
          } else {
            textToDraw = lWord.text;
            textToDrawWidth = lWord.width;
          }
          if (this.selection && this.hasFocus() && view.show_guids == true) {
            if (y >= this.selectionStart.y && y <= this.selectionEnd.y) {
              ctx.fillStyle = this.selectionStyle;
              let drawWholeText = false;
              if (y > this.selectionStart.y && y < this.selectionEnd.y) {
                ctx.fillRect(startX + x * this.scale, startY + (y - this.vOffset) * this.scale, textToDrawWidth * this.scale, line3.max_height * this.scale);
              } else if (y === this.selectionStart.y && y === this.selectionEnd.y) {
                let rx = startX + x, rw = textToDrawWidth;
                if (this.selectionStart.x > x) {
                  let diff = x - this.selectionStart.x;
                  rx -= diff;
                  rw += diff;
                }
                if (this.selectionEnd.x <= rx + rw) {
                  let diff = rx + rw - this.selectionEnd.x - startX;
                  rw -= diff;
                }
                rx = rx - startX;
                if (rw > 1)
                  ctx.fillRect(startX + rx * this.scale, startY + (y - this.vOffset) * this.scale, rw * this.scale, line3.max_height * this.scale);
              } else if (y === this.selectionStart.y) {
                let rx = startX + x, rw = textToDrawWidth;
                if (this.selectionStart.x > x) {
                  let diff = x - this.selectionStart.x;
                  rx -= diff;
                  rw += diff;
                }
                rx = rx - startX;
                if (rw > 1)
                  ctx.fillRect(startX + rx * this.scale, startY + (y - this.vOffset) * this.scale, rw * this.scale, line3.max_height * this.scale);
              } else if (y === this.selectionEnd.y) {
                let rx = startX + x, rw = textToDrawWidth;
                if (this.selectionEnd.x <= rx + rw) {
                  let diff = rx + rw - this.selectionEnd.x - startX;
                  rw -= diff;
                }
                rx = rx - startX;
                if (rw > 1)
                  ctx.fillRect(startX + rx * this.scale, startY + (y - this.vOffset) * this.scale, rw * this.scale, line3.max_height * this.scale);
              }
            }
          }
          if (lWord.element.font.style) {
            ctx.fillStyle = lWord.element.font.style;
          } else {
            ctx.fillStyle = "black";
          }
          ctx.fillText(textToDraw, screenX, screenY + line3.max_ascent * this.scale);
          x += textToDrawWidth;
          screenX += textToDrawWidth * this.scale;
        }
        if (l < this.lines.length - 1) {
          x = 0;
          y += line3.max_height;
          screenX = startX;
          screenY += line3.max_height * this.scale;
        }
      }
      if (!this.selection && !this.readOnly && this.hasFocus() && view.show_guids == true && !this.ignore_input) {
        if (this.blinkState && this.cursorLocation) {
          let height = this.defaultFont.size * (96 / 72) * this.scale;
          if (this.cursorLocation.line) {
            if (this.cursorLocation.line.max_ascent != 0)
              height = this.cursorLocation.line.max_ascent * this.scale;
          } else {
          }
          ctx.fillStyle = "#fff";
          ctx.fillRect(startX + this.cursorLocation.x * this.scale - 1, startY + this.cursorLocation.y * this.scale - this.vOffset - 1, 3, height + 6);
          ctx.fillStyle = this.defaultFont.style;
          ctx.fillRect(startX + this.cursorLocation.x * this.scale, startY + this.cursorLocation.y * this.scale - this.vOffset, 1, height + 5);
        }
        this.updateBlinkState();
      }
      ctx.restore();
      if (this.needsScrollBar && this.scrollBarWidth) {
        let x2 = this.width - this.scrollBarWidth;
        let y2 = Math.max(this.handleOffset + this.handleDragOffset, 0);
        let height = Math.min(this.height / this.maxHeight * this.height, this.height);
        this.handleRect = { x: x2, y: y2, width: this.scrollBarWidth, height };
        this._scrollBarFunc(ctx, this.handleRect);
      }
    }
    draw_exclamation_mark(view, offset_x, offset_y) {
      if (view == null)
        return;
      if (view.show_handels) {
        let ctx = view.context;
        ctx.save();
        let alert_size = 24;
        let margin = 3;
        let width = this.width * view.zoom;
        let height = this.height * view.zoom;
        ctx.drawImage(this.text_over_flow_alert, offset_x + width - (alert_size + margin), offset_y + height - (alert_size + margin), alert_size, alert_size);
        ctx.restore();
      }
    }
  };
  var RichText = class _RichText {
    constructor(size, defaultFont, taglist, constructing, editor = null) {
      this.size = size;
      this.defaultFont = defaultFont;
      this.taglist = taglist;
      this.constructing = constructing;
      this.editor = editor;
      this.pageelement = null;
      this.undoAt = 0;
      let attr = { bold: false, italic: false };
      let formating = { margin: [], tag: "" };
      if (!defaultFont)
        this.defaultFont = { name: "open sans", size: 24, formatting: formating, attributes: attr, style: "black", text: "24pt open sans", font_style: "regular" };
      if (editor == null)
        this.editor = new Editor(this.size, this.defaultFont, this.taglist);
    }
    copy() {
      let editor = this.editor.copy();
      let richtext = new _RichText(this.size, this.defaultFont, this.taglist, true, editor);
      richtext.pageelement = this.pageelement;
      return richtext;
    }
    setPageElement(el) {
      this.pageelement = el;
    }
    getEditor() {
      return this.editor;
    }
    getElementManager() {
      return this.editor.elementmanager;
    }
    //Expose Editor function to RichText (for now)
    keyDown(key) {
      let now = Date.now();
      let setundo = false;
      if (now > this.undoAt + 600) {
        setundo = true;
        g_typograph_document.set_undo();
        this.undoAt = now;
      }
      this.editor.keyDown(key);
      if (this.editor.elementmanager.elements.length == 0) {
        let p = { x: 1, y: 1 };
        this.mouseDown(p);
      }
      if (setundo)
        g_typograph_document.set_redo();
    }
    setFont(font3) {
      if (this.constructing) {
        this.editor.setFont(font3);
      } else {
        g_typograph_document.set_undo();
        this.editor.setFont(font3);
        g_typograph_document.set_redo();
      }
    }
    textInput(text) {
      if (this.constructing) {
        this.editor.textInput(text);
      } else {
        g_typograph_document.set_undo();
        this.editor.textInput(text);
        g_typograph_document.set_redo();
      }
    }
    setFocus(b) {
      if (b == false)
        this.undoAt = Date.now();
      this.editor.setFocus(b);
    }
    hasFocus() {
      return this.editor.hasFocus();
    }
    mouseDown(p) {
      this.editor.mouseDown(p);
    }
    mouseUp() {
      this.editor.mouseUp();
    }
    mouseWheel(step) {
      this.editor.mouseWheel(step);
    }
    //
    static measureText(text, fontText) {
      let metrics = { width: 0, ascent: -1, descent: -1, height: -1 };
      let view = g_typograph_document.get_view();
      view.context.font = fontText;
      view.context.textBaseline = "top";
      metrics.width = view.context.measureText(text).width;
      let textSpan = document.createElement("span");
      textSpan.innerHTML = text;
      textSpan.style.font = fontText;
      let block = document.createElement("div");
      block.style.display = "inline-block";
      block.style.width = "1px";
      block.style.height = "0px";
      let div = document.createElement("div");
      div.appendChild(textSpan);
      div.appendChild(block);
      let body = document.body;
      body.appendChild(div);
      try {
        block.style.verticalAlign = "baseline";
        metrics.ascent = block.offsetTop - textSpan.offsetTop;
        block.style.verticalAlign = "bottom";
        metrics.height = block.offsetTop - textSpan.offsetTop;
        metrics.descent = metrics.height - metrics.ascent;
      } finally {
        document.body.removeChild(div);
      }
      return metrics;
    }
  };

  // src/linecalculator.ts
  function calcIsInsideThickLineSegment(line1, line22, pnt, lineThickness) {
    var L2 = (line22.x - line1.x) * (line22.x - line1.x) + (line22.y - line1.y) * (line22.y - line1.y);
    if (L2 == 0)
      return false;
    var r = ((pnt.x - line1.x) * (line22.x - line1.x) + (pnt.y - line1.y) * (line22.y - line1.y)) / L2;
    if (r < 0) {
      return Math.sqrt((line1.x - pnt.x) * (line1.x - pnt.x) + (line1.y - pnt.y) * (line1.y - pnt.y)) <= lineThickness;
    } else if (0 <= r && r <= 1) {
      var s = ((line1.y - pnt.y) * (line22.x - line1.x) - (line1.x - pnt.x) * (line22.y - line1.y)) / L2;
      return Math.abs(s) * Math.sqrt(L2) <= lineThickness;
    } else {
      return Math.sqrt((line22.x - pnt.x) * (line22.x - pnt.x) + (line22.y - pnt.y) * (line22.y - pnt.y)) <= lineThickness;
    }
  }

  // src/autolayout.ts
  var AutoLayout = class {
    constructor(columns = 1, column_gab = 0, row_gab = 0, templates = []) {
      this.columns = columns;
      this.column_gab = column_gab;
      this.row_gab = row_gab;
      this.templates = templates;
      this.margin_top = 0;
      this.margin_left = 0;
      this.margin_bottom = 0;
      this.margin_right = 0;
      this.fixed_height = true;
      this.fixed_width = true;
      this.clips = [];
    }
    add_clip(clip) {
      this.clips.push(clip);
    }
    can_add(into, template = 0) {
      let height = 0;
      for (let c = 0; c < this.clips.length; c++) {
        let clip = this.clips[c];
        height += clip.native_rect.size.height;
      }
      height += this.templates[template].native_rect.size.height;
      return height <= into.native_rect.size.height;
    }
    remove_clip(parent, index) {
      parent.remove_group(index);
      this.clips.splice(index, 1);
      let x = parent.native_rect.position.x;
      let y = parent.native_rect.position.y;
      let offset_x = 0;
      let offset_y = 0;
      for (let c = 0; c < this.clips.length; c++) {
        let clip = this.clips[c];
        let c_x = x - clip.native_rect.position.x;
        let c_y = y - clip.native_rect.position.y;
        g_typograph_document.offset_group_elements(clip, c_x, c_y);
        g_typograph_document.offset_group_elements(clip, offset_x, offset_y);
        offset_y += clip.native_rect.size.height + this.row_gab;
      }
    }
    is_point_in_clip_control(parent, view, point) {
      let path = new Path2D();
      let margin = 10;
      let icon_w = 30;
      let icon_h = 24;
      for (let c = 0; c < this.clips.length; c++) {
        let clip = this.clips[c];
        clip.set_screen_rect(view);
        let c_x = clip.screen_rect.position.x + view.offset_x;
        let c_y = clip.screen_rect.position.y + view.offset_y;
        let c_w = clip.screen_rect.size.width;
        let c_h = clip.screen_rect.size.height;
        path.moveTo(c_x + c_w, c_y + margin);
        path.lineTo(c_x + c_w + icon_h, c_y + margin);
        path.lineTo(c_x + c_w + icon_h, c_y + margin + icon_w);
        path.lineTo(c_x + c_w, c_y + margin + icon_w);
        path.lineTo(c_x + c_w, c_y + margin);
        if (parent.is_point_in_path(view, point, path))
          return true;
      }
      return false;
    }
  };

  // src/page.ts
  var gMMtoScreen = 96 / 25.4;
  var gMMtoPixel = 1 / 25.4 * 96;
  var gPixeltoMM = 1 / 72 * 25.4;
  var gPixel96toMM = 1 / 96 * 25.4;
  var g72to96 = 96 / 72;
  var g96to72 = 72 / 96;
  var gSticky;
  var kHandleSize = 8;
  var kRotationHandleOffset = 20;
  var kCheckSize = 7;
  var kCheckSizeRotation = 20;
  var gZoom = 1;
  var g_add_clipping_icon = new Image();
  g_add_clipping_icon.src = "data:image/svg+xml," + kAddClippingSVGURI;
  var g_add_clipping_disabled_icon = new Image();
  g_add_clipping_disabled_icon.src = "data:image/svg+xml," + kAddClippingDisabledSVGURI;
  var g_remove_clipping_icon = new Image();
  g_remove_clipping_icon.src = "data:image/svg+xml," + kRemoveClippingRightSVGURI;
  var g_images_loaded = [];
  var Page = class _Page {
    constructor(id, type, width, height, page_name = "blanc-page") {
      this.id = id;
      this.type = type;
      this.width = width;
      this.height = height;
      this.page_name = page_name;
      let uuid = g_typograph_document.generateUUID();
      this.uuid = uuid;
      this.name = uuid;
      this.spread_pages = 1;
      if (this.type == "spread" || this.type == "spread_horizontal" || this.type == "spread_vertical")
        this.spread_pages = 2;
      this.page_elements = [];
      this.temp_page_elements = [];
      this.left_margin = 10;
      this.top_margin = 10;
      this.right_margin = 10;
      this.bottom_margin = 10;
      this.guides = [];
      this.grid = [];
      this.ruler_guides = [];
      this.bleed = 0;
      this.native_size = new Size(width, height);
      this.page_active = true;
      this.fill_style = "#fff";
      this.stroke_style = "#000";
      this.opacity = 1;
      this.blend_mode = "source-over";
      this.gradient = null;
      this.locked = false;
      this.show_temp = false;
      this.user_data = null;
      gSticky = new Sticky(false, false);
      this.setpageguides();
    }
    setpageguides() {
      let view = g_typograph_document.canvas_view;
      let guide = new Guide(view, "Ver", 0, 0, false);
      this.ruler_guides.push(guide);
      guide = new Guide(view, "Hor", 0, 0, false);
      this.ruler_guides.push(guide);
      guide = new Guide(view, "Ver", 0, this.width, false);
      this.ruler_guides.push(guide);
      guide = new Guide(view, "Hor", 0, this.height, false);
      this.ruler_guides.push(guide);
      guide = new Guide(view, "Ver", 0, this.width / 2, false);
      this.ruler_guides.push(guide);
      guide = new Guide(view, "Hor", 0, this.height / 2, false);
      this.ruler_guides.push(guide);
    }
    updatepageguides() {
      let guide;
      if (this.ruler_guides.length < 6) {
        let current_guides = [];
        if (this.ruler_guides.length > 0) {
          for (let c = 0; c < this.ruler_guides.length; c++)
            current_guides.push(this.ruler_guides[c]);
          this.ruler_guides = [];
        }
        this.setpageguides();
        for (let c = 0; c < current_guides.length; c++)
          this.ruler_guides.push(current_guides[c]);
      }
      guide = this.ruler_guides[0];
      guide.native_value = 0;
      guide.native2screen_value(0, guide.view);
      guide = this.ruler_guides[1];
      guide.native_value = 0;
      guide.native2screen_value(0, guide.view);
      guide = this.ruler_guides[2];
      guide.native_value = this.width;
      guide.native2screen_value(this.width, guide.view);
      guide = this.ruler_guides[3];
      guide.native_value = this.height;
      guide.native2screen_value(this.height, guide.view);
      guide = this.ruler_guides[4];
      guide.native_value = this.width / 2;
      guide.native2screen_value(this.width / 2, guide.view);
      guide = this.ruler_guides[5];
      guide.native_value = this.height / 2;
      guide.native2screen_value(this.height / 2, guide.view);
    }
    copy() {
      let new_page = new _Page(this.id, this.type, this.width, this.height, this.page_name);
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i].copy();
        new_page.add_page_element(el);
      }
      new_page.left_margin = this.left_margin;
      new_page.top_margin = this.top_margin;
      new_page.right_margin = this.right_margin;
      new_page.bottom_margin = this.bottom_margin;
      new_page.guides = [];
      for (let i = 0; i < this.guides.length; i++) {
        let line3 = this.guides[i];
        new_page.guides.push(line3.copy());
      }
      new_page.grid = [];
      for (let i = 0; i < this.grid.length; i++) {
        let line3 = this.grid[i];
        new_page.grid.push(line3.copy());
      }
      new_page.ruler_guides = [];
      for (let i = 0; i < this.ruler_guides.length; i++) {
        let guide = this.ruler_guides[i];
        new_page.ruler_guides.push(guide.copy());
      }
      new_page.bleed = this.bleed;
      new_page.page_active = this.page_active;
      new_page.fill_style = this.fill_style;
      new_page.stroke_style = this.stroke_style;
      new_page.opacity = this.opacity;
      new_page.blend_mode = this.blend_mode;
      if (this.gradient)
        new_page.gradient = this.gradient.copy();
      else
        this.gradient = null;
      new_page.locked = this.locked;
      new_page.show_temp = false;
      new_page.user_data = this.user_data;
      return new_page;
    }
    /**
     * Export to POD page
     *
     * @returns POD page object
     *
     * @description old code to expoer a page to a POD page...
     *
     */
    Export() {
      let elements = [];
      for (let i = 0; i < this.page_elements.length; i++) {
        let element3 = this.page_elements[i];
        let border = {
          width: element3.native_border_size * g72to96,
          style: "solid",
          color: element3.get_stroke_style_name()
        };
        let boxshadow = {
          horizontal: element3.native_shadow_offset_x * gMMtoPixel,
          vertical: element3.native_shadow_offset_y * gMMtoPixel,
          blur: element3.native_shadow_blur * gMMtoPixel,
          spread: element3.native_shadow_length * gMMtoPixel,
          color: element3.get_shadow_color_name(),
          inset: false
        };
        if (element3.type == "line_POD") {
          let el = element3;
          let w = el.native_border_size * gPixeltoMM;
          let x = el.native_rect.position.x + element3.native_rect.size.width / 2 - w / 2;
          let y = el.native_rect.position.y;
          let h = el.native_rect.size.height;
          if (el.horizontal) {
            h = el.native_border_size * gPixeltoMM;
            x = el.native_rect.position.x;
            y = el.native_rect.position.y + element3.native_rect.size.height / 2 - h / 2;
            w = el.native_rect.size.width;
          }
          let rectagle = {
            type: "rectangle",
            x,
            y,
            width: w,
            height: h,
            background: element3.get_stroke_style_name(),
            border_radius: 0,
            rotation: element3.angle,
            opacity: element3.opacity,
            lock: element3.locked,
            border,
            box_shadow: boxshadow
          };
          delete rectagle.border;
          if (!element3.shadow)
            delete rectagle.box_shadow;
          elements.push(rectagle);
        }
        if (element3.type == "rect") {
          let fstyle = element3.get_fill_style_name();
          if (fstyle == "none")
            fstyle = "transparant";
          let rectagle = {
            type: "rectangle",
            x: element3.native_rect.position.x,
            y: element3.native_rect.position.y,
            width: element3.native_rect.size.width,
            height: element3.native_rect.size.height,
            background: fstyle,
            border_radius: 0,
            rotation: element3.angle,
            opacity: element3.opacity,
            lock: element3.locked,
            border,
            box_shadow: boxshadow
          };
          if (element3.get_stroke_style_name() == "none" || element3.native_border_size == 0)
            delete rectagle.border;
          if (!element3.shadow)
            delete rectagle.box_shadow;
          elements.push(rectagle);
        }
        if (element3.type == "roundedrect") {
          let fstyle = element3.get_fill_style_name();
          if (fstyle == "none")
            fstyle = "transparant";
          let b = 0;
          let rr = element3;
          if (rr.native_radiuses[0] == rr.native_radiuses[1] && rr.native_radiuses[0] == rr.native_radiuses[2] && rr.native_radiuses[0] == rr.native_radiuses[3]) {
            b = rr.native_radiuses[0] * gMMtoPixel;
          } else {
            b = rr.native_radiuses[0] * gMMtoPixel + "px ";
            b += rr.native_radiuses[1] * gMMtoPixel + "px ";
            b += rr.native_radiuses[3] * gMMtoPixel + "px ";
            b += rr.native_radiuses[2] * gMMtoPixel + "px";
          }
          let rectagle = {
            type: "rectangle",
            x: element3.native_rect.position.x,
            y: element3.native_rect.position.y,
            width: element3.native_rect.size.width,
            height: element3.native_rect.size.height,
            background: fstyle,
            border_radius: b,
            rotation: element3.angle,
            opacity: element3.opacity,
            lock: element3.locked,
            border,
            box_shadow: boxshadow
          };
          if (element3.get_stroke_style_name() == "none" || element3.native_border_size == 0)
            delete rectagle.border;
          if (!element3.shadow)
            delete rectagle.box_shadow;
          elements.push(rectagle);
        }
        if (element3.type == "circel") {
          let fstyle = element3.get_fill_style_name();
          if (fstyle == "none")
            fstyle = "transparant";
          let circel = {
            type: "circle",
            x: element3.native_rect.position.x,
            y: element3.native_rect.position.y,
            width: element3.native_rect.size.width,
            height: element3.native_rect.size.height,
            background: fstyle,
            border_radius: 0,
            rotation: element3.angle,
            opacity: element3.opacity,
            lock: element3.locked,
            border,
            box_shadow: boxshadow
          };
          if (element3.get_stroke_style_name() == "none" || element3.native_border_size == 0)
            delete circel.border;
          if (!element3.shadow)
            delete circel.box_shadow;
          elements.push(circel);
        }
        if (element3.type == "triangle") {
          let fstyle = element3.get_fill_style_name();
          if (fstyle == "none")
            fstyle = "transparant";
          let rectagle = {
            type: "triangle",
            x: element3.native_rect.position.x,
            y: element3.native_rect.position.y,
            width: element3.native_rect.size.width,
            height: element3.native_rect.size.height,
            background: fstyle,
            border_radius: 0,
            rotation: element3.angle,
            opacity: element3.opacity,
            lock: element3.locked,
            border,
            box_shadow: boxshadow
          };
          if (element3.get_stroke_style_name() == "none" || element3.native_border_size == 0)
            delete rectagle.border;
          if (!element3.shadow)
            delete rectagle.box_shadow;
          elements.push(rectagle);
        }
        if (element3.type == "image") {
          let fstyle = element3.get_fill_style_name();
          if (fstyle == "none")
            fstyle = "transparant";
          let e = element3;
          let contain = false;
          if (e.image_scale == "Scale_to_Fit")
            contain = true;
          let b = 0;
          if (e.native_radiuses[0] == e.native_radiuses[1] && e.native_radiuses[0] == e.native_radiuses[2] && e.native_radiuses[0] == e.native_radiuses[3]) {
            b = e.native_radiuses[0] * gMMtoPixel;
          } else {
            b = e.native_radiuses[0] * gMMtoPixel + "px ";
            b += e.native_radiuses[1] * gMMtoPixel + "px ";
            b += e.native_radiuses[3] * gMMtoPixel + "px ";
            b += e.native_radiuses[2] * gMMtoPixel + "px";
          }
          let photo = {
            type: "photo",
            x: element3.native_rect.position.x,
            y: element3.native_rect.position.y,
            width: element3.native_rect.size.width,
            height: element3.native_rect.size.height,
            background: fstyle,
            border_radius: b,
            rotation: element3.angle,
            opacity: element3.opacity,
            key: e.key,
            contain,
            lock: element3.locked,
            border,
            box_shadow: boxshadow,
            placement_horizontal: e.image_horizontal_alignment.toLowerCase(),
            placement_vertical: e.image_vertical_alignment.toLowerCase()
          };
          if (element3.get_stroke_style_name() == "none" || element3.native_border_size == 0)
            delete photo.border;
          if (!element3.shadow)
            delete photo.box_shadow;
          elements.push(photo);
        }
        if (element3.type == "text") {
          let fstyle = element3.get_fill_style_name();
          if (fstyle == "none")
            fstyle = "transparant";
          let elementtext = element3;
          if (elementtext.pod_tekst_style == "Symbol") {
            let symbol = {
              type: "symbol",
              x: element3.native_rect.position.x,
              y: element3.native_rect.position.y,
              width: element3.native_rect.size.width,
              height: element3.native_rect.size.height,
              background: fstyle,
              rotation: element3.angle,
              opacity: element3.opacity,
              lock: element3.locked,
              border,
              box_shadow: boxshadow,
              font_size: elementtext.native_font_size * g72to96,
              character: elementtext.text,
              color: elementtext.getTextColorName()
            };
            if (elementtext.get_fill_style_name() == "none")
              delete symbol.background;
            if (element3.get_stroke_style_name() == "none" || element3.native_border_size == 0)
              delete symbol.border;
            if (!element3.shadow)
              delete symbol.box_shadow;
            elements.push(symbol);
          }
          if (elementtext.pod_tekst_style == "Body_tekst" || elementtext.pod_tekst_style == "Body_text") {
            let text_border = { width: elementtext.native_border_size * g72to96, color: elementtext.getTextStroleColorName() };
            let text = {
              type: "text",
              x: element3.native_rect.position.x,
              y: element3.native_rect.position.y,
              width: element3.native_rect.size.width,
              height: element3.native_rect.size.height,
              background: fstyle,
              rotation: element3.angle,
              opacity: element3.opacity,
              text_align: elementtext.text_align,
              vertical_align: elementtext.vertical_align,
              text_key: "",
              text_value: elementtext.text,
              text_color: elementtext.getTextColorName(),
              text_font_size: elementtext.native_font_size * g72to96,
              text_font_family: elementtext.pod_font_name,
              text_font_weight: elementtext.pod_font_weight,
              text_line_height: elementtext.native_auto_leading / 100,
              text_margin_bottom: 0,
              text_columns: elementtext.columns,
              text_columns_gap: elementtext.native_columns_cap * gMMtoPixel,
              fields: ["text"],
              lock: element3.locked,
              text_stroke: text_border,
              text_shadow: boxshadow
            };
            if (elementtext.pod_font_name == "")
              delete text.text_font_family;
            if (elementtext.pod_font_weight == 0)
              delete text.text_font_weight;
            if (elementtext.get_stroke_style_name() == "none" || elementtext.native_border_size == 0)
              delete text.text_stroke;
            if (!element3.shadow)
              delete text.text_shadow;
            elements.push(text);
          }
          if (elementtext.pod_tekst_style == "Subtitle") {
            let subtitle_border = { width: elementtext.native_border_size * g72to96, color: elementtext.getTextStroleColorName() };
            let text = {
              type: "text",
              x: element3.native_rect.position.x,
              y: element3.native_rect.position.y,
              width: element3.native_rect.size.width,
              height: element3.native_rect.size.height,
              background: fstyle,
              rotation: element3.angle,
              opacity: element3.opacity,
              text_align: elementtext.text_align,
              vertical_align: elementtext.vertical_align,
              subtitle_key: "",
              subtitle_value: elementtext.text,
              subtitle_color: elementtext.getTextColorName(),
              subtitle_font_size: elementtext.native_font_size * g72to96,
              subtitle_font_family: elementtext.pod_font_name,
              subtitle_font_weight: elementtext.pod_font_weight,
              subtitle_line_height: elementtext.native_auto_leading / 100,
              subtitle_margin_bottom: 0,
              subtitle_columns: elementtext.columns,
              subtitle_columns_gap: elementtext.native_columns_cap * gMMtoPixel,
              fields: ["subtitle"],
              lock: element3.locked,
              subtitle_stroke: subtitle_border,
              subtitle_shadow: boxshadow
            };
            if (elementtext.pod_font_name == "")
              delete text.subtitle_font_family;
            if (elementtext.pod_font_weight == 0)
              delete text.subtitle_font_weight;
            if (elementtext.get_stroke_style_name() == "none" || elementtext.native_border_size == 0)
              delete text.subtitle_stroke;
            if (!element3.shadow)
              delete text.subtitle_shadow;
            elements.push(text);
          }
          if (elementtext.pod_tekst_style == "Title") {
            let title_border = { width: elementtext.native_border_size * g72to96, color: elementtext.getTextStroleColorName() };
            let text = {
              type: "text",
              x: element3.native_rect.position.x,
              y: element3.native_rect.position.y,
              width: element3.native_rect.size.width,
              height: element3.native_rect.size.height,
              background: fstyle,
              rotation: element3.angle,
              opacity: element3.opacity,
              text_align: elementtext.text_align,
              vertical_align: elementtext.vertical_align,
              title_key: "",
              title_value: elementtext.text,
              title_color: elementtext.getTextColorName(),
              title_font_size: elementtext.native_font_size * g72to96,
              title_font_family: elementtext.pod_font_name,
              title_font_weight: elementtext.pod_font_weight,
              title_line_height: elementtext.native_auto_leading / 100,
              title_margin_bottom: 0,
              title_columns: elementtext.columns,
              title_columns_gap: elementtext.native_columns_cap * gMMtoPixel,
              fields: ["title"],
              lock: element3.locked,
              title_stroke: title_border,
              title_shadow: boxshadow
            };
            if (elementtext.pod_font_name == "")
              delete text.title_font_family;
            if (elementtext.pod_font_weight == 0)
              delete text.title_font_weight;
            if (elementtext.get_stroke_style_name() == "none" || elementtext.native_border_size == 0)
              delete text.title_stroke;
            if (!element3.shadow)
              delete text.title_shadow;
            elements.push(text);
          }
        }
      }
      let page2 = { name: this.page_name, width: this.native_size.width, height: this.native_size.height, bleed: this.bleed, blocks: elements };
      return page2;
    }
    MakeInActive() {
      for (let i = 0; i < this.page_elements.length; i++) {
        let element3 = this.page_elements[i];
        element3.selected = false;
      }
    }
    init_page_new(data) {
      let view = g_typograph_document.canvas_view;
      this.uuid = data.uuid;
      this.name = data.name;
      this.type = data.type;
      this.spread_pages = data.spread_pages;
      this.page_elements = [];
      this.left_margin = data.left_margin;
      this.top_margin = data.top_margin;
      this.right_margin = data.right_margin;
      this.bottom_margin = data.bottom_margin;
      this.native_size.width = data.native_size.width;
      this.native_size.height = data.native_size.height;
      this.bleed = data.bleed;
      this.guides = [];
      this.grid = [];
      this.ruler_guides = [];
      this.page_active = data.page_active;
      this.blend_mode = data.blend_mode;
      this.page_name = data.page_name;
      if (data.grid) {
        for (let i = 0; data.grid.length; i++) {
          let l = data.grid[i];
          let fpoint = new Point(l.f.x, l.f.y);
          let tpoint = new Point(l.t.x, l.t.y);
          let c = l.c;
          let line3 = new Line(fpoint, tpoint, c);
          this.grid.push(line3);
        }
      }
      if (data.ruler_guides) {
        for (let i = 0; i < data.ruler_guides.length; i++) {
          let g = data.ruler_guides[i];
          let guide = new Guide(view, g.type, 0);
          guide.native_value = g.native_value;
          guide.screen_value = g.screen_value;
          guide.visible = g.visible;
          guide.selected = g.selected;
          this.ruler_guides.push(guide);
        }
      }
      this.gradient = null;
      if (data.gradient != void 0 && data.gradient != null) {
        let g = data.gradient;
        let samples = [];
        for (let s = 0; s < g.samples.length; s++) {
          let sa = g.samples[s];
          let sample = new Sample(sa.pos, sa.color, sa.opacity);
          samples.push(sample);
        }
        this.gradient = new Gradient(g.type, g.rotation, samples);
      }
      this.set_fill_style(data.fill_style);
      this.set_stroke_style(data.stroke_style);
      this.opacity = data.opacity;
      this.blend_mode = data.blend_mode;
      this.locked = data.locked;
      this.user_data = data.user_data;
      for (let i = 0; i < data.page_elements.length; i++) {
        let p_element = data.page_elements[i];
        let element3 = this.init_element_new(p_element);
        if (element3)
          this.add_page_element(element3);
      }
    }
    initPage(data) {
      let view = g_typograph_document.canvas_view;
      this.page_elements = [];
      this.id = data.id;
      this.type = data.type;
      this.spread_pages = null;
      if (data.type == "spread") {
        this.type = "spread_horizontal";
        this.spread_pages = 2;
      }
      this.left_margin = data.leftmargin;
      this.top_margin = data.topmargin;
      this.right_margin = data.rightmargin;
      this.bottom_margin = data.bottommargin;
      this.native_size.width = data.nativeSize.width;
      this.native_size.height = data.nativeSize.heigth;
      this.bleed = data.bleed;
      this.guides = [];
      if (data.pagename)
        this.name = data.pagename;
      if (data.name)
        this.name = data.name;
      this.ruler_guides = [];
      this.grid = [];
      if (data.grid) {
        for (let i = 0; data.grid.length; i++) {
          let g = data.grid[i];
          let fpoint = new Point(g.f.x, g.f.y);
          let tpoint = new Point(g.t.x, g.t.y);
          let c = g.c;
          let line3 = new Line(fpoint, tpoint, c);
          this.grid.push(line3);
        }
      }
      if (data.rulerguides) {
        for (let i = 0; i < data.rulerguides.length; i++) {
          let g = data.rulerguides[i];
          let guide = new Guide(view, g.type, 0);
          guide.native_value = g.native_value;
          guide.screen_value = g.screen_value;
          guide.visible = g.visible;
          guide.selected = g.selected;
          this.ruler_guides.push(guide);
        }
      }
      if (data.gradient != void 0 && data.gradient != null) {
        let g = data.gradient;
        let samples = [];
        for (let s = 0; s < g.samples.length; s++) {
          let sa = g.samples[s];
          let sample = new Sample(sa.pos, sa.color, sa.opacity);
          samples.push(sample);
        }
        this.gradient = new Gradient(g.type, g.rotation, samples);
      }
      if (data.fillStyle != void 0)
        this.set_fill_style(data.fillStyle);
      if (data.strokeStyle != void 0)
        this.set_stroke_style(data.strokeStyle);
      if (data.opacity != void 0)
        this.opacity = data.opacity;
      if (data.blend_mode != void 0)
        this.blend_mode = data.blend_mode;
      if (data.locked != void 0)
        this.locked = data.locked;
      if (data.userdata != void 0)
        this.user_data = data.userdata;
      for (let i = 0; i < data.pageelements.length; i++) {
        let p_element = data.pageelements[i];
        let element3 = this.initElement(p_element);
        if (element3)
          this.add_page_element(element3);
      }
    }
    init_element_new(elemen_, group = true) {
      if (elemen_.type == "group") {
        group = true;
        if (elemen_.page_elements) {
          let p_element = elemen_;
          let elements = [];
          for (let i = 0; i < p_element.page_elements.length; i++) {
            let el2 = p_element.page_elements[i];
            el2.selected = true;
            elements.push(this.init_element_new(el2, group));
          }
          let el = this.add_page_element_group(elements, group);
          el.uuid = elemen_.uuid;
          el.name = elemen_.name;
          el.user_data = elemen_.data;
          el.rotation_cx = 0;
          el.rotation_cy = 0;
          if (elemen_.auto_layout) {
            el.auto_layout = elemen_.auto_layout;
            let templates = [];
            for (let t = 0; t < p_element.auto_layout.templates.length; t++) {
              let group_p = elemen_.auto_layout.templates[t];
              let g_elements = [];
              let isgroup = true;
              for (let ge = 0; ge < group_p.page_elements.length; ge++) {
                let el_p = group_p.page_elements[ge];
                g_elements.push(this.init_element_new(el_p, group));
              }
              let new_group = this.add_page_element_group(g_elements, isgroup);
              new_group.name = group_p.name;
              templates.push(new_group);
            }
            el.auto_layout = new AutoLayout(p_element.auto_layout.columns, p_element.auto_layout.column_gab, p_element.auto_layout.row_gab, templates);
            for (let e = 1; e < el.page_elements.length; e++) {
              let clip = el.page_elements[e];
              if (clip.type == "group")
                el.auto_layout.clips.push(clip);
            }
          }
          return el;
        }
        return null;
      }
      if (elemen_.type == "rect") {
        let p_element = elemen_;
        let el = this.add_page_element_rect(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          group
        );
        this.init_page_element_general(el, elemen_);
        return el;
      }
      if (elemen_.type == "circel") {
        let p_element = elemen_;
        let el = this.add_page_element_circel(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          group
        );
        this.init_page_element_general(el, elemen_);
        return el;
      }
      if (elemen_.type == "roundedrect") {
        let p_element = elemen_;
        let el = this.add_page_element_roundedrect(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          p_element.native_radius,
          group
        );
        this.init_page_element_general(el, elemen_);
        return el;
      }
      if (elemen_.type == "triangle") {
        let p_element = elemen_;
        let el = this.add_page_element_triangle(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          group
        );
        this.init_page_element_general(el, elemen_);
        return el;
      }
      if (elemen_.type == "line") {
        let p_element = elemen_;
        let x = p_element.native_rect.position.x;
        let y = p_element.native_rect.position.y;
        let h = p_element.native_rect.size.height;
        let w = p_element.native_rect.size.width;
        if (h == 4) {
          y += 2;
          h = 0;
        }
        if (w == 4) {
          x += 2;
          w = 0;
        }
        let el = this.add_page_element_line(x, y, x + w, y + h, p_element.native_border_size, group);
        this.init_page_element_general(el, elemen_);
        return el;
      }
      if (elemen_.type == "image") {
        let p_element = elemen_;
        let el = this.add_page_element_image(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          p_element.url,
          p_element.fit,
          group
        );
        this.init_page_element_general(el, elemen_);
        el.native_image_rect.position.x = p_element.native_image_rect.position.x;
        el.native_image_rect.position.y = p_element.native_image_rect.position.y;
        el.native_image_rect.size.width = p_element.native_image_rect.size.width;
        el.native_image_rect.size.height = p_element.native_image_rect.size.height;
        el.image_angle = p_element.image_angle;
        el.key = null;
        el.image_scale = p_element.image_scale;
        el.image_horizontal_alignment = p_element.image_horizontal_alignment;
        el.image_vertical_alignment = p_element.image_vertical_alignment;
        el.fit = p_element.fit;
        return el;
      }
      if (elemen_.type == "text") {
        let p_element = elemen_;
        let el = this.add_page_element_text(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          p_element.native_font_size,
          p_element.text,
          group
        );
        this.init_page_element_general(el, elemen_);
        el.font = p_element.font;
        el.style = p_element.style;
        el.native_font_size = p_element.native_font_size;
        el.native_auto_leading = p_element.native_auto_leading;
        el.text_align = p_element.text_align;
        el.vertical_align = p_element.vertical_align;
        el.native_padding = p_element.native_padding;
        el.native_margin_button = p_element.native_margin_button;
        el.columns = p_element.columns;
        el.native_columns_cap = p_element.native_columns_cap;
        el.setTextColor(p_element.text_color);
        el.setTextStrokeColor(p_element.text_stroke_color);
        el.native_text_stroke_size = p_element.native_text_stroke_size;
        el.pod_tekst_style = p_element.pod_tekst_style;
        el.pod_font_name = p_element.pod_font_name;
        el.pod_font_weight = p_element.pod_font_weight;
        return el;
      }
      if (elemen_.type == "table") {
        let p_element = elemen_;
        let el = this.add_page_element_table(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          p_element.rows,
          p_element.columns,
          group
        );
        this.init_page_element_general(el, elemen_);
        return el;
      }
      if (elemen_.type == "rich") {
        let p_element = elemen_;
        let text = p_element.text;
        if (p_element.save_elements)
          text = "";
        let el = this.add_page_element_richtext(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          p_element.native_rect.size.height,
          p_element.native_font_size,
          text,
          group
        );
        this.init_page_element_general(el, elemen_);
        el.rich_text.setPageElement(el);
        el.font = p_element.font;
        el.font_name = p_element.font_name;
        el.font_style = p_element.font_style;
        el.native_font_size = p_element.font_size;
        el.native_autoleading = p_element.native_autoleading;
        el.text_align = p_element.text_align;
        el.vertical_align = p_element.vertical_align;
        el.native_padding = p_element.native_padding;
        el.native_margin_button = p_element.native_margin_button;
        el.columns = p_element.columns;
        el.native_columns_cap = p_element.native_columns_cap;
        el.setTextColor(p_element.text_color);
        el.setTextStrokeColor(p_element.text_stroke_color);
        el.native_text_stroke_size = el.native_text_stroke_size;
        el.pod_tekst_style = p_element.pod_tekst_style;
        el.pod_font_name = p_element.pod_font_name;
        el.pod_font_weight = p_element.pod_font_weight;
        el.size.width = el.native_rect.size.width * gMMtoPixel;
        el.size.height = el.native_rect.size.height * gMMtoPixel;
        if (p_element.save_elements) {
          if (p_element.save_elements.length > 0) {
            for (let t = 0; t < p_element.save_elements.length; t++)
              el.save_elements.push(p_element.save_elements[t]);
            el.rich_text.editor.elementmanager.elements = [];
            for (let t = 0; t < el.save_elements.length; t++) {
              let tel = el.save_elements[t];
              for (let w = 0; w < tel.words.length; w++)
                tel.words[w].element = tel;
              let nel = el.rich_text.editor.elementmanager.new_element(tel.font);
              nel.text = tel.text;
              for (let w = 0; w < tel.words.length; w++) {
                let word = tel.words[w];
                let nword = el.rich_text.editor.elementmanager.new_word(word.prefix, word.word, word.offset, word.font);
                nel.words.push(nword);
              }
              nel.enumerable = tel.enumerable;
              nel.writable = tel.writable;
              nel.max_word_height = tel.max_word_height;
              nel.max_ascent = tel.max_ascent;
              nel.max_descent = tel.max_descent;
              nel.url = tel.url;
              el.rich_text.editor.elementmanager.addElement(nel);
            }
            if (el.rich_text.editor.elementmanager.elements.length > 0) {
              el.default_font = el.rich_text.editor.elementmanager.elements[0].font;
            }
          }
        }
        el.rich_text.editor.setAlignment(el.text_align);
        el.rich_text.editor.linalyze();
        return el;
      }
      if (elemen_.type == "path") {
        let p_element = elemen_;
        let el = this.add_page_element_path(p_element.path.commands, group);
        this.init_page_element_general(el, elemen_);
        el.save_path = p_element.save_path;
        return el;
      }
      return null;
    }
    init_page_element_general(el, elemen_) {
      let p_element = elemen_;
      el.uuid = p_element.uuid;
      el.name = p_element.name;
      el.set_fill_style(elemen_.fill_style);
      el.set_stroke_style(elemen_.stroke_style);
      el.opacity = p_element.opacity;
      el.blend_mode = p_element.blend_mode;
      el.native_border_size = p_element.native_border_size;
      el.angle = p_element.angle;
      el.shadow = p_element.shadow;
      el.set_shadow_color(elemen_.shadow_color);
      el.native_shadow_blur = p_element.native_shadow_blur;
      el.native_shadow_offset_x = p_element.native_shadow_offset_x;
      el.native_shadow_offset_y = p_element.native_shadow_offset_y;
      el.shadow_angle = p_element.shadow_angle;
      el.native_shadow_length = p_element.native_shadow_length;
      el.native_rotation_cx = p_element.native_rotation_cx;
      el.native_rotation_cy = p_element.native_rotation_cy;
      el.locked = p_element.locked;
      el.gradient = p_element.gradient;
      if (el.gradient) {
        let g = p_element.gradient;
        let samples = [];
        for (let s = 0; s < g.samples.length; s++) {
          let sa = g.samples[s];
          let sample = new Sample(sa.pos, sa.color, sa.opacity);
          samples.push(sample);
        }
        el.gradient = new Gradient(g.type, g.rotation, samples);
      }
      el.hidden = p_element.hidden;
      el.stroke_dash = p_element.stroke_dash;
      if (p_element.user_data == "")
        el.user_data = null;
      else
        el.user_data = p_element.user_data;
      if (elemen_.native_radiuses) {
        let e = el;
        for (let r = 0; r < 4; r++)
          e.native_radiuses[r] = elemen_.native_radiuses[r];
        if (el.type == "roudedrect")
          e.native_radius = elemen_.native_radius;
      }
      return el;
    }
    initElement(elemen_, group = true) {
      if (elemen_.type == "group") {
        if (elemen_.pageelements) {
          let p_element = elemen_;
          let elements = [];
          for (let i = 0; i < elemen_.pageelements.length; i++) {
            let el2 = elemen_.pageelements[i];
            el2.selected = true;
            elements.push(this.initElement(el2, group));
          }
          let el = this.add_page_element_group(elements, group);
          if (elemen_.uuid)
            el.uuid = elemen_.uuid;
          if (elemen_.name)
            el.name = elemen_.name;
          if (elemen_.auto_layout)
            el.auto_layout = elemen_.auto_layout;
          if (elemen_.user_data)
            el.user_data = elemen_.user_data;
          if (elemen_.auto_layout) {
            let templates = [];
            for (let t = 0; t < p_element.auto_layout.templates.length; t++) {
              let group_p = p_element.auto_layout.templates[t];
              let g_elements = [];
              let isgroup = true;
              for (let ge = 0; ge < group_p.pageelements.length; ge++) {
                let el_p = group_p.pageelements[ge];
                let pageelement = this.create_page_element(el_p, isgroup);
                g_elements.push(pageelement);
              }
              let new_group = this.add_page_element_group(g_elements, isgroup);
              new_group.name = group_p.name;
              templates.push(new_group);
            }
            el.auto_layout = new AutoLayout(p_element.auto_layout.columns, p_element.auto_layout.column_gab, p_element.auto_layout.row_gab, templates);
            for (let e = 1; e < el.page_elements.length; e++) {
              let clip = el.page_elements[e];
              if (clip.type == "group")
                el.auto_layout.clips.push(clip);
            }
          }
          return el;
        }
        return null;
      }
      if (elemen_.type == "rect") {
        let p_element = elemen_;
        let el = this.add_page_element_rect(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          elemen_.native_rect.size.heigth,
          group
        );
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        if (elemen_.rotation_cx == null)
          el.rotation_cx = 0;
        if (elemen_.rotation_cy == null)
          el.rotation_cy = 0;
        if (elemen_.native_rotation_cx == null)
          el.native_rotation_cx = 0;
        if (elemen_.native_rotation_cy == null)
          el.native_rotation_cy = 0;
        el.locked = p_element.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        return el;
      }
      if (elemen_.type == "circel") {
        let p_element = elemen_;
        let el = this.add_page_element_circel(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          elemen_.native_rect.size.heigth,
          group
        );
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = p_element.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        return el;
      }
      if (elemen_.type == "roundedrect") {
        let p_element = elemen_;
        let el = this.add_page_element_roundedrect(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          elemen_.native_rect.size.heigth,
          p_element.native_radius,
          group
        );
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        if (elemen_.native_radiuses != void 0) {
          for (let r = 0; r < 4; r++) {
            el.native_radiuses[r] = elemen_.native_radiuses[r];
          }
        }
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        return el;
      }
      if (elemen_.type == "triangle") {
        let p_element = elemen_;
        let el = this.add_page_element_triangle(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          elemen_.native_rect.size.heigth,
          group
        );
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        return el;
      }
      if (elemen_.type == "line") {
        let p_element = elemen_;
        let x = p_element.native_rect.position.x;
        let y = p_element.native_rect.position.y;
        let h = elemen_.native_rect.size.heigth;
        let w = p_element.native_rect.size.width;
        if (h == 4) {
          y += 2;
          h = 0;
        }
        if (w == 4) {
          x += 2;
          w = 0;
        }
        let el = this.add_page_element_line(x, y, x + w, y + h, elemen_.nativeborderSize, group);
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        return el;
      }
      if (elemen_.type == "image") {
        let p_element = elemen_;
        let url = elemen_.url;
        url = g_asset_url + url;
        url = encodeURI(url);
        let expe = /\/\d+\//;
        let str = expe.exec(url);
        if (str)
          url = url.replace(str[0], "/" + g_typograph_document.setup.user_id + "/");
        let el = this.add_page_element_image(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          elemen_.native_rect.size.heigth,
          url,
          elemen_.fit,
          group
        );
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        if (elemen_.native_radiuses != void 0) {
          for (let r = 0; r < 4; r++) {
            el.native_radiuses[r] = elemen_.native_radiuses[r];
          }
        }
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        el.native_image_rect.position.x = elemen_.nativeimage_rect.position.x;
        el.native_image_rect.position.y = elemen_.nativeimage_rect.position.y;
        el.native_image_rect.size.width = elemen_.nativeimage_rect.size.width;
        el.native_image_rect.size.height = elemen_.nativeimage_rect.size.heigth;
        el.image_angle = elemen_.angle;
        el.key = null;
        el.image_scale = elemen_.imagescale;
        el.image_horizontal_alignment = elemen_.imagehorizontalalignment;
        el.image_vertical_alignment = elemen_.imageverticalalignment;
        el.fit = elemen_.fit;
        return el;
      }
      if (elemen_.type == "text") {
        let p_element = elemen_;
        let native_font_size = 24;
        if (elemen_.native_font_size !== void 0)
          native_font_size = elemen_.native_font_size;
        else {
          if (elemen_.native_fontsize !== void 0)
            native_font_size = elemen_.native_fontsize;
        }
        let el = this.add_page_element_text(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          elemen_.native_rect.size.heigth,
          native_font_size,
          p_element.text,
          group
        );
        el.set_fill_style(elemen_.fillStyle);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        el.font = elemen_.font;
        el.style = elemen_.style;
        el.native_font_size = elemen_.native_fontsize;
        el.native_auto_leading = elemen_.native_autoleading;
        el.text_align = elemen_.text_align;
        el.vertical_align = elemen_.vertical_align;
        el.native_padding = elemen_.native_padding;
        el.native_margin_button = elemen_.native_margin_button;
        el.columns = elemen_.columns;
        el.native_columns_cap = elemen_.native_columns_cap;
        el.setTextColor(elemen_.text_color);
        el.setTextStrokeColor(elemen_.text_color_stroke);
        el.native_text_stroke_size = elemen_.native_textstroke_size;
        el.pod_tekst_style = elemen_.pod_tekststyle;
        el.pod_font_name = elemen_.pod_fontname;
        el.pod_font_weight = elemen_.pod_fontweight;
        return el;
      }
      if (elemen_.type == "table") {
        let p_element = elemen_;
        let el = this.add_page_element_table(
          p_element.native_rect.position.x,
          p_element.native_rect.position.y,
          p_element.native_rect.size.width,
          elemen_.native_rect.size.heigth,
          p_element.rows,
          p_element.columns,
          group
        );
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        return el;
      }
      if (elemen_.type == "rich") {
        let p_element = elemen_;
        let native_font_size = 24;
        if (elemen_.nativeFontsize)
          native_font_size = elemen_.nativeFontsize;
        if (p_element.native_font_size !== void 0)
          native_font_size = p_element.native_font_size;
        let el = null;
        if (elemen_.saveelements) {
          el = this.add_page_element_richtext(
            p_element.native_rect.position.x,
            p_element.native_rect.position.y,
            p_element.native_rect.size.width,
            elemen_.native_rect.size.heigth,
            native_font_size,
            "",
            group
          );
        } else {
          el = this.add_page_element_richtext(
            p_element.native_rect.position.x,
            p_element.native_rect.position.y,
            p_element.native_rect.size.width,
            elemen_.native_rect.size.heigth,
            native_font_size,
            p_element.text,
            group
          );
        }
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.rich_text.setPageElement(el);
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        if (elemen_.saveelements) {
          if (elemen_.saveelements.length > 0) {
            for (let t = 0; t < elemen_.saveelements.length; t++) {
              let el_ = elemen_.saveelements[t];
              if (el_.hasOwnProperty("max_word_height"))
                el.save_elements.push(el_);
              else {
                let nfont = { name: el_.font.name, size: el_.font.size, formatting: el_.font.formatting, attributes: el_.font.attributes, style: el_.font.style, text: el_.font.text, font_style: el_.font.fontstyle };
                let nel = { font: nfont, text: el_.text, words: el_.words, enumerable: el_.enumerable, writable: el_.writable, max_word_height: el_.maxWordHeight, max_ascent: el_.maxAscent, max_descent: el_.max_descent, url: el_.url, hover_attributes: el_.hoverAttributes };
                el.save_elements.push(nel);
              }
            }
            el.rich_text.editor.elementmanager.elements = [];
            for (let t = 0; t < el.save_elements.length; t++) {
              let tel = el.save_elements[t];
              for (let w = 0; w < tel.words.length; w++) {
                tel.words[w].element = tel;
              }
              let nel = el.rich_text.editor.elementmanager.new_element(tel.font);
              nel.text = tel.text;
              for (let w = 0; w < tel.words.length; w++) {
                let word = tel.words[w];
                if (word.word == "" || word.font == null)
                  continue;
                let font3 = word.font;
                let nfont = { name: font3.name, size: font3.size, formatting: font3.formatting, attributes: font3.attributes, style: font3.style, text: font3.text, font_style: font3.fontstyle };
                let nword = el.rich_text.editor.elementmanager.new_word(word.prefix, word.word, word.offset, nfont);
                nel.words.push(nword);
              }
              nel.enumerable = tel.enumerable;
              nel.writable = tel.writable;
              nel.max_word_height = elemen_.maxWordHeight;
              nel.max_ascent = elemen_.maxAscent;
              nel.max_descent = elemen_.maxDescent;
              nel.url = tel.url;
              el.rich_text.editor.elementmanager.addElement(nel);
            }
            if (el.rich_text.editor.elementmanager.elements.length > 0) {
              el.default_font = el.rich_text.editor.elementmanager.elements[0].font;
            }
          }
        }
        el.font = elemen_.font;
        el.font.data = this.rename_modification_date(el.font.data);
        el.font_name = elemen_.fontname;
        el.font_style = elemen_.fontstyle;
        el.native_font_size = elemen_.native_fontsize;
        el.native_autoleading = elemen_.native_autoleading;
        el.text_align = elemen_.text_align;
        el.vertical_align = elemen_.vertical_align;
        el.native_padding = elemen_.native_padding;
        el.native_margin_button = elemen_.native_margin_button;
        el.columns = elemen_.columns;
        el.native_columns_cap = elemen_.native_columns_cap;
        el.setTextColor(elemen_.text_color);
        el.setTextStrokeColor(elemen_.text_stroke_color);
        el.native_text_stroke_size = elemen_.native_textstroke_size;
        el.pod_tekst_style = elemen_.pod_tekststyle;
        el.pod_font_name = elemen_.pod_fontname;
        el.pod_font_weight = elemen_.pod_fontweight;
        el.size.width = el.native_rect.size.width * gMMtoPixel;
        el.size.height = el.native_rect.size.height * gMMtoPixel;
        el.rich_text.editor.setAlignment(el.text_align);
        el.rich_text.editor.linalyze();
        return el;
      }
      if (elemen_.type == "path") {
        let p_element = elemen_;
        let el = this.add_page_element_path(p_element.path.commands, group);
        if (elemen_.uuid)
          el.uuid = elemen_.uuid;
        el.set_fill_style(elemen_.fillStyle);
        el.set_stroke_style(elemen_.strokeStyle);
        el.opacity = elemen_.opacity;
        el.native_border_size = elemen_.nativeborderSize;
        el.angle = elemen_.angle;
        el.shadow = elemen_.shadow;
        el.set_shadow_color(elemen_.shadowcolor);
        el.native_shadow_blur = elemen_.native_shadowblur;
        el.native_shadow_offset_x = elemen_.native_shadowoffset_x;
        el.native_shadow_offset_y = elemen_.native_shadowoffset_y;
        el.shadow_angle = elemen_.shadow_angle;
        el.native_shadow_length = elemen_.native_shadowlenght;
        el.native_rotation_cx = elemen_.native_rotation_cx;
        el.native_rotation_cy = elemen_.native_rotation_cy;
        el.locked = elemen_.locked;
        if (p_element.gradient != void 0) {
          let g = p_element.gradient;
          let samples = [];
          for (let s = 0; s < g.samples.length; s++) {
            let sa = g.samples[s];
            let sample = new Sample(sa.pos, sa.color, sa.opacity);
            samples.push(sample);
          }
          el.gradient = new Gradient(g.type, g.rotation, samples);
        }
        if (elemen_.hidden != void 0)
          el.hidden = p_element.hidden;
        if (elemen_.strokedash != void 0)
          el.stroke_dash = elemen_.strokedash;
        if (elemen_.userdata != void 0) {
          if (elemen_.userdata == "")
            elemen_.userdata = null;
          el.user_data = elemen_.userdata;
        } else {
          el.user_data = null;
        }
        if (elemen_.uuid != void 0)
          el.uuid = elemen_.uuid;
        if (elemen_.name != void 0)
          el.name = elemen_.name;
        el.save_path = new Path(elemen_.save_path.commands);
        el.save_path.color = elemen_.save_path.color;
        return el;
      }
    }
    create_page_element(el_p, group = false) {
      let pageelement = this.initElement(el_p, group);
      return pageelement;
    }
    rename_modification_date(data) {
      let ndata = data;
      let in_data = data;
      if (in_data.hasOwnProperty("lastModified")) {
        ndata = { family: data.family, variants: data.variants, subsets: data.subsets, version: data.version, modification_date: (/* @__PURE__ */ new Date()).toISOString(), files: data.files, category: data.category, kind: data.kind };
      }
      return ndata;
    }
    addcontentfrompage(frompage, replace_rulers = false) {
      let view = g_typograph_document.canvas_view;
      if (replace_rulers) {
        if (frompage.ruler_guides.length > 0) {
          if (replace_rulers)
            this.ruler_guides = [];
          for (let i = 0; i < frompage.ruler_guides.length; i++) {
            let g = frompage.ruler_guides[i];
            let guide = new Guide(view, g.type, 0);
            guide.native_value = g.native_value;
            guide.selected = g.selected;
            this.ruler_guides.push(guide);
          }
        }
      }
      let group_elements = [];
      for (let i = 0; i < frompage.page_elements.length; i++) {
        let el = frompage.page_elements[i];
        if (this.page_elements.indexOf(el) == -1) {
          el.selected = true;
          this.page_elements.push(el);
          group_elements.push(el);
        }
      }
      if (group_elements.length > 1) {
        g_typograph_document.create_group();
        this.deselectAllElements();
      }
    }
    setPageSize(width, height) {
      this.width = width;
      this.height = height;
      this.updatepageguides();
      this.setScreenSize(g_typograph_document.canvas_view);
      g_typograph_document.resize_main_canvas();
    }
    setBleed(bleed) {
      this.bleed = bleed;
      g_typograph_document.redraw();
    }
    get_Gradient() {
      return this.gradient;
    }
    set_gradient(gr) {
      this.gradient = gr;
    }
    get_fill_style() {
      if (this.fill_style.indexOf("COLOR_") != -1) {
        let rgba = "#0f0";
        let i = parseInt(this.fill_style.split("_")[1]);
        if (i <= g_typograph_document.get_brand_colors().colors.length)
          rgba = g_typograph_document.get_brand_colors().colors[i - 1].rgba;
        return rgba;
      } else
        return this.fill_style;
    }
    get_fill_style_name() {
      if (this.fill_style.indexOf("COLOR_") != -1) {
        return this.fill_style;
      } else {
        return "none";
      }
    }
    get_fill_style_native() {
      return this.fill_style;
    }
    get_stroke_style() {
      if (this.stroke_style.indexOf("COLOR_") != -1) {
        let rgba = "#0f0";
        let i = parseInt(this.stroke_style.split("_")[1]);
        if (i <= g_typograph_document.get_brand_colors().colors.length)
          rgba = g_typograph_document.get_brand_colors().colors[i - 1].rgba;
        return rgba;
      } else
        return this.stroke_style;
    }
    get_stroke_style_name() {
      if (this.stroke_style.indexOf("COLOR_") != -1) {
        return this.stroke_style;
      } else {
        return "none";
      }
    }
    get_stroke_style_native() {
      return this.stroke_style;
    }
    set_fill_style(s) {
      this.fill_style = s;
    }
    set_stroke_style(s) {
      this.stroke_style = s;
    }
    setScreenSize(view) {
      this.native_size = new Size(this.width, this.height);
      this.screen_size = new Size(this.width, this.height);
      this.screen_size.width = this.native_size.width * view.zoom * gMMtoScreen;
      this.screen_size.height = this.native_size.height * view.zoom * gMMtoScreen;
    }
    is_point_in_element(view, point) {
      for (let e = this.page_elements.length; e > 0; e--) {
        let element3 = this.page_elements[e - 1];
        if (element3.is_point_in_path(view, point))
          return element3;
        if (element3.type == "group" && element3.auto_layout) {
          if (element3.auto_layout.is_point_in_clip_control(element3, view, point))
            return element3;
        }
      }
      return null;
    }
    checkguidealignment(selectedelement) {
      if (g_typograph_document.canvas_view.show_guids) {
        for (let i = 0; i < this.ruler_guides.length; i++) {
          let g = this.ruler_guides[i];
          g.selected = false;
          if (g.type == "Ver") {
            if (gSticky.sticky_x == false) {
              let gX = g.screen_value - g_typograph_document.canvas_view.offset_x;
              if (selectedelement.screen_rect.position.x > gX - gSticky.stick_size && selectedelement.screen_rect.position.x < gX + gSticky.stick_size) {
                gSticky.sticky_x = true;
                gSticky.x = gX;
                g.selected = true;
              }
              if (gSticky.sticky_x == false) {
                let middel = selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width / 2;
                if (middel > gX - gSticky.stick_size && middel < gX + gSticky.stick_size) {
                  gSticky.sticky_x = true;
                  gSticky.x = gX - selectedelement.screen_rect.size.width / 2;
                  g.selected = true;
                }
              }
              if (gSticky.sticky_x == false) {
                if (selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width > gX - gSticky.stick_size && selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width < gX + gSticky.stick_size) {
                  gSticky.sticky_x = true;
                  gSticky.x = gX - selectedelement.screen_rect.size.width;
                  g.selected = true;
                }
              }
            }
          }
          if (g.type == "Hor") {
            if (gSticky.sticky_y == false) {
              let gY = g.screen_value - g_typograph_document.canvas_view.offset_y;
              if (selectedelement.screen_rect.position.y > gY - gSticky.stick_size && selectedelement.screen_rect.position.y < gY + gSticky.stick_size) {
                gSticky.sticky_y = true;
                gSticky.y = gY;
                g.selected = true;
              }
              if (gSticky.sticky_y == false) {
                let middel = selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height / 2;
                if (middel > gY - gSticky.stick_size && middel < gY + gSticky.stick_size) {
                  gSticky.sticky_y = true;
                  gSticky.y = gY - selectedelement.screen_rect.size.height / 2;
                  g.selected = true;
                }
              }
              if (gSticky.sticky_y == false) {
                if (selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height > gY - gSticky.stick_size && selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height < gY + gSticky.stick_size) {
                  gSticky.sticky_y = true;
                  gSticky.y = gY - selectedelement.screen_rect.size.height;
                  g.selected = true;
                }
              }
            }
          }
        }
      }
    }
    checkguidealignment_sizing(selectedelement, offsetx, offsety, handle) {
      if (g_typograph_document.canvas_view.show_guids) {
        let s = gSticky.stick_size;
        for (let i = 0; i < this.ruler_guides.length; i++) {
          let g = this.ruler_guides[i];
          g.selected = false;
          if (g.type == "Ver") {
            let x = g.screen_value - g_typograph_document.canvas_view.offset_x;
            if (gSticky.sticky_x == false) {
              if (handle.type == "t_l" || handle.type == "l_m" || handle.type == "l_b") {
                let x1 = selectedelement.screen_saved_rect.position.x + offsetx;
                let x2 = x - s;
                let x3 = x + s;
                if (x1 >= x2 && x1 <= x3) {
                  gSticky.sticky_x = true;
                  gSticky.x = x;
                  g.selected = true;
                }
              }
            }
            if (gSticky.sticky_x == false) {
              if (handle.type == "t_r" || handle.type == "r_m" || handle.type == "r_b") {
                let x1 = selectedelement.screen_saved_rect.position.x + offsetx + selectedelement.screen_saved_rect.size.width;
                let x2 = x - s;
                let x3 = x + s;
                if (x1 >= x2 && x1 <= x3) {
                  gSticky.sticky_x = true;
                  gSticky.x = x;
                  g.selected = true;
                }
              }
            }
          }
          if (g.type == "Hor") {
            let y = g.screen_value - g_typograph_document.canvas_view.offset_y;
            if (gSticky.sticky_y == false) {
              if (handle.type == "t_l" || handle.type == "t_m" || handle.type == "t_r") {
                let y1 = selectedelement.screen_saved_rect.position.y + offsety;
                let y2 = y - s;
                let y3 = y + s;
                if (y1 >= y2 && y1 <= y3) {
                  gSticky.sticky_y = true;
                  gSticky.y = y;
                  g.selected = true;
                }
              }
            }
            if (gSticky.sticky_y == false) {
              if (handle.type == "l_b" || handle.type == "m_b" || handle.type == "r_b") {
                let y1 = selectedelement.screen_saved_rect.position.y + offsety + selectedelement.screen_saved_rect.size.height;
                let y2 = y - s;
                let y3 = y + s;
                if (y1 >= y2 && y1 <= y3) {
                  gSticky.sticky_y = true;
                  gSticky.y = y;
                  g.selected = true;
                }
              }
            }
          }
        }
      }
    }
    deselect_all_guides() {
      for (let i = 0; i < this.ruler_guides.length; i++)
        this.ruler_guides[i].selected = false;
    }
    checkalignment(selectedelement) {
      this.guides = [];
      let temp_x = [];
      let temp_y = [];
      gSticky.sticky_x = false;
      gSticky.sticky_y = false;
      gSticky.x = 0;
      gSticky.y = 0;
      let s = gSticky.stick_size;
      let pageelements = [];
      if (selectedelement.type == "temp") {
        let tempelement = selectedelement;
        if (selectedelement.type == "temp") {
          for (let i = 0; i < this.page_elements.length; i++) {
            let pageelement = this.page_elements[i];
            if (tempelement.elements.indexOf(pageelement) == -1) {
              pageelements.push(pageelement);
            }
          }
        }
      } else {
        for (let i = 0; i < this.page_elements.length; i++)
          pageelements.push(this.page_elements[i]);
      }
      if (this.show_temp) {
        for (let i = 0; i < this.temp_page_elements.length; i++)
          pageelements.push(this.temp_page_elements[i]);
      }
      for (let i = pageelements.length; i > 0; i--) {
        let pageelement = pageelements[i - 1];
        if (pageelement != selectedelement) {
          {
            if (selectedelement.screen_rect.position.x > pageelement.screen_rect.position.x - gSticky.stick_size && selectedelement.screen_rect.position.x < pageelement.screen_rect.position.x + gSticky.stick_size) {
              let distance = Math.abs(pageelement.screen_rect.position.x - selectedelement.screen_rect.position.x);
              gSticky.sticky_x = true;
              gSticky.x = pageelement.screen_rect.position.x;
              let lf = new Point(pageelement.screen_rect.position.x, 0);
              let lt = new Point(pageelement.screen_rect.position.x, 2e3);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            let middle = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            if (selectedelement.screen_rect.position.x > middle - gSticky.stick_size && selectedelement.screen_rect.position.x < middle + gSticky.stick_size) {
              let distance = Math.abs(pageelement.screen_rect.position.x - middle);
              gSticky.sticky_x = true;
              gSticky.x = middle;
              let lf = new Point(middle, 0);
              let lt = new Point(middle, 2e3);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            if (selectedelement.screen_rect.position.x > pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - gSticky.stick_size && selectedelement.screen_rect.position.x < pageelement.screen_rect.position.x + pageelement.screen_rect.size.width + gSticky.stick_size) {
              let distance = Math.abs(pageelement.screen_rect.position.x - pageelement.screen_rect.position.x + pageelement.screen_rect.size.width);
              gSticky.sticky_x = true;
              gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
              let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 0);
              let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 2e3);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            let right = selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width;
            let left = pageelement.screen_rect.position.x;
            if (right > left - s && right < left + s) {
              let distance = Math.abs(left - right);
              gSticky.sticky_x = true;
              gSticky.x = left - selectedelement.screen_rect.size.width;
              let lf = new Point(left, 0);
              let lt = new Point(left, 2e3);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            let middle = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let right = selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width;
            if (right > middle - s && right < middle + s) {
              let distance = Math.abs(right - middle);
              gSticky.sticky_x = true;
              gSticky.x = middle - selectedelement.screen_rect.size.width;
              let lf = new Point(middle, 0);
              let lt = new Point(middle, 2e3);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            let right = selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width;
            let right_right = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            if (right > right_right - s && right < right_right + s) {
              let distance = Math.abs(right - right_right);
              gSticky.sticky_x = true;
              gSticky.x = right_right - selectedelement.screen_rect.size.width;
              let lf = new Point(right_right, 0);
              let lt = new Point(right_right, 2e3);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            if (selectedelement.screen_rect.position.y > pageelement.screen_rect.position.y - gSticky.stick_size && selectedelement.screen_rect.position.y < pageelement.screen_rect.position.y + gSticky.stick_size) {
              let distance = Math.abs(selectedelement.screen_rect.position.y - pageelement.screen_rect.position.y);
              gSticky.sticky_y = true;
              gSticky.y = pageelement.screen_rect.position.y;
              let lf = new Point(0, pageelement.screen_rect.position.y);
              let lt = new Point(2e3, pageelement.screen_rect.position.y);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            let middle = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            if (selectedelement.screen_rect.position.y > middle - gSticky.stick_size && selectedelement.screen_rect.position.y < middle + gSticky.stick_size) {
              let distance = Math.abs(selectedelement.screen_rect.position.y - middle);
              gSticky.sticky_y = true;
              gSticky.y = middle;
              let lf = new Point(0, middle);
              let lt = new Point(2e3, middle);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            if (selectedelement.screen_rect.position.y > pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - gSticky.stick_size && selectedelement.screen_rect.position.y < pageelement.screen_rect.position.y + pageelement.screen_rect.size.height + gSticky.stick_size) {
              let distance = Math.abs(selectedelement.screen_rect.position.y - pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
              gSticky.sticky_y = true;
              gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
              let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
              let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            let bottom = selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height;
            let top = pageelement.screen_rect.position.y;
            if (bottom > top - s && bottom < top + s) {
              let distance = Math.abs(bottom - top);
              gSticky.sticky_y = true;
              gSticky.y = pageelement.screen_rect.position.y - selectedelement.screen_rect.size.height;
              let lf = new Point(0, top);
              let lt = new Point(2e3, top);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            let bottom = selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height;
            let middle = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            if (bottom > middle - s && bottom < middle + s) {
              let distance = Math.abs(bottom - middle);
              gSticky.sticky_y = true;
              gSticky.y = middle - selectedelement.screen_rect.size.height;
              let lf = new Point(0, middle);
              let lt = new Point(2e3, middle);
              let line3 = new Line(lf, lt, "51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            let bottom = selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height;
            let bottom_bottom = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            if (bottom > bottom_bottom - s && bottom < bottom_bottom + s) {
              let distance = Math.abs(bottom - bottom_bottom);
              gSticky.sticky_y = true;
              gSticky.y = bottom_bottom - selectedelement.screen_rect.size.height;
              let lf = new Point(0, bottom_bottom);
              let lt = new Point(2e3, bottom_bottom);
              let line3 = new Line(lf, lt, "51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            let selected_middle = selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height / 2;
            let top = pageelement.screen_rect.position.y;
            if (selected_middle > top - s && selected_middle < top + s) {
              let distance = Math.abs(selected_middle - top);
              gSticky.sticky_y = true;
              gSticky.y = top - selectedelement.screen_rect.size.height / 2;
              let lf = new Point(0, top);
              let lt = new Point(2e3, top);
              let line3 = new Line(lf, lt, "51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            let selected_middle = selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height / 2;
            let bottom = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            if (selected_middle > bottom - s && selected_middle < bottom + s) {
              let distance = Math.abs(selected_middle - bottom);
              gSticky.sticky_y = true;
              gSticky.y = bottom - selectedelement.screen_rect.size.height / 2;
              let lf = new Point(0, bottom);
              let lt = new Point(2e3, bottom);
              let line3 = new Line(lf, lt, "51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
          {
            let selected_middle = selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width / 2;
            let left = pageelement.screen_rect.position.x;
            if (selected_middle > left - s && selected_middle < left + s) {
              let distance = Math.abs(selected_middle - left);
              gSticky.sticky_x = true;
              gSticky.x = left - selectedelement.screen_rect.size.width / 2;
              let lf = new Point(left, 0);
              let lt = new Point(left, 2e3);
              let line3 = new Line(lf, lt, "51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            let selected_middle = selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width / 2;
            let right = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            if (selected_middle > right - s && selected_middle < right + s) {
              let distance = Math.abs(selected_middle - right);
              gSticky.sticky_x = true;
              gSticky.x = right - selectedelement.screen_rect.size.width / 2;
              let lf = new Point(right, 0);
              let lt = new Point(right, 2e3);
              let line3 = new Line(lf, lt, "51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            let middle = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let selected_middle = selectedelement.screen_rect.position.x + selectedelement.screen_rect.size.width / 2;
            if (selected_middle > middle - gSticky.stick_size && selected_middle < middle + gSticky.stick_size) {
              let distance = Math.abs(middle - selected_middle);
              gSticky.sticky_x = true;
              gSticky.x = middle - selectedelement.screen_rect.size.width / 2;
              let lf = new Point(middle, 0);
              let lt = new Point(middle, 2e3);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
            }
          }
          {
            let middle = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            let selected_middle = selectedelement.screen_rect.position.y + selectedelement.screen_rect.size.height / 2;
            if (selected_middle > middle - gSticky.stick_size && selected_middle < middle + gSticky.stick_size) {
              let distance = Math.abs(middle - selected_middle);
              gSticky.sticky_y = true;
              gSticky.y = middle - selectedelement.screen_rect.size.height / 2;
              let lf = new Point(0, middle);
              let lt = new Point(2e3, middle);
              let line3 = new Line(lf, lt, "#51c1fd");
              temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
            }
          }
        }
      }
      if (temp_x.length > 0) {
        let t_x = temp_x[0];
        if (temp_x.length > 1) {
          for (let g_x = 1; g_x < temp_x.length; g_x++) {
            let t = temp_x[g_x];
            if (t.distance < t_x.distance) {
              t_x = t;
            }
          }
        }
        gSticky.sticky_x = true;
        gSticky.x = t_x.value;
        this.guides.push(t_x.line);
      } else {
        gSticky.sticky_x = false;
      }
      if (temp_y.length > 0) {
        let t_y = temp_y[0];
        if (temp_y.length > 1) {
          for (let g_y = 1; g_y < temp_y.length; g_y++) {
            let t = temp_y[g_y];
            if (t.distance < t_y.distance) {
              t_y = t;
            }
          }
        }
        gSticky.sticky_y = true;
        gSticky.y = t_y.value;
        this.guides.push(t_y.line);
      } else {
        gSticky.sticky_y = false;
      }
    }
    checkalignment_sizing(selectedelement, offsetx, offsety, handle, shift) {
      this.guides = [];
      let temp_x = [];
      let temp_y = [];
      gSticky.sticky_x = false;
      gSticky.sticky_y = false;
      gSticky.x = 0;
      gSticky.y = 0;
      let s = gSticky.stick_size;
      let pageelements = [];
      if (selectedelement.type == "temp") {
      } else {
        for (let i = 0; i < this.page_elements.length; i++) {
          let pageelement = this.page_elements[i];
          if (pageelement != selectedelement)
            pageelements.push(pageelement);
        }
      }
      for (let i = 0; i < pageelements.length; i++) {
        let pageelement = pageelements[i];
        if (handle.type == "t_l") {
          let x = selectedelement.screen_saved_rect.position.x + offsetx;
          let x2 = pageelement.screen_rect.position.x - s;
          let x3 = pageelement.screen_rect.position.x + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x;
            let lf = new Point(pageelement.screen_rect.position.x, 0);
            let lt = new Point(pageelement.screen_rect.position.x, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          let y = selectedelement.screen_saved_rect.position.y + offsety;
          let y2 = pageelement.screen_rect.position.y - s;
          let y3 = pageelement.screen_rect.position.y + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y;
            let lf = new Point(0, pageelement.screen_rect.position.y);
            let lt = new Point(2e3, pageelement.screen_rect.position.y);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
        }
        if (handle.type == "l_m") {
          let x = selectedelement.screen_saved_rect.position.x + offsetx;
          let x2 = pageelement.screen_rect.position.x - s;
          let x3 = pageelement.screen_rect.position.x + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x;
            let lf = new Point(pageelement.screen_rect.position.x, 0);
            let lt = new Point(pageelement.screen_rect.position.x, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
        }
        if (handle.type == "t_m") {
          let y = selectedelement.screen_saved_rect.position.y + offsety;
          let y2 = pageelement.screen_rect.position.y - s;
          let y3 = pageelement.screen_rect.position.y + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y;
            let lf = new Point(0, pageelement.screen_rect.position.y);
            let lt = new Point(2e3, pageelement.screen_rect.position.y);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
        }
        if (handle.type == "t_r") {
          let x = selectedelement.screen_saved_rect.position.x + selectedelement.screen_saved_rect.size.width + offsetx;
          let x2 = pageelement.screen_rect.position.x - s;
          let x3 = pageelement.screen_rect.position.x + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x;
            let lf = new Point(pageelement.screen_rect.position.x, 0);
            let lt = new Point(pageelement.screen_rect.position.x, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          let y = selectedelement.screen_saved_rect.position.y + offsety;
          let y2 = pageelement.screen_rect.position.y - s;
          let y3 = pageelement.screen_rect.position.y + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y;
            let lf = new Point(0, pageelement.screen_rect.position.y);
            let lt = new Point(2e3, pageelement.screen_rect.position.y);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
        }
        if (handle.type == "r_m") {
          let x = selectedelement.screen_saved_rect.position.x + selectedelement.screen_saved_rect.size.width + offsetx;
          let x2 = pageelement.screen_rect.position.x - s;
          let x3 = pageelement.screen_rect.position.x + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x;
            let lf = new Point(pageelement.screen_rect.position.x, 0);
            let lt = new Point(pageelement.screen_rect.position.x, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
        }
        if (handle.type == "l_b") {
          let x = selectedelement.screen_saved_rect.position.x + offsetx;
          let x2 = pageelement.screen_rect.position.x - s;
          let x3 = pageelement.screen_rect.position.x + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x;
            let lf = new Point(pageelement.screen_rect.position.x, 0);
            let lt = new Point(pageelement.screen_rect.position.x, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          let y = selectedelement.screen_saved_rect.position.y + selectedelement.screen_saved_rect.size.height + offsety;
          let y2 = pageelement.screen_rect.position.y - s;
          let y3 = pageelement.screen_rect.position.y + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y;
            let lf = new Point(0, pageelement.screen_rect.position.y);
            let lt = new Point(2e3, pageelement.screen_rect.position.y);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
        }
        if (handle.type == "m_b") {
          let y = selectedelement.screen_saved_rect.position.y + selectedelement.screen_saved_rect.size.height + offsety;
          let y2 = pageelement.screen_rect.position.y - s;
          let y3 = pageelement.screen_rect.position.y + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y;
            let lf = new Point(0, pageelement.screen_rect.position.y);
            let lt = new Point(2e3, pageelement.screen_rect.position.y);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
        }
        if (handle.type == "r_b") {
          let x = selectedelement.screen_saved_rect.position.x + selectedelement.screen_saved_rect.size.width + offsetx;
          let x2 = pageelement.screen_rect.position.x - s;
          let x3 = pageelement.screen_rect.position.x + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x;
            let lf = new Point(pageelement.screen_rect.position.x, 0);
            let lt = new Point(pageelement.screen_rect.position.x, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2 - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width / 2, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          x2 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - s;
          x3 = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width + s;
          if (x >= x2 && x <= x3) {
            let distance = Math.abs(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width - x);
            gSticky.sticky_x = true;
            gSticky.x = pageelement.screen_rect.position.x + pageelement.screen_rect.size.width;
            let lf = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 0);
            let lt = new Point(pageelement.screen_rect.position.x + pageelement.screen_rect.size.width, 2e3);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_x.push({ type: "x", value: gSticky.x, distance, line: line3 });
          }
          let y = selectedelement.screen_saved_rect.position.y + selectedelement.screen_saved_rect.size.height + offsety;
          let y2 = pageelement.screen_rect.position.y - s;
          let y3 = pageelement.screen_rect.position.y + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y;
            let lf = new Point(0, pageelement.screen_rect.position.y);
            let lt = new Point(2e3, pageelement.screen_rect.position.y);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2 - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height / 2);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
          y2 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - s;
          y3 = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height + s;
          if (y >= y2 && y <= y3) {
            let distance = Math.abs(pageelement.screen_rect.position.y + pageelement.screen_rect.size.height - y);
            gSticky.sticky_y = true;
            gSticky.y = pageelement.screen_rect.position.y + pageelement.screen_rect.size.height;
            let lf = new Point(0, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let lt = new Point(2e3, pageelement.screen_rect.position.y + pageelement.screen_rect.size.height);
            let line3 = new Line(lf, lt, "#51c1fd");
            temp_y.push({ type: "y", value: gSticky.y, distance, line: line3 });
          }
        }
      }
      if (temp_x.length > 0) {
        let t_x = temp_x[0];
        if (temp_x.length > 1) {
          for (let g_x = 1; g_x < temp_x.length; g_x++) {
            let t = temp_x[g_x];
            if (t.distance < t_x.distance) {
              t_x = t;
            }
          }
        }
        gSticky.sticky_x = true;
        gSticky.x = t_x.value;
        this.guides.push(t_x.line);
      } else {
        gSticky.sticky_x = false;
      }
      if (temp_y.length > 0) {
        let t_y = temp_y[0];
        if (temp_y.length > 1) {
          for (let g_y = 1; g_y < temp_y.length; g_y++) {
            let t = temp_y[g_y];
            if (t.distance < t_y.distance) {
              t_y = t;
            }
          }
        }
        gSticky.sticky_y = true;
        gSticky.y = t_y.value;
        this.guides.push(t_y.line);
      } else {
        gSticky.sticky_y = false;
      }
    }
    add_grid(input_grid) {
      this.grid = [];
      for (let i = 0; i < input_grid.length; i++) {
        let l = input_grid[i];
        let x1 = l.f.x;
        let y1 = l.f.y;
        let x2 = l.t.x;
        let y2 = l.t.y;
        let f = new Point(x1, y1);
        let t = new Point(x2, y2);
        let L = new Line(f, t, "#51c1fd");
        this.grid.push(L);
      }
    }
    add_page_element(element3) {
      this.page_elements.push(element3);
    }
    add_page_element_group(pageelements, group = false) {
      let pageelement = new PageElementGroup(this.page_elements.length, "group", pageelements);
      if (group)
        return pageelement;
      else {
        this.page_elements.push(pageelement);
        this.removeTempElements();
        this.removeSelectedElements();
      }
      return pageelement;
    }
    add_page_element_rect(x, y, width, height, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElement(this.page_elements.length, "rect", rect2);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_roundedrect(x, y, width, height, radius, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementRoundedRect(this.page_elements.length, "roundedrect", rect2, radius);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_triangle(x, y, width, height, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementTriangle(this.page_elements.length, "triangle", rect2);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_circel(x, y, width, height, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementCircel(this.page_elements.length, "circel", rect2);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_image(x, y, width, height, url, fit, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementImage(this.page_elements.length, "image", rect2, url, fit);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_richtext(x, y, width, height, fontsize, text, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementRichText(this.page_elements.length, "rich", rect2, fontsize, text);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_text(x, y, width, height, fontsize, text, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementText(this.page_elements.length, "text", rect2, text);
      pageelement.native_font_size = fontsize;
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_table(x, y, width, height, rows, columns, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementTable(this.page_elements.length, "table", rect2, rows, columns);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_line_pod(x, y, width, height, bordersize, group = false) {
      let position = new Point(x, y);
      let size = new Size(width, height);
      let rect2 = new Rect(position, size);
      let pageelement = new PageElementLinePOD(this.page_elements.length, "line_pod", rect2, bordersize);
      this.page_elements.push(pageelement);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_line(x, y, x1, y1, bordersize, group = false) {
      let p1 = new Point(x, y);
      let p2 = new Point(x1, y1);
      let rect2 = new Rect(p1, new Size(x1 - x, y1 - y));
      let pageelement = new PageElementLine(this.page_elements.length, "line", rect2, p1, p2, bordersize);
      this.page_elements.push(pageelement);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_page_element_path(path, group = false) {
      let p = new Path(path);
      let pageelement = new PageElementPath(this.page_elements.length, "path", p);
      this.page_elements.push(pageelement);
      if (group)
        return pageelement;
      else
        this.page_elements.push(pageelement);
      return pageelement;
    }
    add_guide(type, src_value) {
      let guide = new Guide(g_typograph_document.canvas_view, type, src_value);
      guide.selected = true;
      this.ruler_guides.push(guide);
    }
    remove_guide(guide) {
      let index = this.ruler_guides.indexOf(guide);
      this.ruler_guides.splice(index, 1);
      g_typograph_document.redraw();
    }
    handleselected_element(element3, point) {
      let handleselection = new Handleselection(-1, -1, null);
      let found = false;
      let h_found = false;
      if (element3.handles) {
        for (let ii = 0; ii < element3.handles.length; ii++) {
          let handle;
          if (element3.angle != 0) {
            handle = element3.rotated_handles[ii];
          } else {
            handle = element3.handles[ii];
          }
          if (handle.checkbox_circle.pointInside(point) || handle.checkbox.point_in_rect(point)) {
            found = true;
            h_found = true;
            handleselection.handle = handle;
            handle.selected_type = "check_circle";
            handleselection.handle_index = ii;
            handleselection.pageelement_index = this.getElementIndex(element3);
            handle.selected = true;
          } else {
            if (handle.rotatebox_circle.pointInside(point) && !element3.screen_rect.point_in_rect(point)) {
              found = true;
              h_found = true;
              handleselection.handle = handle;
              handle.selected_type = "rotate_circle";
              handleselection.handle_index = ii;
              handleselection.pageelement_index = this.getElementIndex(element3);
              handle.selected = true;
            }
          }
        }
      } else {
        console.log("No handles??");
      }
      return handleselection;
    }
    handleselected(point) {
      let handleselection = new Handleselection(-1, -1, null);
      let found = false;
      for (let i = this.page_elements.length; i > 0 && found == false; i--) {
        let pageelement = this.page_elements[i - 1];
        if (pageelement.selected) {
          let h_found = false;
          if (pageelement.handles) {
            for (let ii = 0; ii < pageelement.handles.length; ii++) {
              let handle;
              if (pageelement.angle != 0) {
                handle = pageelement.rotated_handles[ii];
              } else {
                handle = pageelement.handles[ii];
              }
              if (handle.checkbox_circle.pointInside(point) || handle.checkbox.point_in_rect(point)) {
                found = true;
                h_found = true;
                handleselection.handle = handle;
                handle.selected_type = "check_circle";
                handleselection.handle_index = ii;
                handleselection.pageelement_index = i;
                handle.selected = true;
              } else {
                if (handle.rotatebox_circle.pointInside(point) && !pageelement.screen_rect.point_in_rect(point)) {
                  if (pageelement.type != "line") {
                    found = true;
                    h_found = true;
                    handleselection.handle = handle;
                    handle.selected_type = "rotate_circle";
                    handleselection.handle_index = ii;
                    handleselection.pageelement_index = i;
                    handle.selected = true;
                  }
                }
              }
            }
          }
        }
      }
      return handleselection;
    }
    imagehandleselected(point) {
      let handleselection = new Handleselection(-1, -1, null);
      let found = false;
      for (let i = this.page_elements.length; i > 0 && found == false; i--) {
        let pageelement = this.page_elements[i - 1];
        if (pageelement.type == "image" && pageelement.selected) {
          let p = pageelement;
          let h_found = false;
          if (p.image_handles) {
            if (p.image_handles.length > 0 && p.show_image_handles) {
              for (let ii = 0; ii < p.image_handles.length; ii++) {
                let handle;
                if (pageelement.angle != 0) {
                  handle = p.rotated_image_handles[ii];
                } else {
                  handle = p.image_handles[ii];
                }
                if (handle.rect.point_in_rect(point)) {
                  found = true;
                  h_found = true;
                  handleselection.handle = handle;
                  handleselection.handle_index = ii;
                  handleselection.pageelement_index = i;
                  handle.selected = true;
                  p.resizing_image = true;
                } else {
                  handle.selected = false;
                }
              }
            }
          }
        }
      }
      return handleselection;
    }
    removeTempElements() {
      for (let i = this.page_elements.length; i > 0; i--) {
        let element3 = this.page_elements[i - 1];
        if (element3.type == "temp")
          this.removeElement(element3);
      }
      g_typograph_document.redraw();
    }
    removeElement(element3) {
      let result = false;
      let index = this.getElementIndex(element3);
      if (index > -1) {
        this.page_elements.splice(index, 1);
        result = true;
      }
      return result;
    }
    removeSelectedElements() {
      let message = "element?";
      if (this.selectedElements().length > 0) {
      }
      for (let i = this.page_elements.length; i > 0; i--) {
        let element3 = this.page_elements[i - 1];
        if (element3.selected && !element3.locked)
          this.removeElement(element3);
      }
    }
    getElementByUUID(uuid) {
      for (let i = 0; i < this.page_elements.length; i++) {
        if (uuid == this.page_elements[i].uuid)
          return this.page_elements[i];
      }
      return null;
    }
    get_element_by_name(name) {
      for (let i = 0; i < this.page_elements.length; i++) {
        if (name == this.page_elements[i].name)
          return this.page_elements[i];
      }
      return null;
    }
    get_elements_by_type(type) {
      let page_elements = [];
      for (let i = 0; i < this.page_elements.length; i++) {
        if (this.page_elements[i].type == type)
          page_elements.push(this.page_elements[i]);
      }
      return page_elements;
    }
    getElementIndex(element3) {
      return this.page_elements.indexOf(element3);
    }
    renumberAllElements() {
      for (let i = 0; i < this.page_elements.length; i++) {
        this.page_elements[i].id = i;
      }
    }
    deselectAllElements() {
      for (let i = 0; i < this.page_elements.length; i++)
        this.page_elements[i].selected = false;
      g_typograph_document.redraw();
      return;
    }
    selectAllElements(in_types) {
      if (in_types.length == 0) {
        for (let i = 0; i < this.page_elements.length; i++) {
          if (this.page_elements[i].hidden)
            continue;
          this.page_elements[i].selected = true;
        }
        g_typograph_document.create_temp_selection();
        return;
      }
      let types = in_types.split(",");
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        if (el.locked || el.hidden)
          continue;
        if (types.indexOf(el.type) != -1)
          el.selected = true;
        else
          el.selected = false;
      }
      g_typograph_document.create_temp_selection();
    }
    selectedElement() {
      for (let i = this.page_elements.length; i > 0; i--) {
        let element3 = this.page_elements[i - 1];
        if (element3.selected) {
          if (element3.type == "group") {
            let group = element3;
            group.select_elements();
          }
          return element3;
        }
      }
      return null;
    }
    selectedElements() {
      let elements = [];
      for (let i = 0; i < this.page_elements.length; i++) {
        let element3 = this.page_elements[i];
        if (element3.selected && !element3.locked) {
          if (element3.type == "group") {
          }
          elements.push(element3);
        }
      }
      return elements;
    }
    addGroupElements(group, elements) {
      for (let i = 0; i < group.page_elements.length; i++) {
        let el = group.page_elements[i];
        el.selected = true;
        if (el.type == "group") {
          elements.push(el);
          elements = this.addGroupElements(el, elements);
        } else {
          elements.push(el);
        }
      }
      return elements;
    }
    elementselected(point, shift = false, offsetpoint = null) {
      let found = false;
      let view = g_typograph_document.canvas_view;
      for (let i = this.page_elements.length; i > 0 && found == false; i--) {
        let pageelement = this.page_elements[i - 1];
        if (pageelement.hidden)
          continue;
        if (pageelement.type == "line") {
          let lineelement = pageelement;
          if (lineelement.line_screen.pointOn(point, lineelement.border_size * view.zoom)) {
            found = true;
            if (shift) {
              if (lineelement.selected)
                lineelement.selected = false;
              else
                lineelement.selected = true;
            } else {
              lineelement.selected = true;
            }
            for (let ii = 0; ii < pageelement.handles.length; ii++) {
              let handle = pageelement.handles[ii];
              handle.selected = false;
            }
          }
        }
        if (pageelement.type == "roundedrect") {
          if (pageelement.is_point_in_path(view, offsetpoint)) {
            found = true;
            if (shift) {
              if (pageelement.selected)
                pageelement.selected = false;
              else
                pageelement.selected = true;
            } else {
              pageelement.selected = true;
            }
            if (pageelement.handles == null) {
              console.log("No handles!!");
              return found;
            }
            for (let ii = 0; ii < pageelement.handles.length; ii++) {
              let handle = pageelement.handles[ii];
              handle.selected = false;
            }
          }
        }
        if (pageelement.type != "line" && pageelement.type != "roundedrect") {
          if (pageelement.screen_rect.point_in_rect(point)) {
            found = true;
            if (shift) {
              if (pageelement.selected)
                pageelement.selected = false;
              else
                pageelement.selected = true;
            } else {
              pageelement.selected = true;
            }
            if (pageelement.type == "group") {
              let group = pageelement;
              group.select_elements();
              g_typograph_document.create_temp_selection();
            }
            if (pageelement.type == "temp") {
              let temp = pageelement;
              temp.select_elements();
            }
            if (pageelement.handles == null) {
              console.log("No handles!!");
              return found;
            }
            for (let ii = 0; ii < pageelement.handles.length; ii++) {
              let handle = pageelement.handles[ii];
              handle.selected = false;
            }
          }
        }
      }
      return found;
    }
    bringtoFront(element3) {
      if (!this.isFrontElement(element3)) {
        let index = this.page_elements.indexOf(element3);
        let a = this.page_elements.slice(0, index);
        let b = this.page_elements.slice(index + 1);
        let c = a.concat(b);
        let d = this.page_elements.slice(index, index + 1);
        this.page_elements = c.concat(d);
      }
    }
    sendtoBack(element3) {
      if (!this.isBackElement(element3)) {
        let index = this.page_elements.indexOf(element3);
        let a = this.page_elements.slice(0, index);
        let b = this.page_elements.slice(index + 1, this.page_elements.length);
        let c = a.concat(b);
        let d = this.page_elements.slice(index, index + 1);
        this.page_elements = d.concat(c);
      }
    }
    bringforward(element3) {
      if (!this.isFrontElement(element3)) {
        let index = this.page_elements.indexOf(element3);
        if (index == this.page_elements.length - 1) {
          this.bringtoFront(element3);
        } else {
          let a = this.page_elements.slice(0, index);
          let b = this.page_elements.slice(index + 1, index + 2);
          let c = this.page_elements.slice(index + 2, this.page_elements.length);
          let d = this.page_elements.slice(index, index + 1);
          let r = a.concat(b);
          r = r.concat(d);
          this.page_elements = r.concat(c);
        }
      }
    }
    sendbackward(element3) {
      if (!this.isBackElement(element3)) {
        let index = this.page_elements.indexOf(element3);
        if (index < 2) {
          this.sendtoBack(element3);
        } else {
          let a = this.page_elements.slice(0, index - 1);
          let b = this.page_elements.slice(index - 1, index);
          let c = this.page_elements.slice(index + 1, this.page_elements.length);
          let d = this.page_elements.slice(index, index + 1);
          let r = a.concat(d);
          r = r.concat(b);
          this.page_elements = r.concat(c);
        }
      }
    }
    isFrontElement(element3) {
      let index = this.page_elements.indexOf(element3);
      if (index == this.page_elements.length - 1)
        return true;
      return false;
    }
    isBackElement(element3) {
      let index = this.page_elements.indexOf(element3);
      if (index == 0)
        return true;
      return false;
    }
    changeselectedelements(type) {
      for (let i = this.page_elements.length; i > 0; i--) {
        let el = this.page_elements[i - 1];
        if (!el.locked) {
          if (el.selected) {
            if (el.type != type) {
              let index = i;
              let new_element = this.changeelementtype(el, type);
              this.removeElement(el);
            }
          }
        }
      }
    }
    changeelementtype(element3, type) {
      let r_element;
      if (type == "rect") {
        let new_element = this.add_page_element_rect(element3.native_rect.position.x, element3.native_rect.position.y, element3.native_rect.size.width, element3.native_rect.size.height);
        new_element.set_fill_style("COLOR_1");
        new_element.native_border_size = 0.5;
        new_element.set_stroke_style("#5C585B");
        r_element = new_element;
      }
      if (type == "roundedrect") {
        let new_element = this.add_page_element_roundedrect(element3.native_rect.position.x, element3.native_rect.position.y, element3.native_rect.size.width, element3.native_rect.size.height, 0);
        new_element.set_fill_style("COLOR_1");
        new_element.native_border_size = 0.5;
        new_element.set_stroke_style("#5C585B");
        r_element = new_element;
      }
      return r_element;
    }
    move_element_toindex(element3, index) {
      let current_index = this.page_elements.indexOf(element3);
      if (current_index == index)
        return;
    }
    getScreenbounds(elements) {
      if (elements.length > 0) {
        let element3 = elements[0];
        let min_x = element3.screen_rect.position.x;
        let min_y = element3.screen_rect.position.y;
        let max_x = min_x + element3.screen_rect.size.width;
        let max_y = min_y + element3.screen_rect.size.height;
        for (let i = 1; i < elements.length; i++) {
          let el = elements[i];
          let x = el.screen_rect.position.x;
          let y = el.screen_rect.position.y;
          let x1 = x + el.screen_rect.size.width;
          let y1 = y + el.screen_rect.size.height;
          if (x < min_x)
            min_x = x;
          if (y < min_y)
            min_y = y;
          if (x1 > max_x)
            max_x = x1;
          if (y1 > max_y)
            max_y = y1;
        }
        let w = max_x - min_x;
        let h = max_y - min_y;
        let rect2 = new Rect(new Point(min_x, min_y), new Size(w, h));
        return rect2;
      }
      return null;
    }
    draw(view) {
      if (!view.hidpi) {
        let keep = view.canvas_element.width;
        view.canvas_element.width++;
        view.canvas_element.width = keep;
      }
      this.setScreenSize(view);
      view.context.save();
      if (!view.show_guids) {
        view.offset_x = (view.canvas_element.width / 2 - this.screen_size.width) / 2;
        view.offset_y = (view.canvas_element.height / 2 - this.screen_size.height) / 2;
      } else {
      }
      view.offset_x = Math.floor(view.offset_x);
      view.offset_y = Math.floor(view.offset_y);
      view.context.shadowColor = "#666";
      if (this.screen_size.width < 100) {
        if (view.hidpi) {
          view.context.shadowBlur = 12;
        } else {
          view.context.shadowBlur = 6;
        }
      } else {
        view.context.shadowBlur = 24;
      }
      view.context.shadowOffsetX = 0;
      view.context.shadowOffsetY = 0;
      let screenbleed = this.bleed * view.zoom * gMMtoScreen;
      if (!view.show_bleed)
        screenbleed = 0;
      if (this.gradient != null) {
        let grd;
        if (this.gradient.type == "linear") {
          grd = view.context.createLinearGradient(view.offset_x - screenbleed, 0, view.offset_x - screenbleed + this.screen_size.width + 2 * screenbleed, 0);
          for (let s = 0; s < this.gradient.samples.length; s++) {
            let sample = this.gradient.samples[s];
            let offset = sample.pos / 100;
            let rgb = HEXtoRGB(sample.color.rgba);
            if (rgb == null)
              rgb = [0, 0, 0];
            let color2 = "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + "," + sample.opacity + ")";
            grd.addColorStop(offset, color2);
          }
        } else {
          let cx = view.offset_x - screenbleed + (this.screen_size.width + 2 * screenbleed) / 2;
          let cy = view.offset_y - screenbleed + (this.screen_size.height + 2 * screenbleed) / 2;
          grd = view.context.createRadialGradient(cx, cy, 0, cx, cy, this.screen_size.width + 2 * screenbleed);
          for (let s = 0; s < this.gradient.samples.length; s++) {
            let sample = this.gradient.samples[s];
            let offset = sample.pos / 100;
            let rgb = HEXtoRGB(sample.color.rgba);
            if (rgb == null)
              rgb = [0, 0, 0];
            let color2 = "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + "," + sample.opacity + ")";
            grd.addColorStop(offset, color2);
          }
        }
        view.context.fillStyle = grd;
      } else {
        view.context.fillStyle = this.get_fill_style();
      }
      {
        view.context.fillRect(view.offset_x - screenbleed, view.offset_y - screenbleed, this.screen_size.width + 2 * screenbleed, this.screen_size.height + 2 * screenbleed);
        view.context.shadowBlur = 0;
        view.context.shadowColor = "tranparent";
        view.context.strokeStyle = "#000";
        view.context.strokeRect(view.offset_x - screenbleed, view.offset_y - screenbleed, this.screen_size.width + 2 * screenbleed, this.screen_size.height + 2 * screenbleed);
      }
      view.context.shadowBlur = 0;
      view.context.shadowColor = "tranparent";
      view.context.beginPath();
      {
        view.context.rect(view.offset_x - screenbleed, view.offset_y - screenbleed, this.screen_size.width + 2 * screenbleed, this.screen_size.height + 2 * screenbleed);
      }
      view.context.clip();
      for (let i = 0; i < this.page_elements.length; i++) {
        let pageelement = this.page_elements[i];
        if (pageelement.hidden)
          continue;
        pageelement.set_screen_rect(view);
        pageelement.draw(view);
      }
      if (this.show_temp) {
        for (let i = 0; i < this.temp_page_elements.length; i++) {
          let pageelement = this.temp_page_elements[i];
          if (pageelement.hidden)
            continue;
          pageelement.set_screen_rect(view);
          pageelement.draw(view);
        }
      }
      if (this.bleed && view.show_bleed) {
        view.context.strokeStyle = "#f00";
        view.context.lineWidth = 0.5;
        view.context.strokeRect(view.offset_x, view.offset_y, this.screen_size.width, this.screen_size.height);
      }
      if (view.show_guids) {
        for (let i = 0; i < this.ruler_guides.length; i++) {
          let g = this.ruler_guides[i];
          g.draw(view);
        }
      }
      view.context.restore();
      view.context.save();
      {
        let selected_elements = this.selectedElements();
        if (selected_elements.length > 0) {
          if (selected_elements.length == 1) {
            for (let i = 0; i < this.page_elements.length; i++) {
              let pageelement = this.page_elements[i];
              if (pageelement.hidden)
                continue;
              if (pageelement.selected) {
                let focus = false;
                if (pageelement.type == "rich") {
                  let el = pageelement;
                  if (el.rich_text.hasFocus())
                    focus = true;
                }
                pageelement.draw_selection(view, focus);
              } else {
              }
            }
          } else {
            for (let i = 0; i < this.page_elements.length; i++) {
              let pageelement = this.page_elements[i];
              if (pageelement.type == "temp" || pageelement.type == "group") {
                if (pageelement.selected)
                  pageelement.draw_selection(view);
              } else {
                if (g_typograph_document.get_show_element_frames())
                  pageelement.draw_frame(view);
              }
            }
          }
        } else {
          if (view.show_guids) {
            if (g_typograph_document.get_show_element_frames()) {
              for (let i = 0; i < this.page_elements.length; i++) {
                this.page_elements[i].draw_frame(view);
              }
            }
          }
        }
      }
      view.context.restore();
      if (view.show_guids) {
        view.context.save();
        {
          view.context.strokeStyle = "#51c1fd";
          for (let i = 0; i < this.guides.length; i++) {
            let l = this.guides[i];
            let lf = new Point(l.f.x, l.f.y);
            let lt = new Point(l.t.x, l.t.y);
            let line3 = new Line(lf, lt, view.context.strokeStyle);
            if (line3.t.x == 2e3) {
              line3.f.y += view.offset_y;
              line3.t.y += view.offset_y;
            }
            if (line3.t.y == 2e3) {
              line3.f.x += view.offset_x;
              line3.t.x += view.offset_x;
            }
            line3.draw(view);
          }
          if (g_typograph_document.get_drag_selection().active) {
            view.context.save();
            view.context.strokeStyle = "#51c1fd";
            view.context.fillStyle = "#dae8f2";
            let s_x = g_typograph_document.get_drag_selection().getX() + view.offset_x;
            let s_y = g_typograph_document.get_drag_selection().getY() + view.offset_y;
            let s_width = g_typograph_document.get_drag_selection().getW();
            let s_height = g_typograph_document.get_drag_selection().getH();
            view.context.globalCompositeOperation = "multiply";
            view.context.fillRect(s_x, s_y, s_width, s_height);
            view.context.globalCompositeOperation = "source-over";
            view.context.lineWidth = 1;
            view.context.strokeRect(s_x, s_y, s_width, s_height);
            view.context.restore();
          }
        }
        view.context.restore();
      }
    }
  };
  var Sticky = class {
    constructor(sticky_x, sticky_y) {
      this.sticky_x = sticky_x;
      this.sticky_y = sticky_y;
      this.stick_size = 4;
      this.x = -1;
      this.y = -1;
    }
  };
  var Point = class _Point {
    constructor(x, y) {
      this.x = x;
      this.y = y;
    }
    copy() {
      return new _Point(this.x, this.y);
    }
    equalto(to) {
      if (this.x === to.x && this.y === to.y)
        return true;
      return false;
    }
  };
  var Size = class {
    constructor(width, height) {
      this.width = width;
      this.height = height;
    }
  };
  var Rect = class _Rect {
    constructor(position, size) {
      this.position = position;
      this.size = size;
      this.position = new Point(position.x, position.y);
      this.size = new Size(size.width, size.height);
    }
    copy() {
      return new _Rect(this.position, this.size);
    }
    isequal(toRect) {
      if (this.position.x == toRect.position.x && this.position.y == toRect.position.y && this.size.width == toRect.size.width && this.size.height == toRect.size.height) {
        return true;
      }
      return false;
    }
    point_in_rect(point) {
      if (point.x >= this.position.x && point.x <= this.position.x + this.size.width) {
        if (point.y >= this.position.y && point.y <= this.position.y + this.size.height) {
          return true;
        }
      }
      return false;
    }
    pointOn(point, bordersize = kCheckSize) {
      if (bordersize < kCheckSize)
        bordersize = kCheckSize;
      if (point.x >= this.position.x - bordersize && point.x <= this.position.x + bordersize) {
        if (point.y >= this.position.y - bordersize && point.y <= this.position.x + this.size.height + bordersize)
          return true;
      }
      if (point.y >= this.position.y - bordersize && point.y <= this.position.y + bordersize) {
        if (point.x >= this.position.x - bordersize && point.x <= this.position.x + this.size.width + bordersize)
          return true;
      }
      if (point.x >= this.position.x + this.size.width - bordersize && point.x <= this.position.x + this.size.width + bordersize) {
        if (point.y >= this.position.y - bordersize && point.y <= this.position.x + this.size.height + bordersize)
          return true;
      }
      if (point.y >= this.position.y + this.size.height - bordersize && point.y <= this.position.y + this.size.height + bordersize) {
        if (point.x >= this.position.x - bordersize && point.x <= this.position.x + this.size.width + bordersize)
          return true;
      }
      return false;
    }
    sect_rect(in_rect) {
      let p = new Point(0, 0);
      let s = new Size(0, 0);
      let r = new _Rect(p, s);
      if (in_rect.empty())
        return r;
      if (this.empty())
        return r;
      if (in_rect.position.x + in_rect.size.width < this.position.x)
        return r;
      if (in_rect.position.x > this.position.x + this.size.width)
        return r;
      if (in_rect.position.y + in_rect.size.height < this.position.y)
        return r;
      if (in_rect.position.y > this.position.y + this.size.height)
        return r;
      let x = 0;
      let y = 0;
      let w = 0;
      let h = 0;
      if (in_rect.position.x < this.position.x)
        x = this.position.x;
      else
        x = in_rect.position.x;
      if (in_rect.position.y < this.position.y)
        y = this.position.y;
      else
        y = in_rect.position.y;
      let x1 = in_rect.position.x + in_rect.size.width;
      let x2 = this.position.x + this.size.width;
      if (x1 > x2)
        w = x2 - x;
      else
        w = x1 - x;
      let y1 = in_rect.position.y + in_rect.size.height;
      let y2 = this.position.y + this.size.height;
      if (y1 > y2)
        h = y2 - h;
      else
        h = x1 - h;
      r.position.x = x;
      r.position.y = y;
      r.size.width = w;
      r.size.height = h;
      return r;
    }
    empty() {
      if (this.size.width == 0 || this.size.height == 0)
        return true;
      return false;
    }
    shrink(size) {
      this.position.x += size;
      this.position.y += size;
      this.size.width -= size * 2;
      this.size.height -= size * 2;
    }
  };
  var Line = class _Line {
    constructor(f, t, c = "black") {
      this.f = f;
      this.t = t;
      this.c = c;
    }
    copy() {
      return new _Line(this.f.copy(), this.t.copy(), this.c);
    }
    draw(view) {
      view.context.beginPath();
      view.context.moveTo(this.f.x, this.f.y);
      view.context.lineTo(this.t.x, this.t.y);
      view.context.lineWidth = 1;
      view.context.strokeStyle = this.c;
      view.context.stroke();
    }
    pointOn(p, bodersize = kCheckSize) {
      if (bodersize < kCheckSize)
        bodersize = kCheckSize;
      let r = calcIsInsideThickLineSegment(this.f, this.t, p, bodersize);
      return r;
    }
  };
  var Circle = class _Circle {
    constructor(centerpoint, radius) {
      this.centerpoint = centerpoint;
      this.radius = radius;
      this.centerpoint = new Point(centerpoint.x, centerpoint.y);
    }
    copy() {
      return new _Circle(this.centerpoint, this.radius);
    }
    pointOn(p) {
      let a = p.x - this.centerpoint.x;
      let b = p.y - this.centerpoint.y;
      let r = this.radius;
      a *= a;
      b * -b;
      return a + b > (r - kCheckSize) * (r - kCheckSize) && a + b < (r + kCheckSize) * (r + kCheckSize);
    }
    pointInside(p) {
      let a = p.x - this.centerpoint.x;
      let b = p.y - this.centerpoint.y;
      return a * a + b * b < this.radius * this.radius;
    }
    draw(view) {
      let x = this.centerpoint.x + view.offset_x;
      let y = this.centerpoint.y + view.offset_y;
      view.context.save();
      view.context.beginPath();
      view.context.arc(x, y, this.radius, 0, 2 * Math.PI);
      view.context.fill();
      view.context.stroke();
      view.context.restore();
    }
  };
  var Guide = class _Guide {
    constructor(view, type, screen_value, native_value = -999, visible = true) {
      this.view = view;
      this.type = type;
      this.screen_value = screen_value;
      this.native_value = native_value;
      this.visible = visible;
      this.selected = false;
      if (native_value == -999) {
        this.screen2native_value(this.screen_value, view);
      } else {
        this.native2screen_value(this.native_value, view);
      }
    }
    copy() {
      let guide = new _Guide(this.view, this.type, this.screen_value);
      guide.selected = this.selected;
      guide.visible = this.visible;
      return guide;
    }
    screen2native_value(scr_value, view) {
      this.screen_value = scr_value;
      let factor = this.view.zoom * gMMtoScreen;
      if (this.type == "Hor") {
        this.native_value = (this.screen_value - view.offset_y) / factor;
      }
      if (this.type == "Ver") {
        this.native_value = (this.screen_value - view.offset_x) / factor;
      }
    }
    native2screen_value(nat_value, view) {
      this.native_value = nat_value;
      let factor = this.view.zoom * gMMtoScreen;
      if (this.type == "Hor") {
        this.screen_value = this.native_value * factor + view.offset_y;
      }
      if (this.type == "Ver") {
        this.screen_value = this.native_value * factor + view.offset_x;
      }
    }
    draw(view) {
      if (view.show_guids) {
        let drawit = this.visible;
        if (this.selected) {
          drawit = true;
        }
        if (drawit) {
          let color2 = this.selected ? "#05ab05" : "#51c1fd";
          this.native2screen_value(this.native_value, view);
          let rect2 = view.canvas_element.getBoundingClientRect();
          if (this.type == "Hor") {
            {
              view.context.save();
              view.context.beginPath();
              view.context.strokeStyle = color2;
              view.context.lineWidth = 1;
              view.context.moveTo(0, this.screen_value);
              view.context.lineTo(rect2.width, this.screen_value);
              view.context.stroke();
              view.context.restore();
            }
          }
          if (this.type == "Ver") {
            {
              view.context.save();
              view.context.beginPath();
              view.context.strokeStyle = color2;
              view.context.lineWidth = 1;
              view.context.moveTo(this.screen_value, 0);
              view.context.lineTo(this.screen_value, rect2.height);
              view.context.stroke();
              view.context.restore();
            }
          }
        }
      }
    }
  };
  var Path = class _Path {
    constructor(commands) {
      this.commands = commands;
      this.color = "black";
      this.size = 1;
      this.saved_path = null;
    }
    copy() {
      let path = new _Path(this.commands);
      path.commands = [];
      for (let i = 0; i < this.commands.length; i++) {
        let command2 = { code: this.commands[i].code, points: [] };
        for (let p = 0; p < this.commands[i].points.length; p++) {
          let point = new Point(this.commands[i].points[p].x, this.commands[i].points[p].y);
          command2.points.push(point);
        }
        path.commands.push(command2);
      }
      return path;
    }
    setsavedpath() {
      this.saved_path = this.copy();
    }
    addcommand(com) {
      let in_points = com.points;
      let out_points = [];
      for (let i = 0; i < in_points.length; i++)
        out_points.push(this.to_native(in_points[i]));
      com.points = out_points;
      this.commands.push(com);
    }
    getnativerect() {
      let min_x = 2e4;
      let max_x = -2e3;
      let min_y = 2e4;
      let max_y = -2e3;
      for (let c = 0; c < this.commands.length; c++) {
        let p2 = this.commands[c].points[0];
        if (p2.x < min_x)
          min_x = p2.x;
        if (p2.x > max_x)
          max_x = p2.x;
        if (p2.y < min_y)
          min_y = p2.y;
        if (p2.y > max_y)
          max_y = p2.y;
      }
      let p = new Point(min_x, min_y);
      let s = new Size(max_x - min_x, max_y - min_y);
      let r = new Rect(p, s);
      return r;
    }
    scale(s_x, s_y) {
      for (let c = 0; c < this.commands.length; c++) {
        let com = this.commands[c];
        let points = com.points;
        for (let i = 0; i < points.length; i++) {
          let p = points[i];
          this.commands[c].points[i].x = p.x * s_x;
          this.commands[c].points[i].y = p.y * s_y;
        }
      }
    }
    move_to(offset_x, offset_y) {
      let offset = new Point(offset_x, offset_y);
      offset = this.to_native_offset(offset);
      if (offset.x != 0 || offset.y != 0) {
        for (let c = 0; c < this.commands.length; c++) {
          let com = this.commands[c];
          let points = com.points;
          for (let i = 0; i < points.length; i++) {
            let p = points[i];
            p.x += offset.x;
            p.y += offset.y;
          }
        }
      }
    }
    to_native(p) {
      let view = g_typograph_document.get_view();
      let factor = view.zoom * gMMtoScreen;
      let pr = new Point(
        (p.x - view.offset_x) / factor,
        (p.y - view.offset_y) / factor
      );
      return pr;
    }
    to_native_offset(p) {
      let view = g_typograph_document.get_view();
      let factor = view.zoom * gMMtoScreen;
      let pr = new Point(
        p.x / factor,
        p.y / factor
      );
      return pr;
    }
    to_screen(view, p, use_offset = true) {
      let factor = view.zoom * gMMtoScreen;
      let offsetx = 0;
      let offsety = 0;
      if (use_offset) {
        offsetx = view.offset_x;
        offsety = view.offset_y;
      }
      let pr = new Point(p.x * factor + offsetx, p.y * factor + offsety);
      return pr;
    }
    pointOn(point, view) {
      let lines = [];
      let p1 = null;
      let p2 = null;
      for (let c = 0; c < this.commands.length; c++) {
        let ps = this.commands[c].points;
        if (this.commands[c].code == "M") {
          p1 = this.to_screen(view, ps[0], false);
        }
        if (this.commands[c].code == "L") {
          p2 = this.to_screen(view, ps[0], false);
          let line3 = new Line(p1, p2);
          lines.push(line3);
          p1 = p2.copy();
        }
        if (this.commands[c].code == "Z") {
          p2 = this.to_screen(view, ps[0], false);
          let line3 = new Line(p1, p2);
          lines.push(line3);
          p1 = p2.copy();
        }
      }
      for (let l = 0; l < lines.length; l++) {
        let line3 = lines[l];
        let result = line3.pointOn(point);
        if (result)
          return true;
      }
      return false;
    }
    draw(view) {
      if (this.commands.length > 1) {
        view.context.lineWidth = this.size;
        view.context.strokeStyle = this.color;
        this.buildpath(view);
        view.context.stroke();
      }
    }
    buildpath(view) {
      view.context.beginPath();
      for (let c = 0; c < this.commands.length; c++) {
        let ps = this.commands[c].points;
        if (this.commands[c].code == "M") {
          let p = this.to_screen(view, ps[0]);
          view.context.moveTo(p.x, p.y);
        }
        if (this.commands[c].code == "L") {
          let p = this.to_screen(view, ps[0]);
          view.context.lineTo(p.x, p.y);
        }
        if (this.commands[c].code == "Z") {
          let p = this.to_screen(view, ps[0]);
          view.context.lineTo(p.x, p.y);
          view.context.closePath();
        }
      }
    }
  };
  var Handleselectionbox = class {
    //rotatebox bigger as the checking box and only on tl, tr, lb and br
    constructor(rect2, selected, type, frame, draw = true, active = true, selected_type = "") {
      this.rect = rect2;
      this.selected = selected;
      this.type = type;
      this.frame = frame;
      this.draw = draw;
      this.active = active;
      this.selected_type = selected_type;
      let center = new Point(this.rect.position.x + this.rect.size.width / 2, this.rect.position.y + this.rect.size.height / 2);
      this.checkbox_circle = new Circle(center, kCheckSize);
      this.rotatebox_circle = new Circle(center, 0);
      this.checkbox = new Rect(new Point(center.x - kCheckSize, center.y - kCheckSize), new Size(kCheckSize + kCheckSize, kCheckSize + kCheckSize));
      if (this.type == "t_l" || this.type == "t_r" || this.type == "l_b" || this.type == "r_b") {
        this.rotatebox_circle.radius = kCheckSizeRotation;
      }
      if (this.type == "t_m" || this.type == "m_b" || this.type == "l_m" || this.type == "r_m") {
        if (this.type == "t_m") {
          this.checkbox.position.x = frame.position.x + kCheckSize;
          this.checkbox.position.y = frame.position.y - kCheckSize;
          this.checkbox.size.width = frame.size.width - kCheckSize + kCheckSize;
          this.checkbox.size.height = kCheckSize + kCheckSize;
        }
        if (this.type == "m_b") {
          this.checkbox.position.x = frame.position.x + kCheckSize;
          this.checkbox.position.y = frame.position.y + frame.size.height - kCheckSize;
          this.checkbox.size.width = frame.size.width - kCheckSize + kCheckSize;
          this.checkbox.size.height = kCheckSize + kCheckSize;
        }
        if (this.type == "l_m") {
          this.checkbox.position.x = frame.position.x - kCheckSize;
          this.checkbox.position.y = frame.position.y + kCheckSize;
          this.checkbox.size.width = kCheckSize + kCheckSize;
          this.checkbox.size.height = frame.size.height - (kCheckSize + kCheckSize);
        }
        if (this.type == "r_m") {
          this.checkbox.position.x = frame.position.x + frame.size.width - kCheckSize;
          this.checkbox.position.y = frame.position.y + kCheckSize;
          this.checkbox.size.width = kCheckSize + kCheckSize;
          this.checkbox.size.height = frame.size.height - (kCheckSize + kCheckSize);
        }
        this.draw = false;
      }
    }
  };
  var Handleselection = class {
    constructor(pageelement_index, handle_index, handle) {
      this.pageelement_index = pageelement_index;
      this.handle_index = handle_index;
      this.handle = handle;
    }
  };
  var PageElement = class _PageElement {
    //constructor(public id:number, public type:string, public native_rect:Rect, public edit_options:edit_options={resize:true, move:true, rotate:true, imageedit:true})
    constructor(id, type, native_rect) {
      this.id = id;
      this.type = type;
      this.native_rect = native_rect;
      this.uuid = g_typograph_document.generateUUID();
      this.name = this.uuid;
      this.selected = false;
      this.fill_style = "COLOR_3";
      this.stroke_style = "COLOR_2";
      this.screen_rect = new Rect(this.native_rect.position, this.native_rect.size);
      this.screen_saved_rect = new Rect(this.native_rect.position, this.native_rect.size);
      this.opacity = 1;
      this.blend_mode = "source-over";
      this.native_border_size = 1;
      this.border_size = 1;
      this.angle = 0;
      this.shadow = false;
      this.shadow_color = "COLOR_2";
      this.shadow_angle = 0;
      this.native_shadow_length = 1;
      this.native_shadow_offset_x = 1;
      this.native_shadow_offset_y = 0;
      this.native_shadow_blur = 2;
      this.rotation_center_set = false;
      this.rotation_cx = 0;
      this.rotation_cy = 0;
      this.gradient = null;
      this.locked = false;
      this.hidden = false;
      this.stroke_dash = { name: "solid", pattern: [] };
      this.auto_layout = null;
      this.user_data = null;
    }
    copy() {
      let element3 = new _PageElement(this.id, this.type, this.native_rect);
      element3.native_rect = new Rect(this.native_rect.position, this.native_rect.size);
      this.copy_properties_to(element3);
      return element3;
    }
    copy_properties_to(e) {
      e.name = this.name;
      e.selected = this.selected;
      e.fill_style = this.fill_style;
      e.stroke_style = this.stroke_style;
      e.native_rect = this.native_rect.copy();
      e.screen_rect = this.screen_rect.copy();
      e.screen_saved_rect = this.screen_saved_rect.copy();
      e.opacity = this.opacity;
      e.blend_mode = this.blend_mode;
      e.native_border_size = this.native_border_size;
      e.border_size = this.border_size;
      e.angle = this.angle;
      e.shadow = this.shadow;
      e.shadow_color = this.shadow_color;
      e.shadow_angle = this.shadow_angle;
      e.native_shadow_length = this.native_shadow_length;
      e.native_shadow_offset_x = this.native_shadow_offset_x;
      e.native_shadow_offset_y = this.native_shadow_offset_y;
      e.native_shadow_blur = this.native_shadow_blur;
      e.rotation_center_set = this.rotation_center_set;
      e.rotation_cy = 0;
      e.rotation_cy = 0;
      e.native_rotation_cx = this.native_rotation_cx;
      e.native_rotation_cy = this.native_rotation_cy;
      if (this.gradient) {
        e.gradient = this.gradient.copy();
      }
      e.locked = this.locked;
      if (e.hidden != void 0)
        e.hidden = this.hidden;
      if (e.stroke_dash != void 0)
        e.stroke_dash = this.stroke_dash;
      if (e.auto_layout != void 0)
        e.auto_layout = this.auto_layout;
      if (e.user_data != void 0)
        e.user_data = this.user_data;
    }
    get_name() {
      return this.name;
    }
    set_name(name) {
      this.name = name;
    }
    get_Gradient() {
      return this.gradient;
    }
    set_gradient(gr) {
      this.gradient = gr;
    }
    get_fill_style() {
      if (this.fill_style.indexOf("COLOR_") != -1) {
        let rgba = "#0f0";
        let index = g_typograph_document.get_brand_colors().get_index(this.fill_style);
        if (index != -1)
          rgba = g_typograph_document.get_brand_colors().get_rgba(index);
        return rgba;
      } else
        return this.fill_style;
    }
    get_fill_style_name() {
      if (this.fill_style.indexOf("COLOR_") != -1) {
        return this.fill_style;
      } else {
        return "none";
      }
    }
    get_fill_style_native() {
      return this.fill_style;
    }
    get_stroke_style() {
      if (this.stroke_style.indexOf("COLOR_") != -1) {
        let rgba = "#0f0";
        let index = g_typograph_document.get_brand_colors().get_index(this.stroke_style);
        if (index != -1)
          rgba = g_typograph_document.get_brand_colors().get_rgba(index);
        return rgba;
      } else
        return this.stroke_style;
    }
    get_stroke_style_name() {
      if (this.stroke_style.indexOf("COLOR_") != -1) {
        return this.stroke_style;
      } else {
        return "none";
      }
    }
    get_stroke_style_native() {
      return this.stroke_style;
    }
    get_shadow_color() {
      if (this.shadow_color.indexOf("COLOR_") != -1) {
        let rgba = "#0f0";
        let index = g_typograph_document.get_brand_colors().get_index(this.shadow_color);
        if (index != -1)
          rgba = g_typograph_document.get_brand_colors().get_rgba(index);
        return rgba;
      } else
        return this.shadow_color;
    }
    get_shadow_color_name() {
      if (this.shadow_color.indexOf("COLOR_") != -1) {
        return this.shadow_color;
      } else {
        return "none";
      }
    }
    /**
     *
     * Get the blending mode of a page_element
     *
     * @returns blending mode of the page_element as a string
     *
     * @default  "source-over"
     * @description
     * blending mode is a string legitament modes are
     *
     * "color" | "color-burn" | "color-dodge" | "copy" | "darken" | "destination-atop" | "destination-in" | "destination-out" | "destination-over" | "difference" | "exclusion" | "hard-light" | "hue" | "lighten" | "lighter" | "luminosity" | "multiply" | "overlay" | "saturation" | "screen" | "soft-light" | "source-atop" | "source-in" | "source-out" | "source-over" | "xor";
     *
     * For type script defined as
     *
     * type GlobalCompositeOperation = "color" | "color-burn" | "color-dodge" | "copy" | "darken" | "destination-atop" | "destination-in" | "destination-out" | "destination-over" | "difference" | "exclusion" | "hard-light" | "hue" | "lighten" | "lighter" | "luminosity" | "multiply" | "overlay" | "saturation" | "screen" | "soft-light" | "source-atop" | "source-in" | "source-out" | "source-over" | "xor";
     *
     */
    get_blend_mode() {
      return this.blend_mode;
    }
    get_shadow_color_native() {
      return this.shadow_color;
    }
    set_fill_style(s) {
      this.fill_style = s;
    }
    set_stroke_style(s) {
      this.stroke_style = s;
    }
    set_native_stroke_size(n) {
      this.native_border_size = n;
    }
    set_shadow_color(s) {
      this.shadow_color = s;
    }
    /**
    *
    * Set blending mode
    *
    * @param mode string value
    *
    * @default "source-over"
    * @description
    * blending mode is a string legitament modes are
    *
    * "color" | "color-burn" | "color-dodge" | "copy" | "darken" | "destination-atop" | "destination-in" | "destination-out" | "destination-over" | "difference" | "exclusion" | "hard-light" | "hue" | "lighten" | "lighter" | "luminosity" | "multiply" | "overlay" | "saturation" | "screen" | "soft-light" | "source-atop" | "source-in" | "source-out" | "source-over" | "xor";
    *
    * For TypeScript defined as
    *
    * type GlobalCompositeOperation = "color" | "color-burn" | "color-dodge" | "copy" | "darken" | "destination-atop" | "destination-in" | "destination-out" | "destination-over" | "difference" | "exclusion" | "hard-light" | "hue" | "lighten" | "lighter" | "luminosity" | "multiply" | "overlay" | "saturation" | "screen" | "soft-light" | "source-atop" | "source-in" | "source-out" | "source-over" | "xor";
    *
    */
    set_blend_mode(mode) {
      this.blend_mode = mode;
    }
    //interactive move stuff .. set position to saved position;
    update_screen_rect() {
      this.screen_rect.position.x = this.screen_saved_rect.position.x;
      this.screen_rect.position.y = this.screen_saved_rect.position.y;
      this.rotation_cx = this.save_rotation_cx;
    }
    //set screensaved_rect tto current screen_rect...
    update_save_screen_rect() {
      this.screen_saved_rect.position.x = this.screen_rect.position.x;
      this.screen_saved_rect.position.y = this.screen_rect.position.y;
      this.screen_saved_rect.size.width = this.screen_rect.size.width;
      this.screen_saved_rect.size.height = this.screen_rect.size.height;
      this.save_rotation_cx = this.rotation_cx;
      this.save_rotation_cy = this.rotation_cy;
    }
    //set screen_rect_position basesd on screensaved_rect and offset
    update_position(offset_x, offset_y) {
      this.screen_rect.position.x = this.screen_saved_rect.position.x + offset_x;
      this.screen_rect.position.y = this.screen_saved_rect.position.y + offset_y;
    }
    native_point_to_screen_point(view, p, use_offset = true) {
      let factor = view.zoom * gMMtoScreen;
      let point = new Point(p.x * factor, p.y * factor);
      if (use_offset) {
        point.x += view.offset_x;
        point.y += view.offset_y;
      }
      return point;
    }
    set_screen_rect(view) {
      let factor = view.zoom * gMMtoScreen;
      this.screen_rect.position.x = this.native_rect.position.x * factor;
      this.screen_rect.position.y = this.native_rect.position.y * factor;
      this.screen_rect.size.width = this.native_rect.size.width * factor;
      this.screen_rect.size.height = this.native_rect.size.height * factor;
      this.rotation_cx = this.native_rotation_cx * factor;
      this.rotation_cy = this.native_rotation_cy * factor;
      this.update_handles();
      this.update_rotated_handles();
      this.border_size = this.native_border_size * view.zoom;
      this.shadow_blur = this.native_shadow_blur * factor;
      this.shadow_offset_x = this.native_shadow_offset_x * factor;
      this.shadow_offset_y = this.native_shadow_offset_y * factor;
    }
    screen_point_to_native_point(view, sp) {
      let factor = view.zoom * gMMtoScreen;
      let point = new Point(sp.x / factor, sp.y / factor);
      return point;
    }
    set_native_rect() {
      let factor = g_typograph_document.get_view().zoom * gMMtoScreen;
      this.native_rect.position.x = this.screen_rect.position.x / factor;
      this.native_rect.position.y = this.screen_rect.position.y / factor;
      this.native_rect.size.width = this.screen_rect.size.width / factor;
      this.native_rect.size.height = this.screen_rect.size.height / factor;
      this.native_rotation_cx = this.rotation_cx / factor;
      this.native_rotation_cy = this.rotation_cy / factor;
    }
    update_native_rect(r) {
      this.native_rect = r;
      this.set_screen_rect(g_typograph_document.get_view());
    }
    updata_native_pos(pos) {
      this.native_rect.position = pos;
    }
    update_handles() {
      this.handles = [];
      let rect2 = this.screen_rect;
      let position = new Point(this.screen_rect.position.x, this.screen_rect.position.y);
      let size = new Size(kHandleSize, kHandleSize);
      position.x = position.x - kHandleSize / 2;
      position.y = position.y - kHandleSize / 2;
      let handle = new Handleselectionbox(new Rect(position, size), false, "t_l", rect2);
      this.handles.push(handle);
      position.x = rect2.position.x + rect2.size.width / 2 - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "t_m", rect2);
      this.handles.push(handle);
      position.x = rect2.position.x + rect2.size.width / 2 - kHandleSize / 2;
      position.y -= kRotationHandleOffset;
      handle = new Handleselectionbox(new Rect(position, size), false, "rotation", rect2);
      this.handles.push(handle);
      position.y = this.screen_rect.position.y - kHandleSize / 2;
      position.x = rect2.position.x + rect2.size.width - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "t_r", rect2);
      this.handles.push(handle);
      position.x = rect2.position.x - kHandleSize / 2;
      position.y = rect2.position.y + rect2.size.height / 2 - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "l_m", rect2);
      this.handles.push(handle);
      position.x = rect2.position.x + rect2.size.width - kHandleSize / 2;
      position.y = rect2.position.y + rect2.size.height / 2 - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "r_m", rect2);
      this.handles.push(handle);
      position.x = rect2.position.x - kHandleSize / 2;
      position.y = rect2.position.y + rect2.size.height - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "l_b", rect2);
      this.handles.push(handle);
      position.x = rect2.position.x + rect2.size.width / 2 - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "m_b", rect2);
      this.handles.push(handle);
      position.x = rect2.position.x + rect2.size.width - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "r_b", rect2);
      this.handles.push(handle);
    }
    update_rotated_handles() {
      this.rotated_handles = [];
      let cx = this.rotation_cx;
      let cy = this.rotation_cy;
      var angleInRad = this.angle * 2 * Math.PI / 360;
      var handle_types = ["t_l", "t_m", "rotation", "t_r", "l_m", "r_m", "l_b", "m_b", "r_b", "image_center"];
      for (let h = 0; h < this.handles.length; h++) {
        let position = new Point(this.handles[h].rect.position.x, this.handles[h].rect.position.y);
        let size = new Size(this.handles[h].rect.size.width, this.handles[h].rect.size.height);
        let x = position.x + size.width / 2 - cx;
        let y = position.y + size.height / 2 - cy;
        position.x = x * Math.cos(angleInRad) - y * Math.sin(angleInRad);
        position.y = x * Math.sin(angleInRad) + y * Math.cos(angleInRad);
        position.x = position.x - size.width / 2 + cx;
        position.y = position.y - size.height / 2 + cy;
        let handle = new Handleselectionbox(new Rect(position, size), false, handle_types[h], this.screen_rect);
        this.rotated_handles.push(handle);
      }
    }
    resize(offset_x, offset_y, handle, shift) {
      if (offset_x == 0 && offset_y == 0)
        return;
      this.screen_rect.position.x = this.screen_saved_rect.position.x;
      this.screen_rect.position.y = this.screen_saved_rect.position.y;
      this.screen_rect.size.width = this.screen_saved_rect.size.width;
      this.screen_rect.size.height = this.screen_saved_rect.size.height;
      let ration_x = 1;
      let ration_y = 1;
      if (shift) {
        if (handle.type == "t_l" || handle.type == "r_b") {
          if (this.screen_saved_rect.size.width > this.screen_saved_rect.size.height) {
            ration_y = this.screen_saved_rect.size.height / this.screen_saved_rect.size.width;
            offset_y = offset_x * ration_y;
          } else {
            ration_x = this.screen_saved_rect.size.width / this.screen_saved_rect.size.height;
            offset_x = offset_y * ration_x;
          }
        }
        if (handle.type == "l_b" || handle.type == "t_r") {
          if (this.screen_saved_rect.size.width > this.screen_saved_rect.size.height) {
            ration_y = this.screen_saved_rect.size.height / this.screen_saved_rect.size.width;
            offset_y = -1 * (offset_x * ration_y);
          } else {
            ration_x = this.screen_saved_rect.size.width / this.screen_saved_rect.size.height;
            offset_x = -1 * (offset_y * ration_x);
          }
        }
      }
      if (handle.type == "t_l") {
        this.screen_rect.position.x += offset_x;
        this.screen_rect.position.y += offset_y;
        this.screen_rect.size.width -= offset_x;
        this.screen_rect.size.height -= offset_y;
      }
      if (handle.type == "t_m") {
        this.screen_rect.position.y += offset_y;
        this.screen_rect.size.height -= offset_y;
      }
      if (handle.type == "t_r") {
        this.screen_rect.position.y += offset_y;
        this.screen_rect.size.width += offset_x;
        this.screen_rect.size.height -= offset_y;
      }
      if (handle.type == "l_m") {
        this.screen_rect.position.x += offset_x;
        this.screen_rect.size.width -= offset_x;
      }
      if (handle.type == "r_m") {
        this.screen_rect.size.width += offset_x;
      }
      if (handle.type == "l_b") {
        this.screen_rect.position.x += offset_x;
        this.screen_rect.size.width -= offset_x;
        this.screen_rect.size.height += offset_y;
      }
      if (handle.type == "m_b") {
        this.screen_rect.size.height += offset_y;
      }
      if (handle.type == "r_b") {
        this.screen_rect.size.width += offset_x;
        this.screen_rect.size.height += offset_y;
      }
      this.update_handles();
      this.set_native_rect();
      handle.selected = true;
    }
    move_to_new(x, y) {
      let offset_x = this.screen_rect.position.x - x;
      let offset_y = this.screen_rect.position.y - y;
      this.screen_rect.position.x = x;
      this.screen_rect.position.y = y;
      for (let i = 0; i < this.handles.length; i++) {
        let handle = this.handles[i];
        handle.rect.position.x += offset_x;
        handle.rect.position.y += offset_y;
      }
      this.rotation_cx += offset_x;
      this.rotation_cy += offset_y;
      this.set_native_rect();
    }
    move_to(offset_x, offset_y) {
      this.screen_rect.position.x += offset_x;
      this.screen_rect.position.y += offset_y;
      if (this.handles) {
        for (let i = 0; i < this.handles.length; i++) {
          let handle = this.handles[i];
          handle.rect.position.x += offset_x;
          handle.rect.position.y += offset_y;
        }
      }
      this.rotation_cx += offset_x;
      this.rotation_cy += offset_y;
      this.set_native_rect();
    }
    move_content(offset_x, offset_y) {
    }
    set_rotation(d) {
      if (!this.locked) {
        this.angle = d;
        this.update_rotated_handles;
        this.set_rotation_center();
        g_typograph_document.redraw();
      }
    }
    set_rotation_center() {
      this.native_rotation_cx = this.native_rect.position.x + this.native_rect.size.width / 2;
      this.native_rotation_cy = this.native_rect.position.y + this.native_rect.size.height / 2;
    }
    set_shadow_rotation(d) {
      this.shadow_angle = d;
      var angleInRad = d * 2 * Math.PI / 360;
      this.native_shadow_offset_x = this.native_shadow_length * Math.cos(angleInRad);
      this.native_shadow_offset_y = this.native_shadow_length * Math.sin(angleInRad);
      g_typograph_document.redraw();
    }
    set_shadow_length(l) {
      this.native_shadow_length = l;
      this.set_shadow_rotation(this.shadow_angle);
    }
    set_shadow_blur(b) {
      this.native_shadow_blur = b;
      g_typograph_document.redraw();
    }
    get_path(view) {
      let path = new Path2D();
      let x = this.screen_rect.position.x + view.offset_x;
      let y = this.screen_rect.position.y + view.offset_y;
      let w = this.screen_rect.size.width;
      let h = this.screen_rect.size.height;
      path.rect(x, y, w, h);
      return path;
    }
    is_point_in_path(view, p, path = null) {
      let inpath = false;
      let x = p.x;
      let y = p.y;
      if (view.hidpi) {
        x *= 2;
        y *= 2;
      }
      view.context.save();
      this.rotate_context(view);
      if (path == null)
        path = this.get_path(view);
      inpath = view.context.isPointInPath(path, x, y, "evenodd");
      view.context.restore();
      return inpath;
    }
    is_point_in_stroke(view, p) {
      let instroke = false;
      let x = p.x;
      let y = p.y;
      if (view.hidpi) {
        x *= 2;
        y *= 2;
      }
      view.context.save();
      view.context.lineWidth = this.border_size;
      this.rotate_context(view);
      instroke = view.context.isPointInStroke(this.get_path(view), x, y);
      view.context.restore();
      return instroke;
    }
    rotate_context(view) {
      if (this.angle != 0) {
        let cx = this.rotation_cx + view.offset_x;
        let cy = this.rotation_cy + view.offset_y;
        view.context.translate(cx, cy);
        view.context.rotate(Math.PI / 180 * this.angle);
        view.context.translate(-cx, -cy);
      }
    }
    set_auto_layout(auto_layout) {
      this.auto_layout = auto_layout;
    }
    get_auto_layout() {
      return this.auto_layout;
    }
    calc_stroke_dash_pattern(strokedash, linewidth) {
      if (linewidth < 2)
        return strokedash.pattern;
      let f = linewidth / 2;
      let pattern = [];
      for (let i = 0; i < strokedash.pattern.length; i++) {
        pattern.push(strokedash.pattern[i] * f);
      }
      return pattern;
    }
    draw(view) {
      view.context.save();
      this.rotate_context(view);
      if (this.fill_style != "none") {
        if (this.gradient != null) {
          let grd = view.context.createLinearGradient(0, 0, this.screen_rect.size.width, 0);
          for (let s = 0; s < this.gradient.samples.length; s++) {
            let sample = this.gradient.samples[s];
            let offset = sample.pos / 100;
            grd.addColorStop(offset, sample.color.rgba);
          }
          view.context.fillStyle = grd;
        } else {
          view.context.fillStyle = this.get_fill_style();
        }
        if (this.opacity == 1) {
          if (this.shadow) {
            view.context.save();
            view.context.shadowColor = this.shadow_color;
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
            view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
            view.context.restore();
          } else {
            view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
          }
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          if (this.shadow) {
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
          }
          view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
          view.context.restore();
        }
      }
      if (this.stroke_style != "none" && this.border_size > 0) {
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
        if (this.opacity == 1) {
          view.context.strokeRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          view.context.strokeRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
          view.context.restore();
        }
      }
      if (this.selected) {
      }
      view.context.restore();
    }
    draw_selection(view, focus = false) {
      view.context.save();
      this.rotate_context(view);
      if (view.show_handels) {
        let s_x = this.screen_rect.position.x + view.offset_x;
        let s_y = this.screen_rect.position.y + view.offset_y;
        let s_width = this.screen_rect.size.width;
        let s_height = this.screen_rect.size.height;
        view.context.lineWidth = 1;
        if (this.locked)
          view.context.strokeStyle = "#f30b2b";
        else
          view.context.strokeStyle = "#51c1fd";
        if (focus)
          view.context.strokeStyle = "#00ff00";
        view.context.strokeRect(s_x, s_y, s_width, s_height);
        if (this.auto_layout)
          this.draw_add_clip_icon(view, s_x, s_y);
        if (this.handles) {
          for (let i = 0; i < this.handles.length; i++) {
            let handle = this.handles[i];
            if (handle.draw) {
              let h_x = handle.rect.position.x + view.offset_x;
              let h_y = handle.rect.position.y + view.offset_y;
              let h_width = handle.rect.size.width;
              let h_height = handle.rect.size.height;
              if (handle.type != "image_center") {
                if (handle.selected)
                  view.context.fillStyle = view.context.strokeStyle;
                else
                  view.context.fillStyle = "rgba(255, 255, 255, 1.0)";
              }
              if (handle.type == "rotation") {
              } else {
                if (handle.type != "image_center") {
                  view.context.strokeStyle = "#51c1fd";
                  view.context.fillRect(h_x, h_y, h_width, h_height);
                  view.context.strokeRect(h_x, h_y, h_width, h_height);
                  view.context.strokeStyle = "#f00";
                  h_x = handle.rect.position.x + view.offset_x;
                  h_y = handle.rect.position.y + view.offset_y;
                  h_width = handle.rect.size.width;
                  h_height = handle.rect.size.height;
                  view.context.fillStyle = "rgba(255, 255, 255, 0.0)";
                }
              }
            }
          }
        }
      }
      view.context.restore();
      if (this.angle != 0 && view.show_handels) {
        view.context.save();
        let point_1 = new Point(this.rotated_handles[1].rect.position.x + view.offset_x + kHandleSize / 2, this.rotated_handles[1].rect.position.y + view.offset_y);
        let point_2 = new Point(this.rotated_handles[2].rect.position.x + view.offset_x + kHandleSize / 2, this.rotated_handles[2].rect.position.y + view.offset_y + kHandleSize / 2);
        let line3 = new Line(point_1, point_2);
        view.context.lineWidth = 1;
        view.context.strokeStyle = "#f00";
        for (let i = 0; i < this.rotated_handles.length; i++) {
          let handle = this.rotated_handles[i];
          if (handle.type != "image_center") {
            let h_x = handle.rect.position.x + view.offset_x;
            let h_y = handle.rect.position.y + view.offset_y;
            let h_width = handle.rect.size.width;
            let h_height = handle.rect.size.height;
            if (handle.selected)
              view.context.fillStyle = view.context.strokeStyle;
            else
              view.context.fillStyle = "rgba(255, 255, 255, 1.0)";
            if (handle.type == "rotation") {
            } else {
            }
          }
        }
        view.context.restore();
      }
    }
    draw_frame(view, remove = false) {
      view.context.save();
      this.rotate_context(view);
      let s_x = this.screen_rect.position.x + view.offset_x;
      let s_y = this.screen_rect.position.y + view.offset_y;
      let s_width = this.screen_rect.size.width;
      let s_height = this.screen_rect.size.height;
      view.context.lineWidth = 1;
      if (this.locked)
        view.context.strokeStyle = "#f30b2b";
      else
        view.context.strokeStyle = "#51c1fd";
      view.context.strokeRect(s_x, s_y, s_width, s_height);
      if (remove) {
        this.draw_remove_clip_icon(view, s_x, s_y);
      }
      view.context.restore();
    }
    draw_add_clip_icon(view, offset_x, offset_y) {
      if (view.show_handels) {
        let ctx = view.context;
        ctx.save();
        let w = 30;
        let h = 24;
        let margin = 10;
        let width = this.screen_rect.size.width;
        if (this.auto_layout.can_add(this))
          ctx.drawImage(g_add_clipping_icon, offset_x + width - (w + margin), offset_y - (h - 2), w, h);
        else
          ctx.drawImage(g_add_clipping_disabled_icon, offset_x + width - (w + margin), offset_y - (h - 2), w, h);
        ctx.restore();
      }
    }
    draw_remove_clip_icon(view, offset_x, offset_y) {
      if (view.show_handels) {
        let ctx = view.context;
        ctx.save();
        let w = 24;
        let h = 30;
        let margin = 10;
        let width = this.screen_rect.size.width;
        ctx.drawImage(g_remove_clipping_icon, offset_x + width, offset_y + margin, w, h);
        ctx.restore();
      }
    }
  };
  var PageElementGroup = class _PageElementGroup extends PageElement {
    constructor(id, type, page_elements) {
      super(id, type, new Rect(new Point(0, 0), new Size(0, 0)));
      this.page_elements = page_elements;
      this.calc_native_rect();
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        this.page_elements[i] = el.copy();
        this.page_elements[i].selected = false;
      }
    }
    copy() {
      let c = new _PageElementGroup(this.id, this.type, []);
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        el.selected = false;
        c.page_elements.push(el.copy());
      }
      this.copy_properties_to(c);
      c.calc_native_rect();
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
    }
    calc_native_rect() {
      let x_min = 1e3;
      let y_min = 1e3;
      let x_max = 0;
      let y_max = 0;
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        if (el) {
          let x = el.native_rect.position.x;
          let y = el.native_rect.position.y;
          if (x < x_min)
            x_min = x;
          if (y < y_min)
            y_min = y;
          let w = el.native_rect.size.width;
          let h = el.native_rect.size.height;
          if (x + w > x_max)
            x_max = x + w;
          if (y + h > y_max)
            y_max = y + h;
        }
      }
      this.native_rect.position.x = x_min;
      this.native_rect.position.y = y_min;
      this.native_rect.size.width = x_max - x_min;
      this.native_rect.size.height = y_max - y_min;
      super.update_native_rect(this.native_rect);
    }
    select_elements() {
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        el.selected = true;
      }
    }
    deselect_elements() {
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        el.selected = false;
      }
    }
    update_native_rect(r) {
      let scale_x = r.size.width / this.native_rect.size.width;
      let scale_y = r.size.height / this.native_rect.size.height;
      for (let e = 0; e < this.page_elements.length; e++) {
        let el = this.page_elements[e];
        let s_x = el.native_rect.position.x - this.native_rect.position.x;
        let s_y = el.native_rect.position.y - this.native_rect.position.y;
        let x = s_x * scale_x;
        let y = s_y * scale_y;
        x += r.position.x;
        y += r.position.y;
        let w = el.native_rect.size.width * scale_x;
        let h = el.native_rect.size.height * scale_y;
        let rect2 = new Rect(new Point(x, y), new Size(w, h));
        el.update_native_rect(rect2);
      }
      super.update_native_rect(r);
    }
    update_position(offset_x, offset_y) {
      super.update_position(offset_x, offset_y);
      for (let e = 0; e < this.page_elements.length; e++) {
        let el = this.page_elements[e];
        el.update_position(offset_x, offset_y);
      }
    }
    set_auto_layout(auto_layout) {
      super.set_auto_layout(auto_layout);
      for (let c = 0; c < auto_layout.templates.length; c++)
        this.add_group(auto_layout.templates[c]);
    }
    add_clip(index) {
      if (this.auto_layout) {
        if (index < this.auto_layout.templates.length) {
          this.add_group(this.auto_layout.templates[index]);
        }
      }
    }
    add_group(group) {
      let names = [];
      names.push(group.name);
      for (let e = 0; e < group.page_elements.length; e++)
        names.push(group.page_elements[e].get_name());
      group = group.copy();
      if (this.auto_layout) {
        let x_offset = 0;
        let y_offset = 0;
        let index = this.auto_layout.clips.length;
        if (index > 0) {
          for (let i = 0; i < index; i++) {
            let gr = this.auto_layout.clips[i];
            y_offset += gr.native_rect.size.height;
            y_offset += this.auto_layout.row_gab;
          }
        }
        group.set_name(names[0] + "_" + index);
        for (let e = 0; e < group.page_elements.length; e++)
          group.page_elements[e].set_name(names[e + 1] + "_" + index);
        g_typograph_document.offset_group_elements(group, this.native_rect.position.x + x_offset, this.native_rect.position.y + y_offset);
        this.auto_layout.add_clip(group);
      }
      this.page_elements.push(group);
    }
    remove_group(index) {
      this.page_elements.splice(index + 1, 1);
    }
    move_to(offset_x, offset_y) {
      super.move_to(offset_x, offset_y);
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        el.move_to(offset_x, offset_y);
      }
    }
    moveGroupElements(offset_x, offset_y) {
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        if (el.type == "group") {
          let group = el;
          group.moveGroupElements(offset_x, offset_y);
        } else {
          el.move_to(offset_x, offset_y);
        }
      }
    }
    update_screen_rect() {
      super.update_screen_rect();
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        el.update_screen_rect();
      }
    }
    update_save_screen_rect() {
      super.update_save_screen_rect();
      for (let i = 0; i < this.page_elements.length; i++) {
        let el = this.page_elements[i];
        el.update_save_screen_rect();
      }
    }
    resize(offsetx, offsety, handle, shift) {
      super.resize(offsetx, offsety, handle, shift);
      if (offsetx == 0 && offsety == 0)
        return;
      let x = 0;
      let y = 0;
      let w = 0;
      let h = 0;
      let scale_x = this.screen_rect.size.width / this.screen_saved_rect.size.width;
      let scale_y = this.screen_rect.size.height / this.screen_saved_rect.size.height;
      for (let e = 0; e < this.page_elements.length; e++) {
        let el = this.page_elements[e];
        let s_x = el.screen_saved_rect.position.x - this.screen_saved_rect.position.x;
        let x2 = s_x * scale_x;
        let s_y = el.screen_saved_rect.position.y - this.screen_saved_rect.position.y;
        let y2 = s_y * scale_y;
        let w2 = el.screen_saved_rect.size.width * scale_x;
        let h2 = el.screen_saved_rect.size.height * scale_y;
        if (el.type == "line") {
          let view = g_typograph_document.get_view();
          let line_el = el;
          line_el.screen_rect.position.x = this.screen_rect.position.x + x2;
          line_el.screen_rect.position.y = this.screen_rect.position.y + y2;
          line_el.screen_rect.size.width = w2;
          line_el.screen_rect.size.height = h2;
          line_el.p1_screen.x = line_el.screen_rect.position.x;
          line_el.p1_screen.y = line_el.screen_rect.position.y;
          line_el.p2_screen.x = line_el.p1_screen.x + this.screen_rect.size.width;
          line_el.p2_screen.y = line_el.p1_screen.y + this.screen_rect.size.height;
          line_el.line_screen.f.x = line_el.p1_screen.x;
          line_el.line_screen.f.y = line_el.p1_screen.y;
          line_el.line_screen.t.x = line_el.p2_screen.x;
          line_el.line_screen.t.y = line_el.p2_screen.y;
          line_el.p1_native = this.screen_point_to_native_point(view, line_el.p1_screen);
          line_el.p2_native = this.screen_point_to_native_point(view, line_el.p2_screen);
        }
        el.screen_rect.position.x = this.screen_rect.position.x + x2;
        el.screen_rect.position.y = this.screen_rect.position.y + y2;
        el.screen_rect.size.width = w2;
        el.screen_rect.size.height = h2;
        if (el.type == "path") {
          let path_el = el;
          path_el.path = path_el.save_path.copy();
          let path = path_el.path;
          path.move_to(-el.screen_saved_rect.position.x, -el.screen_saved_rect.position.y);
          path.scale(scale_x, scale_y);
          path.move_to(el.screen_rect.position.x, el.screen_rect.position.y);
        }
        el.update_handles();
        el.set_native_rect();
        if (el.type == "image") {
          let image_el = el;
          image_el.resize_image();
        }
      }
    }
    get_path(view) {
      if (!this.auto_layout) {
        return super.get_path(view);
      } else {
        let icon_w = 30;
        let icon_h = 24;
        let margin = 10;
        let x = this.screen_rect.position.x + view.offset_x;
        let y = this.screen_rect.position.y + view.offset_y;
        let w = this.screen_rect.size.width;
        let h = this.screen_rect.size.height;
        let path = new Path2D();
        path.moveTo(x, y);
        path.lineTo(x + w - icon_w - margin, y);
        path.lineTo(x + w - icon_w - margin, y - icon_h);
        path.lineTo(x + w - margin, y - icon_h);
        path.lineTo(x + w - margin, y);
        path.lineTo(x + w, y);
        path.lineTo(x + w, y + h);
        path.lineTo(x, y + h);
        path.lineTo(x, y);
        return path;
      }
    }
    set_screen_rect(view) {
      super.set_screen_rect(view);
      for (let e = 0; e < this.page_elements.length; e++) {
        let el = this.page_elements[e];
        el.set_screen_rect(view);
      }
    }
    draw(view) {
      for (let e = 0; e < this.page_elements.length; e++) {
        let el = this.page_elements[e];
        el.draw(view);
        if (this.auto_layout && this.selected) {
          if (el.type == "group") {
            el.draw_frame(view, true);
          }
        }
      }
    }
  };
  var PageElementRoundedRect = class _PageElementRoundedRect extends PageElement {
    constructor(id, type, native_rect, native_radius) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
      this.native_radius = native_radius;
      this.native_radiuses = [0, 0, 0, 0];
      this.screen_radiuses = [0, 0, 0, 0];
      this.TL = 0;
      this.TR = 1;
      this.BL = 2;
      this.BR = 3;
      for (let r = 0; r < 4; r++) {
        this.native_radiuses[r] = this.native_radius;
        this.screen_radiuses[r] = this.native_radius * gZoom * gMMtoScreen;
      }
    }
    copy() {
      let c = new _PageElementRoundedRect(this.id, this.type, this.native_rect, this.native_radius);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
      for (let r = 0; r < 4; r++) {
        e.native_radiuses[r] = this.native_radiuses[r];
        e.screen_radiuses[r] = this.screen_radiuses[r];
      }
    }
    set_screen_rect(view) {
      super.set_screen_rect(view);
      let factor = view.zoom * gMMtoScreen;
      if (this.native_radius == void 0) {
        this.native_radius = 0;
        for (let r = 0; r < 4; r++) {
          this.native_radiuses[r] = this.native_radius;
        }
      }
      for (let r = 0; r < 4; r++) {
        this.screen_radiuses[r] = this.native_radiuses[r] * factor;
      }
    }
    set_native_rect() {
      super.set_native_rect();
      let factor = g_typograph_document.get_view().zoom * gMMtoScreen;
      this.native_radius = this.screen_radiuses[0] / factor;
      for (let r = 0; r < 4; r++)
        this.native_radiuses[r] = this.screen_radiuses[r] / factor;
    }
    get_path(view) {
      const halfRadians = 2 * Math.PI / 2;
      const quarterRadians = 2 * Math.PI / 4;
      let x = this.screen_rect.position.x + view.offset_x;
      let y = this.screen_rect.position.y + view.offset_y;
      let width = this.screen_rect.size.width;
      let height = this.screen_rect.size.height;
      let rounded = [];
      for (let r = 0; r < 4; r++)
        rounded[r] = this.screen_radiuses[r];
      let path = new Path2D();
      path.arc(rounded[0] + x, rounded[0] + y, rounded[0], -quarterRadians, halfRadians, true);
      path.lineTo(x, y + height - rounded[2]);
      path.arc(rounded[2] + x, height - rounded[2] + y, rounded[2], halfRadians, quarterRadians, true);
      path.lineTo(x + width - rounded[3], y + height);
      path.arc(x + width - rounded[3], y + height - rounded[3], rounded[3], quarterRadians, 0, true);
      path.lineTo(x + width, y + rounded[1]);
      path.arc(x + width - rounded[1], y + rounded[1], rounded[1], 0, -quarterRadians, true);
      let fix = 0;
      if (rounded[0] == 0)
        fix = this.border_size / 2;
      path.lineTo(x + rounded[0] - fix, y);
      return path;
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      const halfRadians = 2 * Math.PI / 2;
      const quarterRadians = 2 * Math.PI / 4;
      let x = this.screen_rect.position.x + view.offset_x;
      let y = this.screen_rect.position.y + view.offset_y;
      let width = this.screen_rect.size.width;
      let height = this.screen_rect.size.height;
      let rounded = [];
      for (let r = 0; r < 4; r++)
        rounded[r] = this.screen_radiuses[r];
      view.context.beginPath();
      view.context.arc(rounded[0] + x, rounded[0] + y, rounded[0], -quarterRadians, halfRadians, true);
      view.context.lineTo(x, y + height - rounded[2]);
      view.context.arc(rounded[2] + x, height - rounded[2] + y, rounded[2], halfRadians, quarterRadians, true);
      view.context.lineTo(x + width - rounded[3], y + height);
      view.context.arc(x + width - rounded[3], y + height - rounded[3], rounded[3], quarterRadians, 0, true);
      view.context.lineTo(x + width, y + rounded[1]);
      view.context.arc(x + width - rounded[1], y + rounded[1], rounded[1], 0, -quarterRadians, true);
      let fix = 0;
      if (rounded[0] == 0)
        fix = this.border_size / 2;
      view.context.lineTo(x + rounded[0] - fix, y);
      if (this.get_fill_style() != "none") {
        if (this.gradient != null) {
          let grd;
          if (this.gradient.type == "linear") {
            grd = view.context.createLinearGradient(x, 0, x + width, 0);
            for (let s = 0; s < this.gradient.samples.length; s++) {
              let sample = this.gradient.samples[s];
              let offset = sample.pos / 100;
              let rgb = HEXtoRGB(sample.color.rgba);
              if (rgb == null)
                rgb = [0, 0, 0];
              let color2 = "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + "," + sample.opacity + ")";
              grd.addColorStop(offset, color2);
            }
          } else {
            let cx = x + width / 2;
            let cy = y + height / 2;
            grd = view.context.createRadialGradient(cx, cy, 0, cx, cy, width);
            for (let s = 0; s < this.gradient.samples.length; s++) {
              let sample = this.gradient.samples[s];
              let offset = sample.pos / 100;
              let rgb = HEXtoRGB(sample.color.rgba);
              if (rgb == null)
                rgb = [0, 0, 0];
              let color2 = "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + "," + sample.opacity + ")";
              grd.addColorStop(offset, color2);
            }
          }
          view.context.fillStyle = grd;
        } else {
          view.context.fillStyle = this.get_fill_style();
        }
        if (this.opacity == 1) {
          if (this.shadow) {
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
            view.context.fill();
          } else {
            view.context.fill();
          }
        } else {
          view.context.globalAlpha = this.opacity;
          if (this.shadow) {
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
          }
          view.context.fill();
        }
      }
      if (this.get_stroke_style() != "none" && this.border_size > 0) {
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        let pattern = this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size);
        view.context.setLineDash(pattern);
        if (this.opacity == 1) {
          view.context.stroke();
        } else {
          view.context.globalAlpha = this.opacity;
          view.context.stroke();
        }
      }
      if (this.selected) {
      }
      view.context.restore();
    }
  };
  var PageElementTriangle = class _PageElementTriangle extends PageElement {
    constructor(id, type, native_rect) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
    }
    copy() {
      let c = new _PageElementTriangle(this.id, this.type, this.native_rect);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      let x = this.screen_rect.position.x + view.offset_x;
      let y = this.screen_rect.position.y + view.offset_y;
      let w = this.screen_rect.size.width;
      let h = this.screen_rect.size.height;
      view.context.beginPath();
      view.context.moveTo(x, y);
      view.context.lineTo(x + w, y + h);
      view.context.lineTo(x, y + h);
      view.context.closePath();
      if (this.get_fill_style() != "none") {
        view.context.fillStyle = this.get_fill_style();
        if (this.opacity == 1) {
          if (this.shadow) {
            view.context.save();
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
            view.context.fill();
            view.context.restore();
          } else {
            view.context.fill();
          }
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          if (this.shadow) {
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
          }
          view.context.fill();
          view.context.restore();
        }
      }
      if (this.get_stroke_style() != "none" && this.border_size > 0) {
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
        if (this.opacity == 1) {
          view.context.stroke();
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          view.context.stroke();
          view.context.restore();
        }
      }
      if (this.selected) {
      }
      view.context.restore();
    }
  };
  var PageElementCircel = class _PageElementCircel extends PageElement {
    constructor(id, type, native_rect) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
    }
    copy() {
      let c = new _PageElementCircel(this.id, this.type, this.native_rect);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      let x = this.screen_rect.position.x + view.offset_x;
      let y = this.screen_rect.position.y + view.offset_y;
      let w = this.screen_rect.size.width;
      let h = this.screen_rect.size.height;
      let kappa = 0.5522848;
      let ox = w / 2 * kappa;
      let oy = h / 2 * kappa;
      let xe = x + w;
      let ye = y + h;
      let xm = x + w / 2;
      let ym = y + h / 2;
      view.context.beginPath();
      view.context.moveTo(x, ym);
      view.context.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
      view.context.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
      view.context.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
      view.context.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
      if (this.get_fill_style() != "none") {
        view.context.fillStyle = this.get_fill_style();
        if (this.opacity == 1) {
          if (this.shadow) {
            view.context.save();
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
            view.context.fill();
            view.context.restore();
          } else {
            view.context.fill();
          }
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          if (this.shadow) {
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
          }
          view.context.fill();
          view.context.restore();
        }
      }
      if (this.get_stroke_style() != "none" && this.border_size > 0) {
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
        if (this.opacity == 1) {
          view.context.stroke();
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          view.context.stroke();
          view.context.restore();
        }
      }
      if (this.selected) {
      }
      view.context.restore();
    }
  };
  var PageElementImage = class _PageElementImage extends PageElement {
    //None, Top, Center, Bottom
    constructor(id, type, native_rect, url, fit) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
      this.url = url;
      this.fit = fit;
      this.native_radiuses = [0, 0, 0, 0];
      this.screen_radiuses = [0, 0, 0, 0];
      this.key = null;
      if (url != "none") {
      }
      if (url != "none") {
      } else {
        let pixels_w = 0;
        let pixels_h = 0;
      }
      let position = new Point(this.native_rect.position.x, this.native_rect.position.y);
      let size = new Size(this.native_rect.size.width, this.native_rect.size.height);
      this.native_image_rect = new Rect(position, size);
      position = new Point(this.native_rect.position.x, this.native_rect.position.y);
      size = new Size(this.native_rect.size.width, this.native_rect.size.height);
      this.screen_image_rect = new Rect(position, size);
      position = new Point(this.native_rect.position.x, this.native_rect.position.y);
      size = new Size(this.native_rect.size.width, this.native_rect.size.height);
      this.screen_image_saved_rect = new Rect(position, size);
      this.content_moving = false;
      this.show_image_handles = false;
      this.resizing_image = false;
      this.image_angle = 0;
      this.image_scale = "Scale_to_Fit";
      this.image_horizontal_alignment = "Center";
      this.image_vertical_alignment = "Center";
      this.native_radius = 0;
      for (let r = 0; r < 4; r++) {
        this.native_radiuses[r] = 0;
        this.screen_radiuses[r] = 0;
      }
      if (this.url != "none" && this.url != "") {
        this.set_url(this.url);
      }
    }
    copy() {
      let c = new _PageElementImage(this.id, this.type, this.native_rect, this.url, this.fit);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
      let position = new Point(this.native_image_rect.position.x, this.native_image_rect.position.y);
      let size = new Size(this.native_image_rect.size.width, this.native_image_rect.size.height);
      e.native_image_rect = new Rect(position, size);
      position = new Point(this.screen_image_rect.position.x, this.screen_image_rect.position.y);
      size = new Size(this.screen_image_rect.size.width, this.screen_image_rect.size.height);
      e.screen_image_rect = new Rect(position, size);
      position = new Point(this.screen_image_saved_rect.position.x, this.native_image_rect.position.y);
      size = new Size(this.screen_image_saved_rect.size.width, this.native_image_rect.size.height);
      e.screen_image_saved_rect = new Rect(position, size);
      e.content_moving = this.content_moving;
      e.show_image_handles = this.show_image_handles;
      e.resizing_image = this.resizing_image;
      e.image_angle = this.image_angle;
      e.image_scale = this.image_scale;
      e.image_horizontal_alignment = this.image_horizontal_alignment;
      e.image_vertical_alignment = this.image_vertical_alignment;
      e.native_radius = 0;
      for (let r = 0; r < 4; r++) {
        e.native_radiuses[r] = this.native_radiuses[r];
        e.screen_radiuses[r] = this.screen_radiuses[r];
      }
    }
    imagesize() {
      let pixels_w = this.image.naturalWidth;
      let pixels_h = this.image.naturalHeight;
      return new Size(pixels_w / 96 * 25.4, pixels_h / 96 * 25.4);
    }
    set_url(url) {
      this.url = url;
      this.image = new Image();
      g_images_loaded.push({ element: this, url });
      this.image.src = url;
      this.image.onload = this.imageload.bind(this);
    }
    imageload() {
      this.set_fill_style("none");
      let pixels_w = this.image.naturalWidth;
      let pixels_h = this.image.naturalHeight;
      if (this.native_image_rect.size.width == 0 && this.native_image_rect.size.height == 0) {
        this.native_image_rect.size.width = pixels_w / 96 * 25.4;
        this.native_image_rect.size.height = pixels_h / 96 * 25.4;
      }
      if (this.fit == "scaletofit") {
        this.native_image_rect.size.width = this.native_rect.size.width;
        this.native_image_rect.size.height = this.native_rect.size.height;
      }
      this.fit = "none";
      this.resize(0, 0, null, true);
      this.set_screen_rect(g_typograph_document.canvas_view);
      this.set_native_rect();
      this.image.onload = null;
      let val = this.url;
      if (this.image_loaded.length > 0) {
        let index = g_images_loaded.map(function(e) {
          return e.url;
        }).indexOf(val);
        this.image_loaded(index);
      }
      if (g_typograph_document.pages.length > 0)
        g_typograph_document.redraw();
    }
    image_loaded(index) {
      g_images_loaded.splice(index, 1);
      if (g_images_loaded.length == 0) {
        g_typograph_document.canvas_view.canvas_element.dispatchEvent(new Event("typograph_page_images_loaded"));
      }
    }
    update_screen_rect() {
      super.update_screen_rect();
      this.screen_image_rect.position.x = this.screen_image_saved_rect.position.x;
      this.screen_image_rect.position.y = this.screen_image_saved_rect.position.y;
    }
    update_save_screen_rect() {
      super.update_save_screen_rect();
      this.screen_image_saved_rect.position.x = this.screen_image_rect.position.x;
      this.screen_image_saved_rect.position.y = this.screen_image_rect.position.y;
      this.screen_image_saved_rect.size.width = this.screen_image_rect.size.width;
      this.screen_image_saved_rect.size.height = this.screen_image_rect.size.height;
    }
    update_position(offset_x, offset_y) {
      super.update_position(offset_x, offset_y);
      this.screen_image_rect.position.x = this.screen_image_saved_rect.position.x + offset_x;
      this.screen_image_rect.position.y = this.screen_image_saved_rect.position.x + offset_y;
      this.update_handles();
      this.update_rotated_handles();
    }
    update_handles() {
      super.update_handles();
      let rect2 = this.screen_image_rect;
      let centersize = 20;
      let position = new Point(rect2.position.x + rect2.size.width / 2 - centersize / 2, rect2.position.y + rect2.size.height / 2 - centersize / 2);
      let size = new Size(centersize, centersize);
      let handle = new Handleselectionbox(new Rect(position, size), false, "image_center", rect2);
      this.handles.push(handle);
      this.image_handles = [];
      position = new Point(rect2.position.x, rect2.position.y);
      size = new Size(kHandleSize, kHandleSize);
      position.x = position.x - kHandleSize / 2;
      position.y = position.y - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "image_tl", rect2);
      this.image_handles.push(handle);
      position.x = rect2.position.x + rect2.size.width / 2 - kHandleSize / 2;
      position.y -= kRotationHandleOffset;
      handle = new Handleselectionbox(new Rect(position, size), false, "image_rotation", rect2);
      this.image_handles.push(handle);
      position.y = rect2.position.y - kHandleSize / 2;
      position.x = rect2.position.x + rect2.size.width - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "image_tr", rect2);
      this.image_handles.push(handle);
      position.x = rect2.position.x - kHandleSize / 2;
      position.y = rect2.position.y + rect2.size.height - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "image_lb", rect2);
      this.image_handles.push(handle);
      position.x = rect2.position.x + rect2.size.width - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "image_rb", rect2);
      this.image_handles.push(handle);
    }
    update_rotated_handles() {
      super.update_rotated_handles();
      this.rotated_image_handles = [];
      let cx = this.rotation_cx;
      let cy = this.rotation_cy;
      let angleInRad = this.angle * 2 * Math.PI / 360;
      for (let h = 0; h < this.image_handles.length; h++) {
        let position = new Point(this.image_handles[h].rect.position.x, this.image_handles[h].rect.position.y);
        let size = new Size(this.image_handles[h].rect.size.width, this.image_handles[h].rect.size.height);
        let x = position.x + size.width / 2 - cx;
        let y = position.y + size.height / 2 - cy;
        position.x = x * Math.cos(angleInRad) - y * Math.sin(angleInRad);
        position.y = x * Math.sin(angleInRad) + y * Math.cos(angleInRad);
        position.x = position.x - size.width / 2 + cx;
        position.y = position.y - size.height / 2 + cy;
        let handle = new Handleselectionbox(new Rect(position, size), false, this.image_handles[h].type, this.screen_rect);
        this.rotated_image_handles.push(handle);
      }
    }
    /**
     * Set pageelement to the size of the image...
     * @param f is a string and only "scaletofit" will have an effect
     */
    setfit(f) {
      this.fit = f;
      if (f == "scaletofit") {
        this.native_image_rect.position.x = this.native_rect.position.x;
        this.native_image_rect.position.y = this.native_rect.position.y;
        this.native_rect.size.width = this.native_image_rect.size.width;
        this.native_rect.size.height = this.native_image_rect.size.height;
        g_typograph_document.redraw();
      }
    }
    /**
     * Set the scale of the image
     * @param s string allowed are: 'None', 'Scale_to_Fill' or 'Scale_to_Fit'
     *
     *
     * None: will not scale the image in relation to the native_rect of this page_element
     * Scale_to_Fill will scale the image (proportinaly) to fill the whole native_rect of this page_element
     * Scate_to_Fit will scale the image (proportinaly) so the whole image will fit in the native_rect of this page_ement
     *
     * This method takes into account the horizontal and vertical image alignment
     *
     * image_horizontal_alignment can set to "None", "Left", "Center" of "Right"
     * image_vertical_alignment can be set to "None", "Top", "Center" or "Bottom"
     *
     * default values (in editor/template creator)
     * image_scale = 'Scale_to_Fit' image_horizontal_alignment = 'Center' image_vertical_alignment = 'Center'
     * template creator should set these parameters
     */
    set_image_scale(s) {
      if (s == "None")
        this.image_scale = s;
      if (s == "Scale_to_Fill")
        this.image_scale = s;
      if (s == "Scale_to_Fit")
        this.image_scale = s;
      this.resize_image();
    }
    resize_image() {
      if (this.image_scale == "None") {
        let size = this.native_image_rect.size;
        let w = size.width;
        let h = size.height;
        let offset_x = 0;
        let offset_y = 0;
        if (this.image_vertical_alignment == "Top") {
          offset_y = 0;
        }
        if (this.image_vertical_alignment == "Center") {
          offset_y = (this.native_rect.size.height - h) / 2;
        }
        if (this.image_vertical_alignment == "Bottom") {
          offset_y = this.native_rect.size.height - h;
        }
        if (this.image_horizontal_alignment == "Left") {
          offset_x = 0;
        }
        if (this.image_horizontal_alignment == "Center") {
          offset_x = (this.native_rect.size.width - w) / 2;
        }
        if (this.image_horizontal_alignment == "Right") {
          offset_x = this.native_rect.size.width - w;
        }
        if (this.image_horizontal_alignment != "None")
          this.native_image_rect.position.x = this.native_rect.position.x + offset_x;
        if (this.image_vertical_alignment != "None")
          this.native_image_rect.position.y = this.native_rect.position.y + offset_y;
      }
      if (this.image_scale == "Scale_to_Fill") {
        let size = this.imagesize();
        let c = this.native_rect.size.width / size.width;
        if (this.native_rect.size.height / size.height > c)
          c = this.native_rect.size.height / size.height;
        let w = size.width * c;
        let h = size.height * c;
        let offset_x = 0;
        let offset_y = 0;
        if (this.image_vertical_alignment == "Top") {
          offset_y = 0;
        }
        if (this.image_vertical_alignment == "Center") {
          offset_y = (this.native_rect.size.height - h) / 2;
        }
        if (this.image_vertical_alignment == "Bottom") {
          offset_y = this.native_rect.size.height - h;
        }
        if (this.image_horizontal_alignment == "Left") {
          offset_x = 0;
        }
        if (this.image_horizontal_alignment == "Center") {
          offset_x = (this.native_rect.size.width - w) / 2;
        }
        if (this.image_horizontal_alignment == "Right") {
          offset_x = this.native_rect.size.width - w;
        }
        this.native_image_rect.position.x = this.native_rect.position.x + offset_x;
        this.native_image_rect.position.y = this.native_rect.position.y + offset_y;
        this.native_image_rect.size.width = w;
        this.native_image_rect.size.height = h;
      }
      if (this.image_scale == "Scale_to_Fit") {
        let size = this.imagesize();
        let c = this.native_rect.size.width / size.width;
        if (this.native_rect.size.height / size.height < c)
          c = this.native_rect.size.height / size.height;
        let w = size.width * c;
        let h = size.height * c;
        let offset_x = 0;
        let offset_y = 0;
        if (this.image_vertical_alignment == "Top") {
          offset_y = 0;
        }
        if (this.image_vertical_alignment == "Center") {
          offset_y = (this.native_rect.size.height - h) / 2;
        }
        if (this.image_vertical_alignment == "Bottom") {
          offset_y = this.native_rect.size.height - h;
        }
        if (this.image_horizontal_alignment == "Left") {
          offset_x = 0;
        }
        if (this.image_horizontal_alignment == "Center") {
          offset_x = (this.native_rect.size.width - w) / 2;
        }
        if (this.image_horizontal_alignment == "Right") {
          offset_x = this.native_rect.size.width - w;
        }
        this.native_image_rect.position.x = this.native_rect.position.x + offset_x;
        this.native_image_rect.position.y = this.native_rect.position.y + offset_y;
        this.native_image_rect.size.width = w;
        this.native_image_rect.size.height = h;
      }
    }
    resize(offsetx, offsety, handle, shift) {
      super.resize(offsetx, offsety, handle, shift);
      this.resize_image();
    }
    move_to_new(x, y) {
      let current_x = this.screen_rect.position.x;
      let current_y = this.screen_rect.position.y;
      super.move_to_new(x, y);
      let offset_x = current_x - x;
      let offset_y = current_y - y;
      this.screen_image_rect.position.x += offset_x;
      this.screen_image_rect.position.y += offset_y;
      this.set_native_rect();
    }
    move_to(offset_x, offset_y) {
      super.move_to(offset_x, offset_y);
      this.screen_image_rect.position.x += offset_x;
      this.screen_image_rect.position.y += offset_y;
      this.set_native_rect();
    }
    move_content(offset_x, offset_y) {
      if (this.content_moving != true) {
        this.content_moving = true;
        this.show_image_handles = true;
      }
      this.screen_image_rect.position.x += offset_x;
      this.screen_image_rect.position.y += offset_y;
      this.set_native_rect();
    }
    resizeimage(offsetx = 0, offsety = 0, handle = null, shift = false) {
      this.screen_image_rect.position.x = this.screen_image_saved_rect.position.x;
      this.screen_image_rect.position.y = this.screen_image_saved_rect.position.y;
      this.screen_image_rect.size.width = this.screen_image_saved_rect.size.width;
      this.screen_image_rect.size.height = this.screen_image_saved_rect.size.height;
      let ration_x = 1;
      let ration_y = 1;
      let type = "image_tl";
      if (handle) {
        type = handle.type;
        handle.selected = true;
      }
      if (shift) {
        if (type == "image_tl" || type == "image_rb") {
          if (this.screen_image_saved_rect.size.width > this.screen_image_saved_rect.size.height) {
            ration_y = this.screen_image_saved_rect.size.height / this.screen_image_saved_rect.size.width;
            offsety = offsetx * ration_y;
          } else {
            ration_x = this.screen_image_saved_rect.size.width / this.screen_image_saved_rect.size.height;
            offsetx = offsety * ration_x;
          }
        } else {
          if (this.screen_image_saved_rect.size.width > this.screen_image_saved_rect.size.height) {
            ration_y = this.screen_image_saved_rect.size.height / this.screen_image_saved_rect.size.width;
            offsety = -1 * (offsetx * ration_y);
          } else {
            ration_x = this.screen_image_saved_rect.size.width / this.screen_image_saved_rect.size.height;
            offsetx = -1 * (offsety * ration_x);
          }
        }
      }
      if (type == "image_tl") {
        this.screen_image_rect.position.x += offsetx;
        this.screen_image_rect.position.y += offsety;
        this.screen_image_rect.size.width -= offsetx;
        this.screen_image_rect.size.height -= offsety;
        this.update_handles();
        this.set_native_rect();
      }
      if (type == "image_tr") {
        this.screen_image_rect.position.y += offsety;
        this.screen_image_rect.size.width += offsetx;
        this.screen_image_rect.size.height -= offsety;
        this.update_handles();
        this.set_native_rect();
      }
      if (type == "image_lb") {
        this.screen_image_rect.position.x += offsetx;
        this.screen_image_rect.size.width -= offsetx;
        this.screen_image_rect.size.height += offsety;
        this.update_handles();
        this.set_native_rect();
      }
      if (type == "image_rb") {
        this.screen_image_rect.size.width += offsetx;
        this.screen_image_rect.size.height += offsety;
        this.update_handles();
        this.set_native_rect();
      }
      if (this.resizing_image != true) {
        this.resizing_image = true;
      }
    }
    set_screen_rect(view) {
      let factor = view.zoom * gMMtoScreen;
      this.screen_image_rect.position.x = this.native_image_rect.position.x * factor;
      this.screen_image_rect.position.y = this.native_image_rect.position.y * factor;
      this.screen_image_rect.size.width = this.native_image_rect.size.width * factor;
      this.screen_image_rect.size.height = this.native_image_rect.size.height * factor;
      super.set_screen_rect(view);
      for (let r = 0; r < 4; r++)
        this.screen_radiuses[r] = this.native_radiuses[r] * factor;
    }
    set_native_rect() {
      let factor = g_typograph_document.get_view().zoom * gMMtoScreen;
      this.native_image_rect.position.x = this.screen_image_rect.position.x / factor;
      this.native_image_rect.position.y = this.screen_image_rect.position.y / factor;
      this.native_image_rect.size.width = this.screen_image_rect.size.width / factor;
      this.native_image_rect.size.height = this.screen_image_rect.size.height / factor;
      super.set_native_rect();
      for (let r = 0; r < 4; r++)
        this.native_radiuses[r] = this.screen_radiuses[r] / factor;
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      if (this.url != "none") {
        if (this.native_radiuses[0] == 0 && this.native_radiuses[1] == 0 && this.native_radiuses[2] == 0 && this.native_radiuses[3] == 0) {
          if (this.get_fill_style() != "none") {
            view.context.fillStyle = this.get_fill_style();
            view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
          }
        } else {
          if (this.get_fill_style() != "none") {
            view.context.save();
            const halfRadians = 2 * Math.PI / 2;
            const quarterRadians = 2 * Math.PI / 4;
            let x = this.screen_rect.position.x + view.offset_x;
            let y = this.screen_rect.position.y + view.offset_y;
            let width = this.screen_rect.size.width;
            let height = this.screen_rect.size.height;
            let rounded = [];
            for (let r = 0; r < 4; r++)
              rounded[r] = this.screen_radiuses[r];
            view.context.beginPath();
            view.context.arc(rounded[0] + x, rounded[0] + y, rounded[0], -quarterRadians, halfRadians, true);
            view.context.lineTo(x, y + height - rounded[2]);
            view.context.arc(rounded[2] + x, height - rounded[2] + y, rounded[2], halfRadians, quarterRadians, true);
            view.context.lineTo(x + width - rounded[3], y + height);
            view.context.arc(x + width - rounded[3], y + height - rounded[3], rounded[3], quarterRadians, 0, true);
            view.context.lineTo(x + width, y + rounded[1]);
            view.context.arc(x + width - rounded[1], y + rounded[1], rounded[1], 0, -quarterRadians, true);
            view.context.lineTo(x + rounded[0], y);
            view.context.fillStyle = this.get_fill_style();
            view.context.fill();
            view.context.restore();
          }
        }
      }
      if (this.image != void 0) {
        if (this.image.complete) {
          if (this.native_image_rect.isequal(this.native_rect) && this.native_radiuses[0] == 0 && this.native_radiuses[1] == 0 && this.native_radiuses[2] == 0 && this.native_radiuses[3] == 0) {
            view.context.save();
            if (this.opacity != 1)
              view.context.globalAlpha = this.opacity;
            if (this.shadow) {
              view.context.fillStyle = this.get_fill_style();
              view.context.shadowColor = this.get_shadow_color();
              view.context.shadowBlur = this.shadow_blur;
              view.context.shadowOffsetX = this.shadow_offset_x;
              view.context.shadowOffsetY = this.shadow_offset_y;
              view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
            } else {
              if (this.get_fill_style() != "none") {
                view.context.fillStyle = this.get_fill_style();
                view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
              }
            }
            try {
              view.context.drawImage(this.image, this.screen_image_rect.position.x + view.offset_x, this.screen_image_rect.position.y + view.offset_y, this.screen_image_rect.size.width, this.screen_image_rect.size.height);
            } catch (err) {
              console.log("error: " + err + " url: " + this.url);
            }
            if (this.border_size > 0 && this.get_stroke_style() != "none") {
              view.context.strokeStyle = this.get_stroke_style();
              view.context.lineWidth = this.border_size;
              view.context.strokeRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
              view.context.restore();
            }
            view.context.restore();
          } else {
            if (this.content_moving || this.resizing_image) {
              view.context.save();
              view.context.globalAlpha = 0.5;
              view.context.drawImage(this.image, this.screen_image_rect.position.x + view.offset_x, this.screen_image_rect.position.y + view.offset_y, this.screen_image_rect.size.width, this.screen_image_rect.size.height);
              view.context.restore();
            }
            view.context.save();
            if (this.opacity != 1)
              view.context.globalAlpha = this.opacity;
            view.context.beginPath();
            if (this.native_radiuses[0] == 0 && this.native_radiuses[1] == 0 && this.native_radiuses[2] == 0 && this.native_radiuses[3] == 0) {
              view.context.rect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
            } else {
              const halfRadians = 2 * Math.PI / 2;
              const quarterRadians = 2 * Math.PI / 4;
              let x = this.screen_rect.position.x + view.offset_x;
              let y = this.screen_rect.position.y + view.offset_y;
              let width = this.screen_rect.size.width;
              let height = this.screen_rect.size.height;
              let rounded = [];
              for (let r = 0; r < 4; r++)
                rounded[r] = this.screen_radiuses[r];
              view.context.arc(rounded[0] + x, rounded[0] + y, rounded[0], -quarterRadians, halfRadians, true);
              view.context.lineTo(x, y + height - rounded[2]);
              view.context.arc(rounded[2] + x, height - rounded[2] + y, rounded[2], halfRadians, quarterRadians, true);
              view.context.lineTo(x + width - rounded[3], y + height);
              view.context.arc(x + width - rounded[3], y + height - rounded[3], rounded[3], quarterRadians, 0, true);
              view.context.lineTo(x + width, y + rounded[1]);
              view.context.arc(x + width - rounded[1], y + rounded[1], rounded[1], 0, -quarterRadians, true);
              view.context.lineTo(x + rounded[0], y);
            }
            if (this.shadow) {
              view.context.fillStyle = this.get_fill_style();
              view.context.shadowColor = this.get_shadow_color();
              view.context.shadowBlur = this.shadow_blur;
              view.context.shadowOffsetX = this.shadow_offset_x;
              view.context.shadowOffsetY = this.shadow_offset_y;
              if (this.native_radiuses[0] == 0 && this.native_radiuses[1] == 0 && this.native_radiuses[2] == 0 && this.native_radiuses[3] == 0)
                view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
              else
                view.context.fill();
            } else {
              if (this.get_fill_style() != "none") {
                view.context.fillStyle = this.get_fill_style();
                if (this.native_radiuses[0] == 0 && this.native_radiuses[1] == 0 && this.native_radiuses[2] == 0 && this.native_radiuses[3] == 0)
                  view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
                else
                  view.context.fill();
              }
            }
            view.context.clip();
            try {
              view.context.drawImage(this.image, this.screen_image_rect.position.x + view.offset_x, this.screen_image_rect.position.y + view.offset_y, this.screen_image_rect.size.width, this.screen_image_rect.size.height);
            } catch (err) {
              console.log("error: " + err + " url: " + this.url);
            }
            view.context.restore();
            if (this.border_size > 0 && this.get_stroke_style() != "none") {
              if (this.opacity != 1)
                view.context.globalAlpha = this.opacity;
              view.context.strokeStyle = this.get_stroke_style();
              view.context.lineWidth = this.border_size;
              view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
              if (this.native_radiuses[0] == 0 && this.native_radiuses[1] == 0 && this.native_radiuses[2] == 0 && this.native_radiuses[3] == 0) {
                view.context.strokeRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
              } else {
                const halfRadians = 2 * Math.PI / 2;
                const quarterRadians = 2 * Math.PI / 4;
                let x = this.screen_rect.position.x + view.offset_x;
                let y = this.screen_rect.position.y + view.offset_y;
                let width = this.screen_rect.size.width;
                let height = this.screen_rect.size.height;
                let rounded = [];
                for (let r = 0; r < 4; r++)
                  rounded[r] = this.screen_radiuses[r];
                view.context.beginPath();
                view.context.arc(rounded[0] + x, rounded[0] + y, rounded[0], -quarterRadians, halfRadians, true);
                view.context.lineTo(x, y + height - rounded[2]);
                view.context.arc(rounded[2] + x, height - rounded[2] + y, rounded[2], halfRadians, quarterRadians, true);
                view.context.lineTo(x + width - rounded[3], y + height);
                view.context.arc(x + width - rounded[3], y + height - rounded[3], rounded[3], quarterRadians, 0, true);
                view.context.lineTo(x + width, y + rounded[1]);
                view.context.arc(x + width - rounded[1], y + rounded[1], rounded[1], 0, -quarterRadians, true);
                let fix = 0;
                if (rounded[0] == 0)
                  fix = this.border_size / 2;
                view.context.lineTo(x + rounded[0] - fix, y);
                view.context.stroke();
              }
            }
          }
        }
      } else {
        if (this.native_radiuses[0] == 0 && this.native_radiuses[1] == 0 && this.native_radiuses[2] == 0 && this.native_radiuses[3] == 0) {
          if (this.get_fill_style() != "none") {
            view.context.fillStyle = this.get_fill_style();
            view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
          }
        } else {
          if (this.get_fill_style() != "none") {
            view.context.save();
            const halfRadians = 2 * Math.PI / 2;
            const quarterRadians = 2 * Math.PI / 4;
            let x = this.screen_rect.position.x + view.offset_x;
            let y = this.screen_rect.position.y + view.offset_y;
            let width = this.screen_rect.size.width;
            let height = this.screen_rect.size.height;
            let rounded = [];
            for (let r = 0; r < 4; r++)
              rounded[r] = this.screen_radiuses[r];
            view.context.beginPath();
            view.context.arc(rounded[0] + x, rounded[0] + y, rounded[0], -quarterRadians, halfRadians, true);
            view.context.lineTo(x, y + height - rounded[2]);
            view.context.arc(rounded[2] + x, height - rounded[2] + y, rounded[2], halfRadians, quarterRadians, true);
            view.context.lineTo(x + width - rounded[3], y + height);
            view.context.arc(x + width - rounded[3], y + height - rounded[3], rounded[3], quarterRadians, 0, true);
            view.context.lineTo(x + width, y + rounded[1]);
            view.context.arc(x + width - rounded[1], y + rounded[1], rounded[1], 0, -quarterRadians, true);
            view.context.lineTo(x + rounded[0], y);
            view.context.fillStyle = this.get_fill_style();
            view.context.fill();
            view.context.restore();
          }
        }
      }
      if (this.selected) {
      }
      view.context.restore();
    }
    draw_selection(view) {
      super.draw_selection(view);
      view.context.save();
      this.rotate_context(view);
      if (view.show_handels) {
        let m_x = this.screen_image_rect.size.width / 2 + this.screen_image_rect.position.x + view.offset_x;
        let m_y = this.screen_image_rect.size.height / 2 + this.screen_image_rect.position.y + view.offset_y;
        view.context.beginPath();
        view.context.arc(m_x, m_y, 10, 0, 2 * Math.PI);
        view.context.fillStyle = "rgba(255, 255, 255, 0.5)";
        view.context.fill();
        view.context.lineWidth = 0.5;
        view.context.strokeStyle = "#785744";
        view.context.stroke();
        if (this.show_image_handles) {
          let s_x = this.screen_image_rect.position.x + view.offset_x;
          let s_y = this.screen_image_rect.position.y + view.offset_y;
          let s_width = this.screen_image_rect.size.width;
          let s_height = this.screen_image_rect.size.height;
          view.context.lineWidth = 0.5;
          view.context.strokeStyle = "#785744";
          view.context.strokeRect(s_x, s_y, s_width, s_height);
          for (let i = 0; i < this.image_handles.length; i++) {
            let handle = this.image_handles[i];
            let h_x = handle.rect.position.x + view.offset_x;
            let h_y = handle.rect.position.y + view.offset_y;
            let h_width = handle.rect.size.width;
            let h_height = handle.rect.size.height;
            h_x += h_width / 2;
            h_y += h_height / 2;
            if (handle.selected)
              view.context.fillStyle = view.context.strokeStyle;
            else
              view.context.fillStyle = "rgba(255, 255, 255, 1.0)";
            view.context.beginPath();
            view.context.arc(h_x, h_y, 5, 0, 2 * Math.PI);
            view.context.fill();
            view.context.stroke();
          }
        }
      }
      if (this.angle != 0 && view.show_handels) {
        view.context.save();
        view.context.strokeStyle = "#f00";
        let handle = this.handles[9];
        let h_x = handle.rect.position.x + view.offset_x + 20 / 2;
        let h_y = handle.rect.position.y + view.offset_y + 20 / 2;
        view.context.beginPath();
        view.context.arc(h_x, h_y, 10, 0, 2 * Math.PI);
        view.context.stroke();
        view.context.restore();
      }
      view.context.restore();
      view.context.save();
      if (this.angle != 0 && this.show_image_handles && view.show_handels) {
        view.context.lineWidth = 1;
        view.context.strokeStyle = "#0f0";
        for (let i = 0; i < this.rotated_image_handles.length; i++) {
          let handle = this.rotated_image_handles[i];
          let h_x = handle.rect.position.x + view.offset_x;
          let h_y = handle.rect.position.y + view.offset_y;
          let h_width = handle.rect.size.width;
          let h_height = handle.rect.size.height;
          view.context.fillStyle = "rgba(255,255,255, 1.0)";
          if (handle.type == "image_rotation") {
            h_x += h_width / 2;
            h_y += h_height / 2;
            view.context.save();
            view.context.beginPath();
            view.context.arc(h_x, h_y, h_width / 2, 0, 2 * Math.PI);
            view.context.fill();
            view.context.stroke();
            view.context.restore();
          } else {
            view.context.fillRect(h_x, h_y, h_width, h_height);
            view.context.strokeRect(h_x, h_y, h_width, h_height);
          }
        }
      }
      view.context.restore();
    }
  };
  var PageElementRichText = class _PageElementRichText extends PageElement {
    constructor(id, type, native_rect, font_size, text) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
      this.font_size = font_size;
      this.text = text;
      this.default_font = {
        name: kDefaultFontName,
        size: 24,
        attributes: { bold: false, italic: false },
        formatting: { margin: [], tag: "" },
        style: "black",
        text: "24pt Source Sans Pro regular",
        font_style: kDefaultFontStyle
      };
      this.tag_list = [
        { name: "h1", size: 30 },
        { name: "h2", size: 24 },
        { name: "h3", size: 18 },
        { name: "h4", size: 16 },
        { name: "p", size: 12 }
      ];
      this.text_color = kDefaultFontColor;
      //100 t/m 900 standaard 0 (empty)
      this.size = { width: 0, height: 0 };
      //100% size...
      this.save_elements = [];
      this.save_lines = [];
      this.constructing = false;
      this.set_fill_style("#000");
      this.set_stroke_style("none");
      this.native_border_size = 0;
      this.border_size = 0;
      this.font = g_typograph_document.get_font_manager().getFontForFontName(kDefaultFontName);
      this.font_name = this.default_font.name;
      this.font_style = kDefaultFontStyle;
      this.style = kDefaultFontStyle;
      this.native_font_size = font_size;
      this.default_font.size = font_size;
      this.default_font.text = font_size + "pt " + this.font_name + " " + this.font_style;
      this.native_autoleading = 120;
      this.pod_tekst_style = "Header";
      this.pod_font_weight = 0;
      this.text_align = "Left";
      this.vertical_align = "top";
      this.native_padding = 0;
      this.columns = 1;
      this.native_columns_cap = 0;
      this.text_color = kDefaultFontColor;
      this.text_stroke_color = kDefaultFontColor;
      this.native_text_stroke_size = 0;
      this.size.width = this.native_rect.size.width * gMMtoPixel;
      this.size.height = this.native_rect.size.height * gMMtoPixel;
      this.rich_text = new RichText(this.size, this.default_font, this.tag_list, true);
      this.rich_text.setFont(this.default_font);
      this.rich_text.constructing = true;
      this.rich_text.textInput(text);
      this.rich_text.constructing = false;
      this.rich_text.getEditor().clearBackground = false;
      this.rich_text.getEditor().layout(this.size.width, this.size.height);
      this.rich_text.getEditor().setAlignment(this.text_align);
      this.constructing = false;
    }
    copy() {
      let c = new _PageElementRichText(this.id, this.type, this.native_rect, this.font_size, this.text);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
      e.font = this.font;
      e.font_name = this.font_name;
      e.font_style = this.font_style;
      e.style = this.style;
      e.native_font_size = this.native_font_size;
      e.default_font.size = this.default_font.size;
      e.default_font.text = this.default_font.text;
      e.native_autoleading = this.native_autoleading;
      e.pod_tekst_style = this.pod_tekst_style;
      e.pod_font_weight = this.pod_font_weight;
      e.text_align = this.text_align;
      e.vertical_align = this.vertical_align;
      e.native_padding = this.native_padding;
      e.columns = this.columns;
      e.native_columns_cap = this.native_columns_cap;
      e.text_color = this.text_color;
      e.text_stroke_color = this.text_stroke_color;
      e.native_text_stroke_size = this.native_text_stroke_size;
      e.size.width = this.size.width;
      e.size.height = this.size.height;
      e.rich_text = this.rich_text.copy();
    }
    getMousePos(event) {
      let element3 = document.getElementById("typograph_viewer");
      let rect2 = element3.getBoundingClientRect();
      g_typograph_document.offset_left = rect2.left;
      g_typograph_document.header_height = rect2.top;
      let view = g_typograph_document.get_view();
      let point = new Point(event.clientX, event.clientY);
      point.x = point.x - (g_typograph_document.offset_left + view.offset_x);
      point.y = point.y - (g_typograph_document.header_height + view.offset_y);
      return point;
    }
    getTextPos(event) {
      let p = this.getMousePos(event);
      p.x = p.x - this.screen_rect.position.x;
      p.y = p.y - this.screen_rect.position.y;
      return p;
    }
    keyDown(key) {
      this.rich_text.keyDown(key);
    }
    mouseDown(event) {
      let view = g_typograph_document.get_view();
      this.rich_text.getEditor().screen_X = this.screen_rect.position.x + view.offset_x;
      this.rich_text.getEditor().screen_Y = this.screen_rect.position.y + view.offset_y;
      let pos = this.getMousePos(event);
      if (this.inside(pos)) {
        pos = this.getTextPos(event);
        this.rich_text.mouseDown({ x: pos.x, y: pos.y });
        event.preventDefault();
      }
    }
    mouseMove(event) {
      let pos = this.getMousePos(event);
      let view = g_typograph_document.get_view();
      if (event.buttons == 0) {
        if (this.inside(pos) && this.rich_text.editor.focus) {
          if (view.canvas_element.style.cursor != "text")
            view.canvas_element.style.cursor = "text";
          pos = this.getTextPos(event);
          this.rich_text.getEditor().mouseMove({ x: pos.x, y: pos.y });
          event.preventDefault();
        } else {
        }
      } else {
        view.canvas_element.style.cursor = "text";
        pos = this.getTextPos(event);
        this.rich_text.getEditor().mouseMove({ x: pos.x, y: pos.y });
        event.preventDefault();
      }
    }
    mouseUp(event) {
      this.rich_text.mouseUp();
      let pos = this.getMousePos(event);
      if (!this.inside(pos)) {
      }
    }
    setFocus(f) {
      this.rich_text.setFocus(f);
    }
    setFont(font3) {
      this.rich_text.setFont(font3);
    }
    inside(pos) {
      let r = new Rect(this.screen_rect.position, this.screen_rect.size);
      r.shrink(4);
      let p = new Point(pos.x, pos.y);
      return r.point_in_rect(p);
    }
    set_size() {
      this.size.width = this.native_rect.size.width * gMMtoPixel;
      this.size.height = this.native_rect.size.height * gMMtoPixel;
      this.rich_text.getEditor().layout(this.size.width, this.size.height);
    }
    set_native_rect() {
      super.set_native_rect();
      this.set_size();
    }
    update_native_rect(r) {
      super.update_native_rect(r);
      this.set_size();
    }
    setTextColor(color2) {
      this.text_color = color2;
    }
    getTextColor() {
      if (this.text_color.indexOf("COLOR_") != -1) {
        let i = parseInt(this.text_color.split("_")[1]);
        let rgba = g_typograph_document.brand_colors.colors[i - 1].rgba;
        return rgba;
      } else
        return this.text_color;
    }
    getTextColorName() {
      if (this.text_color.indexOf("COLOR_") != -1) {
        return this.text_color;
      } else {
        if (this.text_color.indexOf("rgb()") == -1) {
          if (this.text_color == "#000" || this.text_color == "#000000" || this.text_color == "black")
            return "COLOR_2";
          if (this.text_color == "#fff" || this.text_color == "#ffffff" || this.text_color == "white")
            return "COLOR_1";
          return "COLOR_3";
        } else {
          if (this.text_color == "rgb(0,0,0)")
            return "COLOR_2";
          if (this.text_color == "rgb(255,255,255")
            return "COLOR_1";
          return "COLOR_3";
        }
      }
    }
    getTextColorNative() {
      return this.text_color;
    }
    setTextStrokeColor(color2) {
      if (color2 == void 0)
        this.text_stroke_color = "none";
      else
        this.text_stroke_color = color2;
    }
    getTextStrokeColor() {
      if (this.text_stroke_color.indexOf("COLOR_") != -1) {
        let i = parseInt(this.text_stroke_color.split("_")[1]);
        let rgba = g_typograph_document.brand_colors.colors[i - 1].rgba;
        return rgba;
      } else
        return this.text_stroke_color;
    }
    getTextStroleColorName() {
      if (this.text_stroke_color.indexOf("COLOR_") != -1) {
        return this.text_stroke_color;
      } else {
        return "none";
      }
    }
    getTextStrokeColorNative() {
      return this.text_stroke_color;
    }
    draw_selection(view, focus = false) {
      if (view.show_handels) {
        let s_x = this.screen_rect.position.x + view.offset_x;
        let s_y = this.screen_rect.position.y + view.offset_y;
        let s_width = this.screen_rect.size.width;
        let s_height = this.screen_rect.size.height;
        if (focus) {
          view.context.strokeStyle = "#00ff00";
          view.context.strokeRect(s_x, s_y, s_width, s_height);
        } else {
          super.draw_selection(view, false);
        }
      }
    }
    draw(view) {
      if (this.rich_text == null) {
        console.log("element_id: " + this.id + " rich_text==null");
        return;
      }
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      let scale = view.zoom;
      this.rich_text.getEditor().setScale(scale);
      if (this.opacity != 1)
        view.context.globalAlpha = this.opacity;
      view.context.beginPath();
      if (this.shadow) {
        view.context.shadowColor = this.get_shadow_color();
        view.context.shadowBlur = this.shadow_blur;
        view.context.shadowOffsetX = this.shadow_offset_x;
        view.context.shadowOffsetY = this.shadow_offset_y;
      }
      let screenX = this.screen_rect.position.x + view.offset_x;
      let screenY = this.screen_rect.position.y + view.offset_y;
      this.rich_text.getEditor().draw(view, screenX, screenY);
      view.context.restore();
    }
  };
  var PageElementText = class _PageElementText extends PageElement {
    //100 t/m 900 standaard 0 (empty)
    constructor(id, type, native_rect, text) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
      this.text = text;
      this.set_fill_style("none");
      this.set_stroke_style("none");
      this.native_border_size = 0;
      this.border_size = 0;
      this.font = "Source Sans Pro";
      this.style = "regular";
      this.native_font_size = 64;
      this.native_auto_leading = 120;
      this.pod_tekst_style = "Header";
      this.pod_font_name = "";
      this.pod_font_weight = 0;
      this.text_align = "left";
      this.vertical_align = "top";
      this.native_padding = 0;
      this.columns = 1;
      this.native_columns_cap = 0;
      this.text_color = "COLOR_2";
      this.text_stroke_color = "COLOR_1";
      this.native_text_stroke_size = 0;
    }
    setTextColor(color2) {
      this.text_color = color2;
    }
    copy() {
      let c = new _PageElementText(this.id, this.type, this.native_rect, this.text);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
      e.font = this.font;
      e.style = this.style;
      e.native_font_size = this.native_font_size;
      e.native_auto_leading = this.native_auto_leading;
      e.pod_tekst_style = this.pod_tekst_style;
      e.pod_font_name = this.pod_font_name;
      e.pod_font_weight = this.pod_font_weight;
      e.text_align = this.text_align;
      e.vertical_align = this.vertical_align;
      e.native_padding = this.native_padding;
      e.columns = this.columns;
      e.native_columns_cap = this.native_columns_cap;
      e.text_color = this.text_color;
      e.text_stroke_color = this.text_stroke_color;
      e.native_text_stroke_size = this.native_text_stroke_size;
    }
    getTextColor() {
      if (this.text_color.indexOf("COLOR_") != -1) {
        let i = parseInt(this.text_color.split("_")[1]);
        let rgba = g_typograph_document.brand_colors.colors[i - 1].rgba;
        return rgba;
      } else
        return this.text_color;
    }
    getTextColorName() {
      if (this.text_color.indexOf("COLOR_") != -1) {
        return this.text_color;
      } else {
        if (this.text_color.indexOf("rgb()") == -1) {
          if (this.text_color == "#000" || this.text_color == "#000000" || this.text_color == "black")
            return "COLOR_2";
          if (this.text_color == "#fff" || this.text_color == "#ffffff" || this.text_color == "white")
            return "COLOR_1";
          return "COLOR_3";
        } else {
          if (this.text_color == "rgb(0,0,0)")
            return "COLOR_2";
          if (this.text_color == "rgb(255,255,255")
            return "COLOR_1";
          return "COLOR_3";
        }
      }
    }
    getTextColorNative() {
      return this.text_color;
    }
    setTextStrokeColor(color2) {
      if (color2 == void 0)
        this.text_stroke_color = "none";
      else
        this.text_stroke_color = color2;
    }
    getTextStrokeColor() {
      if (this.text_stroke_color.indexOf("COLOR_") != -1) {
        let i = parseInt(this.text_stroke_color.split("_")[1]);
        let rgba = g_typograph_document.brand_colors.colors[i - 1].rgba;
        return rgba;
      } else
        return this.text_stroke_color;
    }
    getTextStroleColorName() {
      if (this.text_stroke_color.indexOf("COLOR_") != -1) {
        return this.text_stroke_color;
      } else {
        return "none";
      }
    }
    getTextStrokeColorNative() {
      return this.text_stroke_color;
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      if (this.get_fill_style() != "none") {
        view.context.fillStyle = this.get_fill_style();
        view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
      }
      let size = this.native_font_size * (96 / 72) * view.zoom;
      let font3 = size + "px " + this.font;
      if (this.opacity != 1)
        view.context.globalAlpha = this.opacity;
      view.context.font = font3;
      view.context.textBaseline = "top";
      view.context.fillStyle = this.getTextColor();
      view.context.beginPath();
      view.context.rect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
      view.context.clip();
      if (this.shadow) {
        view.context.shadowColor = this.get_shadow_color();
        view.context.shadowBlur = this.shadow_blur;
        view.context.shadowOffsetX = this.shadow_offset_x;
        view.context.shadowOffsetY = this.shadow_offset_y;
      }
      let baseline_offset = size * 0.1;
      baseline_offset = 0;
      view.context.fillText(this.text, this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y - baseline_offset);
      if (this.border_size > 0 && this.getTextStrokeColor() != "none") {
        view.context.shadowBlur = 0;
        view.context.shadowOffsetX = 0;
        view.context.shadowOffsetY = 0;
        view.context.beginPath();
        view.context.rect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
        view.context.strokeStyle = this.getTextStrokeColor();
        view.context.lineWidth = this.border_size;
        view.context.strokeText(this.text, this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y);
      }
      this.draw_colums(view);
      view.context.restore();
      if (this.selected) {
      }
    }
    draw_colums(view) {
      if (this.selected) {
        if (this.columns > 1) {
          view.context.lineWidth = 1;
          if (this.locked)
            view.context.strokeStyle = "#f30b2b";
          else
            view.context.strokeStyle = "#51c1fd";
          let columnsize = (this.native_rect.size.width - this.native_columns_cap * (this.columns - 1)) / this.columns;
          let y = this.native_rect.position.y;
          let y1 = y + this.native_rect.size.height;
          let x = this.native_rect.position.x;
          for (let i = 0; i < this.columns - 1; i++) {
            x += columnsize;
            let point_1 = new Point(x, y);
            let point_2 = new Point(x, y1);
            point_1 = this.native_point_to_screen_point(view, point_1);
            point_2 = this.native_point_to_screen_point(view, point_2);
            let line3 = new Line(point_1, point_2, view.context.strokeStyle);
            line3.draw(view);
            x += this.native_columns_cap;
            point_1 = new Point(x, y);
            point_2 = new Point(x, y1);
            point_1 = this.native_point_to_screen_point(view, point_1);
            point_2 = this.native_point_to_screen_point(view, point_2);
            line3 = new Line(point_1, point_2, view.context.strokeStyle);
            line3.draw(view);
          }
        }
      }
    }
  };
  var PageElementTable = class _PageElementTable extends PageElement {
    constructor(id, type, native_rect, rows, columns) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
      this.rows = rows;
      this.columns = columns;
    }
    copy() {
      let c = new _PageElementTable(this.id, this.type, this.native_rect, this.rows, this.columns);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      let columns_offset = this.screen_rect.size.width / this.columns;
      let row_offset = this.screen_rect.size.height / this.rows;
      let x = this.screen_rect.position.x + view.offset_x;
      let y = this.screen_rect.position.y + view.offset_y;
      let width = this.screen_rect.size.width;
      let height = this.screen_rect.size.height;
      if (this.get_fill_style() != "none") {
        view.context.save();
        if (this.opacity != 1)
          view.context.globalAlpha = this.opacity;
        if (this.shadow) {
          view.context.shadowColor = this.get_shadow_color();
          view.context.shadowBlur = this.shadow_blur;
          view.context.shadowOffsetX = this.shadow_offset_x;
          view.context.shadowOffsetY = this.shadow_offset_y;
        }
        view.context.fillStyle = this.get_fill_style();
        view.context.fillRect(this.screen_rect.position.x + view.offset_x, this.screen_rect.position.y + view.offset_y, this.screen_rect.size.width, this.screen_rect.size.height);
        view.context.restore();
      }
      view.context.beginPath();
      for (let r = 0; r < this.rows + 1; r++) {
        view.context.moveTo(x, y);
        view.context.lineTo(x + width, y);
        y += row_offset;
      }
      y = this.screen_rect.position.y + view.offset_y;
      for (let c = 0; c < this.columns + 1; c++) {
        view.context.moveTo(x, y);
        view.context.lineTo(x, y + height);
        x += columns_offset;
      }
      if (this.get_stroke_style() != "none" && this.border_size > 0) {
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
        view.context.stroke();
      }
      view.context.restore();
    }
  };
  var PageElementLinePOD = class _PageElementLinePOD extends PageElement {
    constructor(id, type, native_rect, stroke_width) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
      this.stroke_width = stroke_width;
      this.native_border_size = stroke_width;
      if (this.native_rect.size.width < stroke_width) {
        this.native_rect.position.x -= 2;
        this.native_rect.size.width = 4;
        this.horizontal = false;
      } else {
        this.native_rect.position.y -= 2;
        this.native_rect.size.height = 4;
        this.horizontal = true;
      }
    }
    copy() {
      let c = new _PageElementLinePOD(this.id, this.type, this.native_rect, this.stroke_width);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      if (this.get_stroke_style() != "none" && this.border_size > 0) {
        let x = this.screen_rect.position.x + view.offset_x;
        let y = this.screen_rect.position.y + this.screen_rect.size.height / 2 + view.offset_y;
        let x1 = this.screen_rect.position.x + this.screen_rect.size.width + view.offset_x;
        let y1 = this.screen_rect.position.y + this.screen_rect.size.height / 2 + view.offset_y;
        if (!this.horizontal) {
          x = this.screen_rect.position.x + this.screen_rect.size.width / 2 + view.offset_x;
          y = this.screen_rect.position.y + view.offset_y;
          x1 = this.screen_rect.position.x + this.screen_rect.size.width / 2 + view.offset_x;
          y1 = this.screen_rect.position.y + this.screen_rect.size.height + view.offset_y;
        }
        view.context.beginPath();
        view.context.moveTo(x, y);
        view.context.lineTo(x1, y1);
        view.context.lineWidth = this.border_size;
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
        if (this.opacity == 1) {
          view.context.stroke();
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          view.context.stroke();
          view.context.restore();
        }
      }
      view.context.restore();
    }
    /*
        draw_selection(view:CanvasView)
        {
        view.context.save();
        this.rotate_context(view);
            if(view.showhandels)
            {
                let s_x = this.screen_rect.position.x + view.offsetx;
                let s_y = this.screen_rect.position.y + view.offsety;
                let s_width = this.screen_rect.size.width;
                let s_height = this.screen_rect.size.heigth;

                view.context.lineWidth = 1.0;
                if(this.locked)
                    view.context.strokeStyle = '#f30b2b';
                else
                    view.context.strokeStyle = '#51c1fd';

                view.context.strokeRect(s_x, s_y,s_width, s_height);

                let point_1 = new Point((this.handles[1].rect.position.x+view.offsetx) + kHandleSize/2, this.handles[1].rect.position.y+view.offsety);
                let point_2 = new Point((this.handles[2].rect.position.x+view.offsetx) + kHandleSize/2, (this.handles[2].rect.position.y+view.offsety) + kHandleSize/2);
                if(!this.horizontal)
                {
                    point_1 = new Point((this.handles[1].rect.position.x+view.offsetx) + kHandleSize/2, this.handles[1].rect.position.y+view.offsety);
                    point_2 = new Point((this.handles[2].rect.position.x+view.offsetx) + kHandleSize/2, (this.handles[2].rect.position.y+view.offsety) + kHandleSize/2);
                }
                //let line = new Line(point_1, point_2);
                //line.draw(view);
            }


        }
        */
  };
  var PageElementLine = class _PageElementLine extends PageElement {
    constructor(id, type, native_rect, p1_native, p2_native, stroke_width) {
      super(id, type, native_rect);
      this.native_rect = native_rect;
      this.p1_native = p1_native;
      this.p2_native = p2_native;
      this.stroke_width = stroke_width;
      this.p1_screen = new Point(0, 0);
      this.p2_screen = new Point(0, 0);
      this.line_screen = new Line(this.p1_screen, this.p2_screen);
      let y = p1_native.y;
      let y1 = p2_native.y;
      if (y > y1) {
        let s = y1;
        y1 = y;
        y = s;
      }
      let x = p1_native.x;
      let x1 = p2_native.x;
      if (x > x1) {
        let s = x1;
        x1 = x;
        x = s;
      }
      let size = new Size(x1 - x, y1 - y);
      let p = new Point(x, y);
      this.native_rect = new Rect(p, size);
      this.native_border_size = stroke_width;
      let view = g_typograph_document.get_view();
      let p1_screen = this.native_point_to_screen_point(view, this.p1_native, false);
      let p2_screen = this.native_point_to_screen_point(view, this.p2_native, false);
      this.line_screen = new Line(p1_screen, p2_screen);
    }
    copy() {
      let c = new _PageElementLine(this.id, this.type, this.native_rect, this.p1_native, this.p2_native, this.stroke_width);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      super.copy_properties_to(e);
    }
    set_screen_rect(view) {
      super.set_screen_rect(view);
      this.p1_screen.x = this.screen_rect.position.x;
      this.p1_screen.y = this.screen_rect.position.y;
      this.p2_screen.x = this.p1_screen.x + this.screen_rect.size.width;
      this.p2_screen.y = this.p1_screen.y + this.screen_rect.size.height;
      this.line_screen.f.x = this.p1_screen.x;
      this.line_screen.f.y = this.p1_screen.y;
      this.line_screen.t.x = this.p2_screen.x;
      this.line_screen.t.y = this.p2_screen.y;
      this.p1_native = this.screen_point_to_native_point(view, this.p1_screen);
      this.p2_native = this.screen_point_to_native_point(view, this.p2_screen);
    }
    update_handles() {
      this.handles = [];
      let rect2 = this.screen_rect;
      let position = new Point(this.screen_rect.position.x, this.screen_rect.position.y);
      position.x = position.x - kHandleSize / 2;
      position.y = position.y - kHandleSize / 2;
      let size = new Size(kHandleSize, kHandleSize);
      let handle = new Handleselectionbox(new Rect(position, size), false, "t_l", rect2);
      this.handles.push(handle);
      position.x = this.screen_rect.position.x + this.screen_rect.size.width;
      position.y = this.screen_rect.position.y + this.screen_rect.size.height;
      position.x = position.x - kHandleSize / 2;
      position.y = position.y - kHandleSize / 2;
      handle = new Handleselectionbox(new Rect(position, size), false, "r_b", rect2);
      this.handles.push(handle);
    }
    move_to(offset_x, offset_y) {
      this.screen_rect.position.x += offset_x;
      this.screen_rect.position.y += offset_y;
      this.p1_screen.x = this.screen_rect.position.x;
      this.p1_screen.y = this.screen_rect.position.y;
      this.p2_screen.x = this.p1_screen.x + this.screen_rect.size.width;
      this.p2_screen.y = this.p1_screen.y + this.screen_rect.size.height;
      this.line_screen.f.x = this.p1_screen.x;
      this.line_screen.f.y = this.p1_screen.y;
      this.line_screen.t.x = this.p2_screen.x;
      this.line_screen.t.y = this.p2_screen.y;
      for (let i = 0; i < this.handles.length; i++) {
        let handle = this.handles[i];
        handle.rect.position.x += offset_x;
        handle.rect.position.y += offset_y;
      }
      this.rotation_cx += offset_x;
      this.rotation_cy += offset_y;
      let view = g_typograph_document.get_view();
      this.p1_native = this.screen_point_to_native_point(view, this.p1_screen);
      this.p2_native = this.screen_point_to_native_point(view, this.p2_screen);
      this.set_native_rect();
    }
    setAngle(angle) {
      let view = g_typograph_document.get_view();
      let length = this.getSize();
      this.p2_native.x = this.p1_native.x + Math.cos(Math.PI * angle / 180) * length;
      this.p2_native.y = this.p1_native.y + Math.sin(Math.PI * angle / 180) * length;
      this.native_rect.size.width = this.p2_native.x - this.p1_native.x;
      this.native_rect.size.height = this.p2_native.y - this.p1_native.y;
      this.p1_screen = this.native_point_to_screen_point(view, this.p1_native, false);
      this.p2_screen = this.native_point_to_screen_point(view, this.p1_native, false);
      this.line_screen.f.x = this.p1_screen.x;
      this.line_screen.f.y = this.p1_screen.y;
      this.line_screen.t.x = this.p2_screen.x;
      this.line_screen.t.y = this.p2_screen.y;
    }
    getAngle() {
      var dy = this.p2_native.y - this.p1_native.y;
      var dx = this.p2_native.x - this.p1_native.x;
      var theta = Math.atan2(dy, dx);
      theta *= 180 / Math.PI;
      return theta;
    }
    getSize() {
      return Math.hypot(this.p2_native.x - this.p1_native.x, this.p2_native.y - this.p1_native.y);
    }
    setSize(size) {
      let slope = (this.p2_native.y - this.p1_native.y) / (this.p2_native.x - this.p1_native.x);
      let r = Math.sqrt(1 + Math.pow(slope, 2));
      this.p2_native.x = this.p1_native.x + size / r;
      this.p2_native.y = this.p1_native.y + size * slope / r;
      this.native_rect.size.width = this.p2_native.x - this.p1_native.x;
      this.native_rect.size.height = this.p2_native.y - this.p1_native.y;
      let view = g_typograph_document.get_view();
      this.p1_screen = this.native_point_to_screen_point(view, this.p1_native, false);
      this.p2_screen = this.native_point_to_screen_point(view, this.p1_native, false);
      this.line_screen.f.x = this.p1_screen.x;
      this.line_screen.f.y = this.p1_screen.y;
      this.line_screen.t.x = this.p2_screen.x;
      this.line_screen.t.y = this.p2_screen.y;
    }
    resize(offsetx, offsety, handle, shift) {
      if (offsetx == 0 && offsety == 0)
        return;
      this.screen_rect.position.x = this.screen_saved_rect.position.x;
      this.screen_rect.position.y = this.screen_saved_rect.position.y;
      this.screen_rect.size.width = this.screen_saved_rect.size.width;
      this.screen_rect.size.height = this.screen_saved_rect.size.height;
      let ration_x = 1;
      let ration_y = 1;
      if (shift) {
        if (handle.type == "t_l" || handle.type == "r_b") {
          if (this.screen_saved_rect.size.width > this.screen_saved_rect.size.height) {
            ration_y = this.screen_saved_rect.size.height / this.screen_saved_rect.size.width;
            offsety = offsetx * ration_y;
          } else {
            ration_x = this.screen_saved_rect.size.width / this.screen_saved_rect.size.height;
            offsetx = offsety * ration_x;
          }
        }
      }
      if (handle.type == "t_l") {
        this.screen_rect.position.x += offsetx;
        this.screen_rect.position.y += offsety;
        this.screen_rect.size.width -= offsetx;
        this.screen_rect.size.height -= offsety;
      }
      if (handle.type == "r_b") {
        this.screen_rect.size.width += offsetx;
        this.screen_rect.size.height += offsety;
      }
      this.p1_screen = new Point(this.screen_rect.position.x, this.screen_rect.position.y);
      this.p2_screen = new Point(this.screen_rect.position.x + this.screen_rect.size.width, this.screen_rect.position.y + this.screen_rect.size.height);
      this.line_screen.f.x = this.p1_screen.x;
      this.line_screen.f.y = this.p1_screen.y;
      this.line_screen.t.x = this.p2_screen.x;
      this.line_screen.t.y = this.p2_screen.y;
      let view = g_typograph_document.get_view();
      this.p1_native = this.screen_point_to_native_point(view, this.p1_screen);
      this.p2_native = this.screen_point_to_native_point(view, this.p2_screen);
      this.update_handles();
      this.set_native_rect();
      handle.selected = true;
    }
    draw(view) {
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      if (this.get_stroke_style() != "none" && this.border_size > 0) {
        this.p1_screen = this.native_point_to_screen_point(view, this.p1_native);
        this.p2_screen = this.native_point_to_screen_point(view, this.p2_native);
        let x = this.p1_screen.x;
        let y = this.p1_screen.y;
        let x1 = this.p2_screen.x;
        let y1 = this.p2_screen.y;
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
        view.context.beginPath();
        view.context.moveTo(x, y);
        view.context.lineTo(x1, y1);
        if (this.opacity == 1) {
          view.context.stroke();
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          view.context.stroke();
          view.context.restore();
        }
      }
      view.context.restore();
    }
    draw_selection(view, focus = false) {
      focus = true;
      view.context.save();
      this.rotate_context(view);
      if (view.show_handels) {
        this.p1_screen = this.native_point_to_screen_point(view, this.p1_native);
        this.p2_screen = this.native_point_to_screen_point(view, this.p2_native);
        view.context.lineWidth = 1;
        if (this.locked)
          view.context.strokeStyle = "#f30b2b";
        else
          view.context.strokeStyle = "#51c1fd";
        view.context.beginPath();
        view.context.moveTo(this.p1_screen.x, this.p1_screen.y);
        view.context.lineTo(this.p2_screen.x, this.p2_screen.y);
        view.context.stroke();
        for (let i = 0; i < this.handles.length; i++) {
          let handle = this.handles[i];
          let h_x = handle.rect.position.x + view.offset_x;
          let h_y = handle.rect.position.y + view.offset_y;
          let h_width = handle.rect.size.width;
          let h_height = handle.rect.size.height;
          view.context.fillStyle = "rgba(255, 255, 255, 1.0)";
          view.context.fillRect(h_x, h_y, h_width, h_height);
          view.context.strokeRect(h_x, h_y, h_width, h_height);
        }
      }
      view.context.restore();
    }
  };
  var PageElementPath = class _PageElementPath extends PageElement {
    constructor(id, type, path) {
      super(id, type, path.getnativerect());
      this.path = path;
      this.save_path = this.path.copy();
    }
    copy() {
      let c = new _PageElementPath(this.id, this.type, this.path);
      this.copy_properties_to(c);
      return c;
    }
    copy_properties_to(e) {
      this.path = this.path.copy();
      this.save_path = this.save_path.copy();
      super.copy_properties_to(e);
    }
    update_screen_rect() {
      super.update_screen_rect();
      this.path = this.save_path.copy();
    }
    update_save_screen_rect() {
      super.update_save_screen_rect();
      this.save_path = this.path.copy();
    }
    update_native_rect(r) {
      let scale_x = r.size.width / this.native_rect.size.width;
      let scale_y = r.size.height / this.native_rect.size.height;
      super.update_native_rect(r);
      this.path = this.save_path.copy();
      this.path.move_to(-1 * this.screen_saved_rect.position.x, -1 * this.screen_saved_rect.position.y);
      this.path.scale(scale_x, scale_y);
      this.path.move_to(this.screen_rect.position.x, this.screen_rect.position.y);
      this.save_path = this.path.copy();
      this.screen_saved_rect = this.screen_rect.copy();
    }
    move_to(offset_x, offset_y) {
      super.move_to(offset_x, offset_y);
      this.path.move_to(offset_x, offset_y);
    }
    resize(offsetx, offsety, handle, shift) {
      super.resize(offsetx, offsety, handle, shift);
      if (offsetx == 0 && offsety == 0)
        return;
      let scale_x = this.screen_rect.size.width / this.screen_saved_rect.size.width;
      let scale_y = this.screen_rect.size.height / this.screen_saved_rect.size.height;
      this.path = this.save_path.copy();
      this.path.move_to(-1 * this.screen_saved_rect.position.x, -1 * this.screen_saved_rect.position.y);
      this.path.scale(scale_x, scale_y);
      this.path.move_to(this.screen_rect.position.x, this.screen_rect.position.y);
    }
    draw_selection(view, focus = false) {
      super.draw_selection(view, focus);
      view.context.save();
      this.rotate_context(view);
      this.path.color = "#51c1fd";
      this.path.size = 1;
      this.path.draw(view);
      view.context.restore();
    }
    draw(view) {
      let x = this.screen_rect.position.x + view.offset_x;
      let y = this.screen_rect.position.y + view.offset_y;
      let width = this.screen_rect.size.width;
      let height = this.screen_rect.size.height;
      view.context.save();
      view.context.globalCompositeOperation = this.blend_mode;
      this.rotate_context(view);
      this.path.buildpath(view);
      if (this.get_fill_style() != "none") {
        view.context.fillStyle = this.get_fill_style();
        if (this.opacity == 1) {
          if (this.shadow) {
            view.context.save();
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
            view.context.fill();
            view.context.restore();
          } else {
            view.context.fill();
          }
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          if (this.shadow) {
            view.context.shadowColor = this.get_shadow_color();
            view.context.shadowBlur = this.shadow_blur;
            view.context.shadowOffsetX = this.shadow_offset_x;
            view.context.shadowOffsetY = this.shadow_offset_y;
          }
          view.context.fill();
          view.context.restore();
        }
      }
      if (this.get_stroke_style() != "none" && this.border_size > 0) {
        view.context.strokeStyle = this.get_stroke_style();
        view.context.lineWidth = this.border_size;
        view.context.setLineDash(this.calc_stroke_dash_pattern(this.stroke_dash, this.border_size));
        if (this.opacity == 1) {
          view.context.stroke();
        } else {
          view.context.save();
          view.context.globalAlpha = this.opacity;
          view.context.stroke();
          view.context.restore();
        }
      }
      view.context.restore();
    }
  };
  var CanvasView = class {
    constructor(canvas_element) {
      this.canvas_element = canvas_element;
      this.context = this.canvas_element.getContext("2d");
      this.show_guids = true;
      this.show_handels = true;
      this.show_bleed = true;
      this.offset_x = 0;
      this.offset_y = 0;
      this.zoom = 1;
      this.hidpi = false;
      this.context.lineWidth = 0.5;
    }
    setupCanvas() {
      this.canvas_element.oncontextmenu = function() {
        return false;
      };
      if (window.devicePixelRatio == 1)
        return;
      let dpr = window.devicePixelRatio || 1;
      if (dpr != 1) {
        let rect2 = this.canvas_element.getBoundingClientRect();
        if (rect2.width == 0)
          rect2.width = this.canvas_element.width;
        if (rect2.height == 0)
          rect2.height = this.canvas_element.height;
        let oldwidth = rect2.width;
        let oldheight = rect2.height;
        this.canvas_element.width = oldwidth * dpr;
        this.canvas_element.height = oldheight * dpr;
        this.canvas_element.style.width = oldwidth + "px";
        this.canvas_element.style.height = oldheight + "px";
        this.context.scale(dpr, dpr);
        this.hidpi = true;
        this.context.imageSmoothingQuality = "high";
      }
    }
  };

  // src/selection.ts
  var DragSelection = class {
    constructor(x, y, x1, y1) {
      this.x = x;
      this.y = y;
      this.x1 = x1;
      this.y1 = y1;
      let p = new Point(this.x, this.x);
      let s = new Size(this.getW(), this.getH());
      this.rect = new Rect(p, s);
      this.active = false;
    }
    getRect() {
      this.rect.position.x = this.x;
      this.rect.position.y = this.y;
      this.rect.size.width = this.getW();
      this.rect.size.height = this.getH();
      return this.rect;
    }
    getSelection() {
      let sel = { x: this.x, y: this.y, x1: this.x1, y1: this.y1 };
      return sel;
    }
    empty() {
      if (this.getW() == 0 || this.getH() == 0)
        return true;
      return false;
    }
    getX() {
      return this.x;
    }
    getY() {
      return this.y;
    }
    getX1() {
      return this.x1;
    }
    getY1() {
      return this.y1;
    }
    getW() {
      return this.x1 - this.x;
    }
    getH() {
      return this.y1 - this.y;
    }
    setXY(in_x, in_y) {
      this.setX(in_x);
      this.setY(in_y);
    }
    setWH(in_w, in_h) {
      this.setW(in_w);
      this.setH(in_h);
    }
    setX(in_x) {
      this.x = in_x;
    }
    setX1(in_x) {
      this.x1 = in_x;
    }
    setY(in_y) {
      this.y = in_y;
    }
    setY1(in_y) {
      this.y1 = in_y;
    }
    setW(in_w) {
      this.setX1(this.x + in_w);
    }
    setH(in_h) {
      this.setY1(this.y + in_h);
    }
  };

  // src/quotes.ts
  var kURL = "quotes/quotes.json";
  var Quotes = class {
    constructor() {
      this._onload = this.onload.bind(this);
      this.xhr = new XMLHttpRequest();
      this.xhr.open("GET", kURL, false);
      this.xhr.onload = this._onload;
      this.xhr.send();
      this.quotedata = JSON.parse(this.xhr.response);
    }
    onload() {
    }
    getQuote(index) {
      return this.quotedata[index];
    }
    getRandomQuote() {
      let min = 0;
      let max = this.getNrOfQuotes() - 1;
      let index = Math.floor(Math.random() * (max - min + 1)) + min;
      if (this.quotedata[index].author == null)
        this.quotedata[index].author = "unkown";
      return this.quotedata[index];
    }
    getNrOfQuotes() {
      return this.quotedata.length;
    }
    save() {
      let json_string = JSON.stringify(this.quotedata);
      let formdata = new FormData();
      formdata.append("filepath", "quotes.json");
      formdata.append("data", json_string);
      let xhr = new XMLHttpRequest();
      xhr.open("POST", "save_page_json.php", false);
      xhr.send(formdata);
      this.savedone(xhr);
    }
    savedone(xhr) {
      if (xhr.status == 200) {
        alert("Quotes saved!");
      } else {
        alert("Quotes NOT saved!");
      }
    }
  };

  // src/animatedbar.ts
  var kAnimatedbarDefaultcolor = "#ddd";
  var kAnimatedthumbDefaultcolor = "#9a9fa7";
  var Animatedbar = class {
    constructor(type, id, width, height, speed) {
      this.type = type;
      this.id = id;
      this.width = width;
      this.height = height;
      this.speed = speed;
      this._animate = this.animate.bind(this);
      this._stop = this.stop.bind(this);
      this.id = "animatedbar_" + this.id;
      this.bar = null;
      this.thumb = null;
      this.stepsize = 2;
      this.thumb_width = this.width / 4;
      this.thumb_pos = 0;
    }
    getHTMLElement() {
      this.bar = document.createElement("div");
      this.bar.id = "bar_" + this.id;
      this.bar.style.position = "relative";
      this.bar.style.width = this.width + "px";
      this.bar.style.height = this.height + "px";
      this.bar.style.borderRadius = this.height / 2 + "px";
      this.bar.style.backgroundColor = kAnimatedbarDefaultcolor;
      this.bar.style.transition = "0.5s";
      this.bar.style.opacity = "1.0";
      this.bar.style.overflow = "hidden";
      this.thumb = document.createElement("div");
      this.thumb.id = "thumb_" + this.id;
      this.thumb.style.position = "absolute";
      this.thumb.style.width = this.thumb_width + "px";
      this.thumb.style.height = this.height + "px";
      this.thumb.style.borderRadius = this.height / 2 + "px";
      this.thumb.style.backgroundColor = kAnimatedthumbDefaultcolor;
      this.thumb.style.left = this.thumb_pos + "px";
      this.bar.appendChild(this.thumb);
      return this.bar;
    }
    start() {
      this.interval_id = setInterval(this._animate, this.speed);
    }
    animate() {
      if (this.thumb_pos < this.width) {
        this.thumb_pos += this.stepsize;
      } else {
        this.stepsize = -1 * this.stepsize;
        this.thumb_pos += this.stepsize;
      }
      if (this.thumb_pos > -1 * this.thumb_width) {
        this.thumb_pos += this.stepsize;
      } else {
        this.stepsize = -1 * this.stepsize;
        this.thumb_pos += this.stepsize;
      }
      this.thumb.style.left = this.thumb_pos + "px";
    }
    stop() {
      clearInterval(this.interval_id);
      this.bar.style.opacity = "0";
    }
  };

  // src/startupscreen.ts
  var gTimerID = 0;
  var Startupscreen = class {
    constructor(type, id, show_quotes = true) {
      this.type = type;
      this.id = id;
      this.show_quotes = show_quotes;
      this._finishedloading = this.finishedloading.bind(this);
      this._stop = this.stop.bind(this);
      this.id = "startupscreen_" + this.id;
      this.content = document.getElementById("quotecontainer");
      if (this.show_quotes) {
        this.quotes = new Quotes();
        this.quote = this.quotes.getRandomQuote();
        document.getElementById("quote_text").innerHTML = "&ldquo;" + this.quote.text + "&rdquo;";
        document.getElementById("quote_author").innerHTML = "Author: " + this.quote.author;
      }
      this.animatedbar = new Animatedbar("animatedbar", "0", 400, 6, 10);
      let animatedbar_element = this.animatedbar.getHTMLElement();
      document.getElementById("quotecontainer").appendChild(animatedbar_element);
      this.animatedbar.start();
    }
    finishedloading(message) {
      this.animatedbar.stop();
      if (this.show_quotes) {
        gTimerID = setTimeout(this._stop, 1e4);
        document.getElementById("continue").style.transition = "0.5s";
        document.getElementById("continue").style.opacity = "1.0";
        document.getElementById("startupscreen").style.cursor = "pointer";
        document.getElementById("startupscreen").addEventListener("click", this._stop);
      } else {
        this.stop();
      }
    }
    stop() {
      if (this.show_quotes)
        clearTimeout(gTimerID);
      document.getElementById("startupscreen").innerHTML = "";
      document.getElementById("startupscreen").style.opacity = "0";
      gTimerID = setTimeout(function() {
        document.getElementById("startupscreen").style.display = "none";
        clearTimeout(gTimerID);
      }, 1250);
      g_typograph_document.new_document();
      let event = new Event("typograph_ready");
      g_typograph_document.canvas_view.canvas_element.dispatchEvent(event);
    }
  };

  // src/fontmanager.ts
  var kDefaultFontData = '[{"index":0,"source":"google","active":false,"data":{"family":"Open Sans","variants":["300","regular","500","600","700","800","300italic","italic","500italic","600italic","700italic","800italic"],"subsets":["cyrillic","cyrillic-ext","greek","greek-ext","hebrew","latin","latin-ext","vietnamese"],"version":"v34","modification_date":"2022-09-22","files":{"300":"http://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf","500":"http://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf","600":"http://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf","700":"http://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf","800":"http://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf","regular":"http://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf","300italic":"http://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVcUwaERZjA.ttf","italic":"http://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVcUwaERZjA.ttf","500italic":"http://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVcUwaERZjA.ttf","600italic":"http://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVcUwaERZjA.ttf","700italic":"http://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVcUwaERZjA.ttf","800italic":"http://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVcUwaERZjA.ttf"},"category":"sans-serif","kind":"webfonts#webfont"}}]';
  var kAipkey = "AIzaSyBY68QJysFti0Wb7tU72wHmeBIoqCFk1fc";
  var kGoogle_url = "https://www.googleapis.com/webfonts/v1/webfonts?key=";
  var gNumberOfFontsLoaded = 0;
  var GoogleFont = class {
  };
  var Font = class {
    constructor() {
      this.index = 0;
      this.source = "google";
      this.active = false;
      this.data = new GoogleFont();
      this.data.variants = [];
      this.data.subsets = [];
      this.data.version = "";
      this.data.modification_date = "";
      this.data.files = null;
      this.data.category = "";
      this.data.kind = "";
    }
    getFamily() {
      let family = this.data.family;
      let index = 0;
      if (this.data.variants.indexOf("regular")) {
        index = this.data.variants.indexOf("regular");
      }
      let variant = this.data.variants[index];
      let style = variant;
      if (this.data.variants.length == 1) {
        family = family + " " + variant;
      } else {
      }
      return family;
    }
    getStyles() {
      return this.data.variants;
    }
    rename_modification_date() {
      let data = this.data;
      if (data.lastModified) {
        let ndata = { family: data.family, variants: data.variants, subsets: data.subsets, version: data.version, modification_date: data.lastModified, files: data.files, category: data.category, kind: data.kind };
        this.data = ndata;
      }
      return this;
    }
  };
  var FontFaceLoader = class {
    //private _onload = this.onload.bind(this);
    //private _onerror = this.onerror.bind(this);
    //private _readystatchange = this.readystatechange.bind(this);
    //private _progress = this.progress.bind(this);
    constructor(font3, callback_all) {
      this.font = font3;
      this.callback_all = callback_all;
    }
    async loadfont() {
      let name = this.font.data.family;
      let index = 0;
      let regular_done = false;
      for (let i = 0; i < this.font.data.variants.length; i++) {
        let index2 = i;
        let variant = this.font.data.variants[i];
        if (variant.match(/^\d/)) {
          if (regular_done) {
            continue;
          } else {
            index2 = this.font.data.variants.indexOf("regular");
            variant = "";
            regular_done = true;
          }
        }
        let url = this.font.data.files[this.font.data.variants[index2]];
        if (window.location.protocol.includes("https")) {
          if (!url.includes("https")) {
            url = url.replace("http", "https");
          }
        }
        let encoded_url = encodeURI(url);
        let request = new Request(encoded_url);
        let response = await fetch(request);
        let buffer = await response.arrayBuffer();
        let name_style = name;
        if (variant != "")
          name_style += " " + variant;
        let fontface = new FontFace(name_style, buffer);
        document.fonts.add(fontface);
      }
    }
    onload() {
    }
    onerror() {
    }
    progress(e) {
    }
    readystatechange() {
      if (this.xhr.readyState == 2 && this.xhr.status == 200) {
      }
      if (this.xhr.readyState == 3) {
      }
      if (this.xhr.readyState == 4) {
        let name = this.font.data.family;
        if (this.font.data.variants.length == 1) {
          name += " " + this.font.data.variants[0];
        }
        try {
          if (name != "") {
            let fontface = new FontFace(name, this.xhr.response);
            document.fonts.add(fontface);
          } else {
            console.log("name=''");
          }
        } catch (error) {
          console.log(error);
        }
        gNumberOfFontsLoaded--;
        if (gNumberOfFontsLoaded == 0) {
          if (this.callback_all) {
            this.callback_all("all fonts loaded");
          }
        }
      }
    }
  };
  var FontManager = class {
    constructor(callback) {
      this.callback = callback;
      this.fonts = [];
      this.fontstyles = [];
      this.fontloaders = [];
      this._onload = this.onload.bind(this);
      this._readystatchange = this.readystatechange.bind(this);
      this._progress = this.progress.bind(this);
      this._allfontsloaded = this.allfontsloaded.bind(this);
      this._allfontsadded = this.allfontsloaded.bind(this);
      this.fontlistHTML = "";
      this.loadfonts_from_fontdata();
    }
    async loadfonts_from_fontdata() {
      this.fontdata = [];
      {
        this.fontdata = JSON.parse(kDefaultFontData);
      }
      this.load_font_faces(this.fontdata);
    }
    add_fonts_from_fontdata(font_data) {
      for (let f = 0; f < font_data.length; f++) {
        if (font_data[f].data.family == "Open Sans")
          continue;
        let input_data = font_data[f].data;
        if (input_data.hasOwnProperty("lastModified")) {
          let data = {
            family: input_data.family,
            variants: input_data.variants,
            subsets: input_data.subsets,
            version: input_data.version,
            modification_date: (/* @__PURE__ */ new Date()).toISOString(),
            files: input_data.files,
            category: input_data.category,
            kind: input_data.kind
          };
          font_data[f].data = data;
          this.fontdata.push(font_data[f]);
        } else
          this.fontdata.push(font_data[f]);
      }
      this.load_font_faces(font_data, true);
    }
    async getGoolgeFonts() {
      let response = await fetch(kGoogle_url + kAipkey);
      this.fontdata = [];
      this.fontlist = await response.json();
      for (let i = 0; i < this.fontlist.items.length - 1; i++) {
        let font3 = new Font();
        this.index = i;
        font3.index = i;
        font3.source = "google";
        font3.active = false;
        font3.data = this.fontlist.items[this.index];
        this.fontdata.push(font3);
      }
      return response;
    }
    async load_font_faces(font_data, add = false) {
      let index = 0;
      let batchsize = 10;
      gNumberOfFontsLoaded = font_data.length;
      this.fontloaders = [];
      for (let i = 0; i < font_data.length; i++) {
        let font3 = new Font();
        this.index = i;
        font3.index = font_data[i].index;
        font3.source = font_data[i].source;
        font3.active = font_data[i].active;
        font3.data = font_data[i].data;
        {
        }
        let fontloader = new FontFaceLoader(font3, this._allfontsloaded);
        this.fonts.push(font3);
        this.fontloaders.push(fontloader);
        if ((i + 1) % batchsize == 0) {
          let result = await this.batchload(index, batchsize);
          index += batchsize;
          this.fontloaders = [];
        }
      }
      if (this.fontloaders.length > 0) {
        let result = await this.batchload(index, this.fontloaders.length);
      }
      if (!add) {
        this.allfontsloaded("defualt font loaded");
      } else {
        this.BuildFontListHTML("", false, false);
        g_typograph_document.document_manager.DocumentFontsLoaded();
      }
    }
    async batchload(index, batchsize) {
      for (let i = 0; i < batchsize; i++) {
        let fontfaceloader = this.fontloaders[i];
        if (fontfaceloader.font.active == true) {
          let result = await fontfaceloader.loadfont();
        }
      }
    }
    onload() {
      this.fontdata = [];
      this.fontlist = JSON.parse(this.xhr.response);
      let gfd = { family: "MyVivendaSymbol", variants: ["regular"], subsets: ["latin"], version: "v1", modification_date: "2021-10-01", files: { regular: "fonts/MyViVendaSymbolsRegular/MyViVendaSymbolsRegular.woff" }, category: "", kind: "woff" };
      this.fontlist.items.push(gfd);
      gNumberOfFontsLoaded = this.fontlist.items.length - 1;
      for (let i = 0; i < this.fontlist.items.length - 1; i++) {
        let font4 = new Font();
        this.index = i;
        font4.index = i;
        font4.source = "google";
        font4.active = false;
        font4.data = this.fontlist.items[this.index];
        this.fontdata.push(font4);
        let fontloader2 = new FontFaceLoader(font4, this._allfontsloaded);
      }
      let font3 = new Font();
      this.index = this.fontlist.items.length - 1;
      font3.index = this.index;
      font3.source = "local";
      font3.active = true;
      font3.data = this.fontlist.items[this.index];
      this.fontdata.push(font3);
      let fontloader = new FontFaceLoader(font3, this._allfontsloaded);
    }
    readystatechange() {
      if (this.xhr.readyState == 2 && this.xhr.status == 200) {
      }
      if (this.xhr.readyState == 3) {
      }
      if (this.xhr.readyState == 4) {
        let font3;
        font3.data = this.fontlist.items[0];
        let name = font3.data.family;
        let index = -1;
        if (font3.data.variants.length > 1) {
          index = font3.data.variants.indexOf("regular");
        }
        let variant = font3.data.variants[index];
        try {
          let fontface = new FontFace(name + " " + variant, this.xhr.response);
          document.fonts.add(fontface);
        } catch (error) {
          console.log(error);
        }
      }
    }
    progress(e) {
      let complete = e.loaded / e.total * 100;
    }
    /*batchload_usingfontdata()
    {
        let gBatchNumberOfFontLoaded = 0;
        for(let i=gStart; i<this.fontdata.length && gBatchNumberOfFontLoaded<gBatchSize; i++)
        {
            let font:Font = new Font();
            this.index = i;
            font.index = this.fontdata[i].index;
            font.source = this.fontdata[i].source;
            font.active = this.fontdata[i].active;
            font.data = this.fontdata[i].data;
            this.fontdata.push(font);
            let fontloader = new FontFaceLoader(font, this._allfontsloaded, this._batchloaded);
        }
    }*/
    /*batchloaded()
    {
        gStart == 500;
    }*/
    allfontsloaded(message) {
      this.BuildFontListHTML("", false, false);
      if (this.callback) {
        message += " and fontlistHTML is ready";
        this.callback(message);
      }
    }
    inspect(obj) {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          console.log(prop, obj[prop]);
        }
      }
    }
    getFontForFontName(name) {
      for (let i = 0; i < this.fontdata.length; i++) {
        let font3 = this.fontdata[i];
        if (font3.data.family === name)
          return font3;
      }
      return null;
    }
    getFontStyleForFontName(name) {
      for (let i = 0; i < this.fontstyles.length; i++) {
        let font_style_data = this.fontstyles[i];
        if (font_style_data.family === name)
          return font_style_data.style_string;
      }
      return "";
    }
    getNrOfFonts() {
      let n = this.fontdata.length;
      return n;
    }
    getActiveFonts() {
      let f = [];
      for (let i = 0; i < this.fontdata.length; i++) {
        let font3 = this.fontdata[i];
        if (font3.active)
          f.push(this.fonts[i]);
      }
      return f;
    }
    getUploadedFonts() {
      let f = [];
      for (let i = 0; i < this.fontdata.length; i++) {
        let font3 = this.fontdata[i];
        if (font3.source == "local")
          f.push(this.fonts[i]);
      }
      return f;
    }
    BuildFontListHTML(search, activeonly, uploadedonly) {
      let lowercase_search = search.toLocaleLowerCase();
      this.fontlistHTML = "";
      this.fontstyles = [];
      this.searchdata = [];
      let searchindex = 0;
      for (let i = 0; i < this.fontdata.length; i++) {
        let font3 = this.fontdata[i];
        let family = font3.data.family;
        let include = true;
        if (search != "") {
          let lowercase_family = family.toLowerCase();
          include = lowercase_family.includes(lowercase_search);
        }
        if (uploadedonly) {
          if (font3.source != "local")
            include = false;
        }
        if (activeonly) {
          if (!font3.active) {
            include = false;
          }
        }
        if (include) {
          this.searchdata.push(font3);
          let index = 0;
          let v = [];
          for (let j = 0; j < font3.data.variants.length; j++) {
            v.push(font3.data.variants[j].toLowerCase());
          }
          if (v.indexOf("regular") != -1) {
            index = v.indexOf("regular");
          }
          let variant = font3.data.variants[index];
          let style = "";
          let f = family + " " + variant;
          style = "width:85%; font-family: " + f + ";";
          let font_style_data = { family, style_string: f };
          this.fontstyles.push(font_style_data);
          this.fontlistHTML += "<div class='fontlist item' id='fontitem_" + font3.index.toFixed(0) + "'><div id='font_" + font3.index.toFixed(0) + "' style='" + style + "'>" + family + "</div></div>";
          searchindex++;
        }
      }
      return this.searchdata;
    }
    getFontListHTML() {
      return this.fontlistHTML;
    }
    saveFontData() {
      let json_string = JSON.stringify(this.fontdata);
      let formdata = new FormData();
      formdata.append("filepath", "../fontdata/fontdata001.json");
      formdata.append("data", json_string);
      let xhr = new XMLHttpRequest();
      xhr.open("POST", "php/save_page_json.php", false);
      xhr.send(formdata);
    }
    async addFont(extened_fontdata2) {
      this.fontloaders = [];
      let numbeoffonts = this.fontdata.length;
      gNumberOfFontsLoaded = extened_fontdata2.length;
      let batchsize = 10;
      for (let i = 0; i < extened_fontdata2.length; i++) {
        let data = extened_fontdata2[i];
        let fileobject = {};
        let id = data.metadata.fontSubfamily;
        let f = this.getFontForFontName(data.metadata.fontFamily);
        if (f) {
          if (f.data.variants.indexOf(data.metadata.fontSubfamily) != -1)
            continue;
          if (fileobject[id] == null) {
            fileobject[id] = "userdata/" + g_typograph_document.get_user_id() + "/fonts/" + data.fontfile.name;
          }
          let google_fontdata2 = {
            family: data.metadata.fontFamily,
            variants: [data.metadata.fontSubfamily],
            subsets: ["latin"],
            version: data.metadata.version,
            modification_date: "",
            files: fileobject,
            category: "",
            kind: "ttf"
          };
          let font3 = new Font();
          font3.index = this.index;
          font3.source = "local";
          font3.active = true;
          font3.data = google_fontdata2;
          let fontfaceloader = new FontFaceLoader(font3, this._allfontsadded);
          let result = await fontfaceloader.loadfont();
          f.data.variants.push(data.metadata.fontSubfamily);
          f.data.files[id] = "userdata/" + g_typograph_document.get_user_id() + "/fonts/" + data.fontfile.name;
          this.saveFontData();
          continue;
        } else {
          if (fileobject[id] == null) {
            fileobject[id] = "userdata/" + g_typograph_document.get_user_id() + "/fonts/" + data.fontfile.name;
          }
          let google_fontdata2 = {
            family: data.metadata.fontFamily,
            variants: [data.metadata.fontSubfamily],
            subsets: ["latin"],
            version: data.metadata.version,
            modification_date: "",
            files: fileobject,
            category: "",
            kind: "ttf"
          };
          this.index = numbeoffonts + i;
          let font3 = new Font();
          font3.index = this.index;
          font3.source = "local";
          font3.active = true;
          font3.data = google_fontdata2;
          let fontloader = new FontFaceLoader(font3, this._allfontsadded);
          this.fonts.push(font3);
          this.fontdata.push(font3);
          this.fontloaders.push(fontloader);
        }
        if ((i + 1) % batchsize == 0) {
          let result = await this.batchload(this.index, batchsize);
          this.index += batchsize;
          this.fontloaders = [];
        }
        if (this.fontloaders.length > 0) {
          let result = await this.batchload(this.index, this.fontloaders.length);
        }
        this.allfontsadded("all fonts added");
      }
    }
    allfontsadded(message) {
      this.BuildFontListHTML("", false, false);
      this.saveFontData();
    }
  };

  // src/documentmanager.ts
  var DocumentManager = class {
    constructor() {
      this.to_open_document_data = null;
      this._fonts_loaded = this.DocumentFontsLoaded.bind(this);
    }
    GetDocumentFileNames() {
      return [];
    }
    LoadDocuments() {
      this.documents = [];
      this.brandcolors = [];
    }
    LoadDocumentFirstPage(filename, index) {
    }
    OpenDocument(data) {
      this.to_open_document_data = data;
      if (data.version) {
        if (data.version == "0.0.1" || data.version == "1.0.0") {
          if (data.version == "0.0.1")
            g_typograph_document.set_assets_url("https://typograph.nl/");
          if (data.version == "1.0.0")
            g_typograph_document.set_assets_url("");
          if (data.hasOwnProperty("font_data")) {
            if (data.font_data.length > 0) {
              for (let f = 0; f < data.font_data.length; f++) {
                let font3 = data.font_data[f];
                if (font3.source == "local") {
                  for (let v = 0; v < font3.data.variants.length; v++) {
                    let v_name = font3.data.variants[v];
                    if (font3.data.files[v_name].indexOf(g_asset_url) == -1)
                      font3.data.files[v_name] = g_asset_url + font3.data.files[v_name];
                  }
                }
              }
              g_typograph_document.font_manager.add_fonts_from_fontdata(data.font_data);
            } else {
              this.DocumentFontsLoaded();
            }
          }
        }
      } else {
        this.DocumentFontsLoaded();
      }
    }
    LoadDocument(filename) {
      if (!g_typograph_document.setup.viewer_only) {
        let xhr = new XMLHttpRequest();
        let path = "userdata/" + g_typograph_document.setup.user_id + "/pages/" + filename;
        xhr.open("GET", path, false);
        xhr.send();
        let data = JSON.parse(xhr.response);
        this.OpenDocument(data);
      }
    }
    DocumentFontsLoaded() {
      let data = this.to_open_document_data;
      let pages = [];
      let page2 = null;
      if (data.hasOwnProperty("version")) {
        if (data.version == "0.0.1") {
          g_typograph_document.brand_colors.empty();
          if (data.brand_colors.length == 0)
            g_typograph_document.DefaultBrandColors();
          else {
            for (let c = 0; c < data.brand_colors.length; c++)
              g_typograph_document.brand_colors.addColor(data.brand_colors[c]);
          }
          for (let i = 0; i < data.pages.length; i++) {
            page2 = new Page(data.pages[i].id, data.pages[i].type, data.pages[i].width, data.pages[i].height);
            page2.initPage(data.pages[i]);
            pages.push(page2);
          }
        }
        if (data.version == "1.0.0") {
          if (data.brand_colors.colors.length == 0)
            g_typograph_document.DefaultBrandColors();
          else {
            for (let c = 0; c < data.brand_colors.colors.length; c++)
              g_typograph_document.brand_colors.addColor(data.brand_colors.colors[c]);
          }
          for (let i = 0; i < data.pages.length; i++) {
            page2 = new Page(data.pages[i].id, data.pages[i].type, data.pages[i].width, data.pages[i].height);
            if (data.version == "1.0.0")
              page2.init_page_new(data.pages[i]);
            else
              page2.initPage(data.pages[i]);
            pages.push(page2);
          }
        }
      } else {
        if (data.hasOwnProperty("brandcolors")) {
          g_typograph_document.brand_colors.empty();
          for (let c = 0; c < data.brandcolors.length; c++)
            g_typograph_document.brand_colors.addColor(data.brandcolors[c]);
        }
        if (data.pages.length == void 0) {
          page2 = new Page(data.pages.id, data.pages.tpye, data.pages.width, data.pages.height);
          page2.initPage(data.pages);
          pages.push(page2);
        } else {
          for (let i = 0; i < data.pages.length; i++) {
            page2 = new Page(data.pages[i].id, data.pages[i].type, data.pages[i].width, data.pages[i].height);
            page2.initPage(data.pages[i]);
            pages.push(page2);
          }
        }
      }
      g_typograph_document.pages = pages;
      page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let rich_text_elements = page2.get_elements_by_type("rich");
      for (let r = 0; r < rich_text_elements.length; r++) {
        let rich_text = rich_text_elements[r];
        let elementmanager = rich_text.rich_text.getElementManager();
        for (let el = 0; el < elementmanager.elements.length; el++)
          elementmanager.reword_element(elementmanager.elements[el]);
        rich_text.rich_text.getEditor().linalyze();
      }
      g_typograph_document.resize_main_canvas();
      g_typograph_document.canvas_view.canvas_element.dispatchEvent(new Event("typograph_document_opened"));
      if (g_images_loaded.length == 0)
        g_typograph_document.canvas_view.canvas_element.dispatchEvent(new Event("typograph_page_images_loaded"));
    }
    GetDocuments() {
      if (!g_typograph_document.setup.viewer_only) {
        this.LoadDocuments();
        return this.documents;
      }
      return [];
    }
    NewDocument(type = "single", width = 210, height = 297) {
      let pages = [];
      let filenames = this.GetDocumentFileNames();
      let uuid = g_typograph_document.generateUUID();
      uuid = "Untitled_" + uuid;
      let page2 = new Page(filenames.length - 1, type, width, height, uuid);
      pages.push(page2);
      return pages;
    }
    async RemoveDocument(id) {
      if (!g_typograph_document.setup.viewer_only) {
        let file = this.documentfiles[id];
        let document2 = this.documents[id];
        this.documentfiles.splice(id, 1);
        this.documents.splice(id, 1);
        let filepaths = [];
        let filepath = "userdata/" + g_typograph_document.setup.user_id + "/pages/" + file;
        filepaths.push(filepath);
        var json = JSON.stringify(filepaths);
        let response = await fetch(
          "php/handle_delete_files.php",
          {
            method: "POST",
            headers: { "Accept": "application/json", "Content-Type": "application/json" },
            body: json
          }
        );
        const content = await response.json();
      }
    }
  };

  // src/scrollbar.ts
  var kMinVisable = 200;
  var kMinThumbsize = 30;
  var Scrollbar = class {
    constructor(bar, thumb, container, contentsize, type, native_size) {
      this.bar = bar;
      this.thumb = thumb;
      this.container = container;
      this.contentsize = contentsize;
      this.type = type;
      this.native_size = native_size;
      this._mousemove = this.mousemove.bind(this);
      this._mouseup = this.mouseup.bind(this);
      this.bar.style.display = "block";
      this.bar.addEventListener("click", this.mousebarclick.bind(this));
      this.thumb.addEventListener("mousedown", this.mousedown.bind(this));
      this.t_rect = this.thumb.getBoundingClientRect();
      this.c_rect = this.container.getBoundingClientRect();
      this.top = this.t_rect.top - this.c_rect.top;
      this.left = this.t_rect.left - this.c_rect.left;
      let size = this.native_size * gMMtoScreen * g_typograph_document.canvas_view.zoom;
      this.resize(size);
    }
    resize(size) {
      if (g_typograph_document.pages.length === 0) {
        this.thumb.style.visibility = "hidden";
        return;
      } else {
        this.thumb.style.visibility = "";
      }
      let max_w = this.c_rect.width - kMinVisable;
      let max_h = this.c_rect.height - kMinVisable;
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let min_w = -page2.screen_size.width + kMinVisable;
      let min_h = -page2.screen_size.height + kMinVisable;
      this.contentsize = size * 2;
      this.c_rect = this.container.getBoundingClientRect();
      if (this.type == "v") {
        let s = this.c_rect.height / this.contentsize * 100;
        if (s > kMinThumbsize) {
          this.thumb.style.height = kMinThumbsize + "%";
        } else {
          this.thumb.style.height = s + "%";
        }
        this.t_rect = this.thumb.getBoundingClientRect();
        s = this.c_rect.height - this.t_rect.height;
        let s_h = s / (max_h + -min_h);
        let t = (g_typograph_document.canvas_view.offset_y + -min_h) * s_h;
        t = this.c_rect.height - (t + this.t_rect.height);
        this.top = t;
        this.thumb.style.top = this.top + "px";
        this.t_rect = this.thumb.getBoundingClientRect();
      } else {
        let s = this.c_rect.width / this.contentsize * 100;
        if (s > kMinThumbsize) {
          this.thumb.style.width = kMinThumbsize + "%";
        } else {
          this.thumb.style.width = s + "%";
        }
        this.t_rect = this.thumb.getBoundingClientRect();
        s = this.c_rect.width - this.t_rect.width;
        let s_w = s / (max_w + -min_w);
        let l = (g_typograph_document.canvas_view.offset_x + -min_w) * s_w;
        l = this.c_rect.width - (l + this.t_rect.width);
        this.left = l;
        this.thumb.style.left = this.left + "px";
        this.t_rect = this.thumb.getBoundingClientRect();
      }
    }
    mousebarclick() {
    }
    mousedown(e) {
      document.addEventListener("mousemove", this._mousemove);
      document.addEventListener("mouseup", this._mouseup);
      this.start_x = e.clientX;
      this.start_y = e.clientY;
      this.t_rect = this.thumb.getBoundingClientRect();
      this.c_rect = this.container.getBoundingClientRect();
    }
    mousemove(e) {
      let max_w = this.c_rect.width - kMinVisable;
      let max_h = this.c_rect.height - kMinVisable;
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let min_w = -page2.screen_size.width + kMinVisable;
      let min_h = -page2.screen_size.height + kMinVisable;
      let s = this.c_rect.width - this.t_rect.width;
      let s_w = (max_w + -min_w) / s;
      s = this.c_rect.height - this.t_rect.height;
      let s_h = (max_h + -min_h) / s;
      let offset_x = this.start_x - e.clientX;
      let offset_y = this.start_y - e.clientY;
      if (this.type == "v") {
        this.top = this.t_rect.top - this.c_rect.top - offset_y;
        if (this.top < 0) {
          this.top = 0;
        } else {
          if (this.top + this.t_rect.height > this.c_rect.height)
            this.top = this.c_rect.height - this.t_rect.height;
        }
        this.thumb.style.top = this.top + "px";
        g_typograph_document.canvas_view.offset_y = max_h - this.top * s_h;
        g_typograph_document.redraw();
      } else {
        this.left = this.t_rect.left - this.c_rect.left - offset_x;
        if (this.left < 0) {
          this.left = 0;
        } else {
          if (this.left + this.t_rect.width > this.c_rect.width)
            this.left = this.c_rect.width - this.t_rect.width;
        }
        this.thumb.style.left = this.left + "px";
        g_typograph_document.canvas_view.offset_x = max_w - this.left * s_w;
        g_typograph_document.redraw();
      }
    }
    updatescrollbar() {
      let max_w = this.c_rect.width - kMinVisable;
      let max_h = this.c_rect.height - kMinVisable;
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let min_w = -page2.screen_size.width + kMinVisable;
      let min_h = -page2.screen_size.height + kMinVisable;
      let s = this.c_rect.width - this.t_rect.width;
      let s_w = s / (max_w + -min_w);
      s = this.c_rect.height - this.t_rect.height;
      let s_h = s / (max_h + -min_h);
      if (this.type == "v") {
        let t = (g_typograph_document.canvas_view.offset_y + -min_h) * s_h;
        t = this.c_rect.height - (t + this.t_rect.height);
        this.top = t;
        this.thumb.style.top = this.top + "px";
      } else {
        let l = (g_typograph_document.canvas_view.offset_x + -min_w) * s_w;
        l = this.c_rect.width - (l + this.t_rect.width);
        this.left = l;
        this.thumb.style.left = this.left + "px";
      }
    }
    scroll(e) {
      e.preventDefault();
      let max_w = this.c_rect.width - kMinVisable;
      let max_h = this.c_rect.height - kMinVisable;
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let min_w = -page2.screen_size.width + kMinVisable;
      let min_h = -page2.screen_size.height + kMinVisable;
      let s = this.c_rect.width - this.t_rect.width;
      let s_w = s / (max_w + -min_w);
      s = this.c_rect.height - this.t_rect.height;
      let s_h = s / (max_h + -min_h);
      if (this.type == "v") {
        g_typograph_document.canvas_view.offset_y -= e.deltaY;
        if (g_typograph_document.canvas_view.offset_y > max_h)
          g_typograph_document.canvas_view.offset_y = max_h;
        if (g_typograph_document.canvas_view.offset_y < min_h)
          g_typograph_document.canvas_view.offset_y = min_h;
        let t = (g_typograph_document.canvas_view.offset_y + -min_h) * s_h;
        t = this.c_rect.height - (t + this.t_rect.height);
        this.top = t;
        this.thumb.style.top = this.top + "px";
        g_typograph_document.redraw();
      } else {
        g_typograph_document.canvas_view.offset_x -= e.deltaX;
        if (g_typograph_document.canvas_view.offset_x > max_w)
          g_typograph_document.canvas_view.offset_x = max_w;
        if (g_typograph_document.canvas_view.offset_x < min_w)
          g_typograph_document.canvas_view.offset_x = min_w;
        let l = (g_typograph_document.canvas_view.offset_x + -min_w) * s_w;
        l = this.c_rect.width - (l + this.t_rect.width);
        this.left = l;
        this.thumb.style.left = this.left + "px";
        g_typograph_document.redraw();
      }
    }
    mouseup() {
      document.removeEventListener("mouseup", this._mouseup);
      document.removeEventListener("mousemove", this._mousemove);
    }
  };

  // src/grab.ts
  var kMinVisable2 = 200;
  var kMaxPastBoard = 20;
  var Grab = class {
    constructor(scrollbars, max_past_board = true) {
      this.scrollbars = scrollbars;
      this.max_past_board = max_past_board;
      this._mouseMove = this.mouseMove.bind(this);
      this._mouseUp = this.mouseUp.bind(this);
      this.container = document.getElementById("typograph_viewer");
    }
    stop() {
      if (this.active) {
        this.mouseUp(null);
      }
    }
    mouseDown(point, x, y) {
      this.clickX = x;
      this.clickY = y;
      this.offset_x = g_typograph_document.canvas_view.offset_x;
      this.offset_y = g_typograph_document.canvas_view.offset_y;
      g_typograph_document.canvas_view.canvas_element.style.cursor = "grabbing";
      g_typograph_document.canvas_view.canvas_element.addEventListener("mouseup", this._mouseUp);
      g_typograph_document.canvas_view.canvas_element.addEventListener("mousemove", this._mouseMove);
      this.active = true;
    }
    mouseMove(e) {
      let c_rect = this.container.getBoundingClientRect();
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let screen_size = new Size(page2.screen_size.width, page2.screen_size.height);
      let bleed_size = page2.bleed * g_typograph_document.canvas_view.zoom * gMMtoScreen;
      screen_size.width += bleed_size;
      screen_size.height += bleed_size;
      if (this.max_past_board) {
        if (c_rect.width > page2.screen_size.width) {
          let offset_x = this.offset_x - (this.clickX - e.clientX);
          if (offset_x < kMaxPastBoard + bleed_size)
            offset_x = kMaxPastBoard + bleed_size;
          if (offset_x + screen_size.width > c_rect.width - kMaxPastBoard)
            offset_x = c_rect.width - kMaxPastBoard - screen_size.width;
          g_typograph_document.canvas_view.offset_x = offset_x;
        } else {
          let offset_x = this.offset_x - (this.clickX - e.clientX);
          let x1 = offset_x + screen_size.width;
          if (x1 < c_rect.width - kMaxPastBoard)
            offset_x = c_rect.width - kMaxPastBoard - screen_size.width;
          else {
            if (offset_x > kMaxPastBoard + bleed_size)
              offset_x = kMaxPastBoard + bleed_size;
          }
          g_typograph_document.canvas_view.offset_x = offset_x;
        }
        if (c_rect.height > page2.screen_size.height) {
          let offset_y = this.offset_y - (this.clickY - e.clientY);
          if (offset_y < kMaxPastBoard)
            offset_y = kMaxPastBoard;
          if (offset_y + page2.screen_size.height > c_rect.height - kMaxPastBoard)
            offset_y = c_rect.height - kMaxPastBoard - page2.screen_size.height;
          g_typograph_document.canvas_view.offset_y = offset_y;
        } else {
          let offset_y = this.offset_y - (this.clickY - e.clientY);
          let y1 = offset_y + screen_size.height;
          if (y1 < c_rect.height - kMaxPastBoard)
            offset_y = c_rect.height - kMaxPastBoard - screen_size.height;
          else {
            if (offset_y > kMaxPastBoard + bleed_size)
              offset_y = kMaxPastBoard + bleed_size;
          }
          g_typograph_document.canvas_view.offset_y = offset_y;
        }
        this.updatescrollbars();
      } else {
        let max_w = c_rect.width - kMinVisable2;
        let max_h = c_rect.height - kMinVisable2;
        let min_w = -page2.screen_size.width + kMinVisable2;
        let min_h = -page2.screen_size.height + kMinVisable2;
        g_typograph_document.canvas_view.offset_y = this.offset_y - (this.clickY - e.clientY);
        if (g_typograph_document.canvas_view.offset_y > max_h)
          g_typograph_document.canvas_view.offset_y = max_h;
        if (g_typograph_document.canvas_view.offset_y < min_h)
          g_typograph_document.canvas_view.offset_y = min_h;
        g_typograph_document.canvas_view.offset_x = this.offset_x - (this.clickX - e.clientX);
        if (g_typograph_document.canvas_view.offset_x > max_w)
          g_typograph_document.canvas_view.offset_x = max_w;
        if (g_typograph_document.canvas_view.offset_x < min_w)
          g_typograph_document.canvas_view.offset_x = min_w;
        this.updatescrollbars();
      }
      g_typograph_document.redraw();
    }
    mouseUp(e) {
      g_typograph_document.canvas_view.canvas_element.style.cursor = "grab";
      g_typograph_document.canvas_view.canvas_element.removeEventListener("mousemove", this._mouseMove);
      g_typograph_document.canvas_view.canvas_element.removeEventListener("mouseup", this._mouseUp);
      if (e != null) {
        this.moved_x = this.clickX - e.clientX;
        this.moved_y = this.clickY - e.clientY;
      }
      g_typograph_document.canvas_view.canvas_element.dispatchEvent(new Event("grab_end"));
      this.active = false;
    }
    updatescrollbars() {
      for (let s = 0; s < this.scrollbars.length; s++) {
        this.scrollbars[s].updatescrollbar();
      }
    }
  };

  // src/dialogmanager.ts
  var DialogManger = class {
    constructor(parent_id) {
      this.parent_id = parent_id;
      this.dialog_type = "";
      this._cancel = this.cancel.bind(this);
      this._click_content = this.click_content.bind(this);
      this._ok = this.ok.bind(this);
      document.body;
      this.typograph_dialog_layer = document.createElement("div");
      this.typograph_dialog_layer.id = "typograph_dialog_layer";
      this.typograph_dialog_layer.style.position = "absolute";
      this.typograph_dialog_layer.style.left = "0px";
      this.typograph_dialog_layer.style.top = "0px";
      this.typograph_dialog_layer.style.right = "0px";
      this.typograph_dialog_layer.style.bottom = "0px";
      this.typograph_dialog_layer.style.backgroundColor = "rgba(0,0,0,0.2)";
      this.typograph_dialog_layer.style.backdropFilter = "blur(2px)";
      let parent = document.getElementById(this.parent_id);
      parent.appendChild(this.typograph_dialog_layer);
      this.typograph_dialog_layer = document.getElementById("typograph_dialog_layer");
      this.typograph_dialog_layer.style.zIndex = "999";
      this.typograph_dialog_layer.style.display = "none";
    }
    /**
     * Clear "typograph_dialog_layer"
     * @returns true if succesfull
     *
     * if false there is no typograph_dialog_layer any more....
     */
    clear() {
      if (this.typograph_dialog_layer != null) {
        this.typograph_dialog_layer.innerHTML = "";
        return true;
      }
      return false;
    }
    /**
     * Add a dialog to  typograph_dialog_layer
     * @param type 'modal' or 'movable_modal" default is 'modal'
     * @param id id for the dialog_content default is 'dialog'
     * @param width default is 80% typograph_dialog_layer width or give a number greater then 1 in pixels so 600 for 600px
     * @param height default is 80% of typograph_dialog_layer height a number greater then 1 in pixels so 400 for 400px
     */
    add_dialog(dialog = null, type = "modal", id = "dialog", width = 0.8, height = 0.8) {
      this.dialog = dialog;
      this.dialog_content = document.createElement("div");
      this.dialog_content.id = id;
      this.dialog_content.style.position = "absolute";
      if (width < 1)
        this.dialog_content.style.width = width * 100 + "%";
      else
        this.dialog_content.style.width = width + "px";
      if (height < 1)
        this.dialog_content.style.height = height * 100 + "%";
      else
        this.dialog_content.style.height = height + "px";
      let rect2 = document.getElementById(this.parent_id).getBoundingClientRect();
      let x = 0;
      let y = 0;
      let w = rect2.width;
      let h = rect2.height;
      if (width < 1)
        x = (w - w * width) / 2;
      else
        x = (w - width) / 2;
      if (height < 1)
        y = (h - h * height) / 2;
      else
        y = (h - (height + 40)) / 2;
      this.dialog_content.style.left = x + "px";
      this.dialog_content.style.top = y + "px";
      this.dialog_content.style.borderRadius = "5px";
      this.dialog_content.style.backgroundColor = "white";
      this.dialog_content.style.boxShadow = "0px 0px 20px 0px black";
      this.dialog_content.innerHTML = "";
      let ok_button_html = "<input type='button' id='ok_button' value='OK'>";
      this.dialog_content.innerHTML += ok_button_html;
      let cancel_button_html = "<input type='button' id='cancel_button' value='Cancel'>";
      this.dialog_content.innerHTML += cancel_button_html;
      let content = document.createElement("div");
      content.id = id + "_content";
      content.style.position = "absolute";
      content.style.left = "20px";
      content.style.right = "20px";
      content.style.top = "30px";
      content.style.bottom = "50px";
      content.style.backgroundColor = "white";
      this.dialog_content.appendChild(content);
      this.typograph_dialog_layer.appendChild(this.dialog_content);
      this.dialog_content.addEventListener("click", this._click_content);
      let ok_button = document.getElementById("ok_button");
      this.set_button_style("ok", ok_button);
      let cancel_button = document.getElementById("cancel_button");
      this.set_button_style("cancel", cancel_button);
      ok_button.addEventListener("click", this._ok);
      cancel_button.addEventListener("click", this._cancel);
      return document.getElementById(content.id);
    }
    click_content(e) {
      e.stopPropagation();
    }
    ok(e) {
      e.stopPropagation();
      this.dialog.do_ok();
      this.hide();
    }
    cancel(e) {
      e.stopPropagation();
      this.dialog.do_cancel();
      this.hide();
    }
    show() {
      this.dialog_active = true;
      this.typograph_dialog_layer.style.display = "block";
      g_typograph_document.stop_scroll();
      g_typograph_document.redraw();
    }
    hide() {
      this.dialog_active = false;
      this.typograph_dialog_layer.style.display = "none";
      g_typograph_document.start_scroll();
    }
    remove() {
      this.dialog_active = false;
      this.typograph_dialog_layer.remove();
      this.typograph_dialog_layer = null;
    }
    /**
     * Set the button style on input button...
     * @param type 'ok' or 'cancel' default 'ok'
     * @param button HTMLElement
     */
    set_button_style(type = "ok", button) {
      button.style.position = "absolute";
      button.style.right = "20px";
      button.style.bottom = "10px";
      button.style.backgroundColor = kDefaultActiveColor;
      button.style.color = "white";
      button.style.fontFamily = kDefaultFontFamaliy;
      button.style.textAlign = "center";
      button.style.paddingLeft = "10px";
      button.style.paddingRight = "10px";
      button.style.paddingTop = "5px";
      button.style.paddingBottom = "5px";
      button.style.textDecoration = "none";
      button.style.fontSize = "16px";
      button.style.cursor = "pointer";
      button.style.borderWidth = "0px";
      button.style.borderRadius = kDefaultCornerRadius + "px";
      button.style.minWidth = "100px";
      if (type == "cancel") {
        button.style.backgroundColor = "white";
        button.style.color = "black";
        button.style.borderWidth = "1px";
        button.style.right = "140px";
      }
    }
  };

  // src/dmdialog.ts
  var DMDialog = class {
    constructor(type, id, dialog_manager) {
      this.type = type;
      this.id = id;
      this.dialog_manager = dialog_manager;
    }
    do_ok() {
    }
    do_cancel() {
    }
  };

  // src/texteditdialog.ts
  var TextEditDialog = class extends DMDialog {
    constructor(type, id, dialog_manager, page_element_rich_text, font3, style_string, zoom = 1) {
      super(type, id, dialog_manager);
      this.type = type;
      this.id = id;
      this.dialog_manager = dialog_manager;
      this.page_element_rich_text = page_element_rich_text;
      this.font = font3;
      this.style_string = style_string;
      this.zoom = zoom;
      if (this.dialog_manager.clear()) {
        this.page_element_rich_text.rich_text.editor.selectall();
        let text = this.page_element_rich_text.rich_text.editor.export("text", true);
        this.dialog_content = this.dialog_manager.add_dialog(this);
        let textarea = "<div><textarea rows='20' cols='100' id='text_edit' style='line-height:120%; color:black ; position: absolute; left:0px; top:0px; right:0px; bottom:0px;'></textarea></div>";
        this.dialog_content.innerHTML = textarea;
        let element3 = document.getElementById("text_edit");
        element3.style.fontFamily = this.style_string;
        if (this.font.size * this.zoom > 12)
          element3.style.fontSize = this.font.size * this.zoom + "pt";
        else
          element3.style.fontSize = "12pt";
        element3.style.borderWidth = "0px";
        this.text_edit = element3;
        this.text_edit.value = text;
        this.dialog_manager.show();
      }
    }
    do_ok() {
      let text = this.text_edit.value;
      this.page_element_rich_text.rich_text.editor.textInput(text);
      this.page_element_rich_text.rich_text.setFont(this.font);
    }
    do_cancel() {
    }
  };

  // src/autolayoutdialog.ts
  var AutoLayoutDialog = class extends DMDialog {
    constructor(type, id, dialog_manager, autolayout = null, clip_index = 0) {
      super(type, id, dialog_manager);
      this.type = type;
      this.id = id;
      this.dialog_manager = dialog_manager;
      this.autolayout = autolayout;
      this.clip_index = clip_index;
      this.controls = [];
      if (this.dialog_manager.clear()) {
        this.dialog_content = this.dialog_manager.add_dialog(this, "modal", "dialog", 0.6, 0.6);
        this.dialog_content.style.overflowY = "scroll";
        this.local_dialog_content = document.createElement("div");
        this.local_dialog_content.style.display = "flex";
        this.local_dialog_content.style.flexDirection = "column";
        this.dialog_content.appendChild(this.local_dialog_content);
        let clip = this.autolayout.clips[this.clip_index];
        this.set_content(clip);
        this.dialog_manager.show();
      }
    }
    set_content(group) {
      let rich_text_elements = [];
      let image_elements = [];
      if (group.page_elements.length > 1) {
        for (let el = 0; el < group.page_elements.length; el++) {
          let element3 = group.page_elements[el];
          if (element3.type == "rich")
            rich_text_elements.push(element3);
          if (element3.type == "image")
            image_elements.push(element3);
        }
      }
      for (let el = 0; el < rich_text_elements.length; el++)
        this.add_rich_text(rich_text_elements[el]);
      for (let el = 0; el < image_elements.length; el++)
        this.add_image(image_elements[el]);
    }
    add_rich_text(element3) {
      let text = element3.rich_text.editor.export("text", true);
      let lines = text.split(/\r\n|\r|\n/).length;
      let id = "control_" + this.controls.length;
      let name = element3.name;
      name = name.split("_")[0];
      name = name.charAt(0).toUpperCase() + name.slice(1);
      name = `<div style='font-family: Arial; font-size: 16px; font-weight: bold;'>${name}</div>`;
      let textarea = `<textarea rows='2' cols='100' id=${id} name=${id} style='font-family:"Source Sans Pro", Arial; font-size: 14px; line-height:120%; color:black; min-height: 20px; height: ${20 * lines}px; margin-bottom: 5px; overflow-y: scroll; resize: vertical;'>${text}</textarea>`;
      this.local_dialog_content.innerHTML += name;
      this.local_dialog_content.innerHTML += textarea;
      this.controls.push({ id, element: element3 });
    }
    add_image(element3) {
    }
    do_ok() {
      for (let c = 0; c < this.controls.length; c++) {
        let control = this.controls[c];
        if (control.element.type == "rich") {
          let input = document.getElementById(control.id);
          let text = input.value;
          text = this.check_for_prijs(text, control.element);
          let rich_text = control.element;
          let font3 = rich_text.rich_text.editor.elementmanager.elements[0].font;
          rich_text.default_font = font3;
          rich_text.rich_text.editor.selectall();
          rich_text.rich_text.editor.textInput(text);
          rich_text.rich_text.setFont(font3);
        }
      }
      g_typograph_document.redraw();
    }
    do_cancel() {
    }
    check_for_prijs(text_in, element3) {
      let name = element3.name;
      name = name.toLocaleLowerCase();
      if (name.indexOf("prijs") == 0) {
        text_in = text_in.replace(".", ",");
        if (text_in.indexOf(",") == -1)
          text_in += ",00";
        if (text_in.indexOf(",") == 0)
          text_in = 0 + text_in;
        let decimals = text_in.length - (text_in.indexOf(",") + 1);
        if (decimals < 2)
          text_in += "0";
        if (decimals > 2)
          text_in = text_in.substring(0, text_in.indexOf(",") + 3);
      }
      return text_in;
    }
  };

  // src/mousemanagers.ts
  var gRichTextEditor = null;
  var gPasteboard = [];
  var gCurrentFocus = false;
  var DocumentMouseManager = class {
    constructor() {
      this._mouse_move = this.mouse_move.bind(this);
      this._mouse_down = this.mouse_down.bind(this);
      this._rich_text_editor_mouse_down = this.rich_text_editor_mouse_down.bind(this);
      this._rich_text_editor_mouse_move = this.rich_text_editor_mouse_move.bind(this);
      this._rich_text_editor_mouse_up = this.rich_text_editor_mouse_up.bind(this);
      this._grab_end = this.grab_end.bind(this);
      this.mouse_down_edit = false;
      this.active_element = null;
      g_typograph_document.canvas_view.canvas_element.addEventListener("mousemove", this._mouse_move);
      g_typograph_document.canvas_view.canvas_element.addEventListener("mousedown", this._mouse_down);
    }
    start_mouse_tracking() {
      g_typograph_document.canvas_view.canvas_element.addEventListener("mousemove", this._mouse_move);
    }
    stop_mouse_tracking() {
      g_typograph_document.canvas_view.canvas_element.removeEventListener("mousemove", this._mouse_move);
    }
    // start_mouse_controls_tracking()
    // {
    //     g_typograph_document.canvas_view.canvas_element.addEventListener('mousemove', this._mouse_move_controls);
    // }
    // stop_mouse_controls_tracking()
    // {
    //     g_typograph_document.canvas_view.canvas_element.removeEventListener('mousemove', this._mouse_move_controls);
    // }
    mouse_move(e) {
      let offsetPoint = new Point(e.offsetX, e.offsetY);
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let element3 = page2.is_point_in_element(g_typograph_document.canvas_view, offsetPoint);
      if (element3)
        element3 = this.check(element3);
      if (element3) {
        page2.deselectAllElements();
        element3.selected = true;
        this.active_element = element3;
        if (element3.type == "group" && element3.auto_layout) {
          let point = new Point(e.clientX, e.clientY);
          let domrect = g_typograph_document.canvas_view.canvas_element.getBoundingClientRect();
          point.x = point.x - (domrect.x + g_typograph_document.canvas_view.offset_x);
          point.y = point.y - (domrect.y + g_typograph_document.canvas_view.offset_y);
          g_typograph_document.canvas_view.canvas_element.style.cursor = "default";
          let r = this.point_in_auto_layout(point);
          if (r >= 0) {
            if (r == 0) {
              if (element3.auto_layout.can_add(element3)) {
                g_typograph_document.canvas_view.canvas_element.style.cursor = "pointer";
              } else {
                g_typograph_document.canvas_view.canvas_element.style.cursor = "default";
              }
            } else
              g_typograph_document.canvas_view.canvas_element.style.cursor = "pointer";
          }
          r = this.point_in_auto_layout_clip(point);
          if (r >= 0)
            g_typograph_document.canvas_view.canvas_element.style.cursor = "pointer";
        }
        if (element3.type == "rich" && element3.locked != true) {
          g_typograph_document.canvas_view.canvas_element.style.cursor = "text";
        }
      } else {
        this.active_element = null;
        g_typograph_document.canvas_view.canvas_element.style.cursor = "grab";
        page2.deselectAllElements();
      }
      g_typograph_document.redraw();
    }
    mouse_down(e) {
      let offsetPoint = new Point(e.offsetX, e.offsetY);
      if (this.active_element) {
        if (this.active_element.type == "group" && this.active_element.auto_layout) {
          let point = new Point(e.clientX, e.clientY);
          let domrect = g_typograph_document.canvas_view.canvas_element.getBoundingClientRect();
          point.x = point.x - (domrect.x + g_typograph_document.canvas_view.offset_x);
          point.y = point.y - (domrect.y + g_typograph_document.canvas_view.offset_y);
          let r = this.point_in_auto_layout(point);
          if (r == 0) {
            let group = this.active_element;
            if (group.auto_layout.can_add(group)) {
              if (group.auto_layout.templates.length == 1) {
                group.add_clip(0);
                g_typograph_document.redraw();
              } else {
                g_typograph_document.do_select_template(group);
              }
            }
            return;
          }
          if (r > 0) {
            let group = this.active_element;
            group.auto_layout.remove_clip(group, r - 1);
            g_typograph_document.redraw();
            return;
          }
          if (e.detail > 1) {
            let index = this.point_in_auto_layout_clip(point);
            if (index >= 0) {
              let dialog = new AutoLayoutDialog("autolayout", "0", g_typograph_document.dialog_manager, this.active_element.auto_layout, index);
              g_typograph_document.grab.stop();
            }
            return;
          }
        }
      }
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      let element3 = page2.is_point_in_element(g_typograph_document.canvas_view, offsetPoint);
      if (element3) {
        if (element3.type == "rich" && element3.locked != true) {
          page2.deselectAllElements();
          this.active_element = element3;
          element3.selected = true;
          if (e.detail > 1) {
            this.dbclick(e, element3);
          } else {
            g_typograph_document.redraw();
          }
          return;
        }
        if (element3.type == "group" && element3.auto_layout != null && element3.locked != true) {
          page2.deselectAllElements();
          this.active_element = element3;
          element3.selected = true;
          if (e.detail > 1) {
            this.dbclick(e, element3);
          } else {
            g_typograph_document.redraw();
          }
          return;
        }
        if (gCurrentFocus) {
          this.exit_text_edit();
          this.start_mouse_tracking();
        }
        page2.deselectAllElements();
        let point = new Point(e.clientX, e.clientY);
        g_typograph_document.canvas_view.canvas_element.addEventListener("grab_end", this._grab_end);
        g_typograph_document.canvas_view.canvas_element.removeEventListener("mousemove", this._mouse_move);
        g_typograph_document.canvas_view.canvas_element.style.cursor = "grab";
        g_typograph_document.grab.mouseDown(point, e.clientX, e.clientY);
        g_typograph_document.redraw();
      } else {
        if (gCurrentFocus) {
          this.exit_text_edit();
        } else {
          let point = new Point(e.clientX, e.clientY);
          g_typograph_document.canvas_view.canvas_element.addEventListener("grab_end", this._grab_end);
          this.stop_mouse_tracking();
          g_typograph_document.canvas_view.canvas_element.style.cursor = "grab";
          g_typograph_document.grab.mouseDown(point, e.clientX, e.clientY);
        }
      }
    }
    dbclick(e, element3, nr_of_clicks = 2) {
      if (element3.type == "rich" && !element3.locked) {
        let richttext_element = element3;
        let style_string = g_typograph_document.font_manager.getFontStyleForFontName(richttext_element.default_font.name);
        let zoom = g_typograph_document.get_zoom();
        let text_edit_dialog = new TextEditDialog("text_edit", element3.uuid, g_typograph_document.dialog_manager, richttext_element, richttext_element.default_font, style_string, zoom);
        g_typograph_document.grab.stop();
      }
      if (element3.type == "group" && !element3.locked && element3.auto_layout != null) {
      }
    }
    check(element3) {
      if (element3.type == "rich")
        return element3;
      if (element3.type == "group" && element3.auto_layout)
        return element3;
      return null;
    }
    rich_text_editor_mouse_down(event, focus = true) {
      let el = this.active_element;
      el.mouseDown(event);
      if (gCurrentFocus) {
        if (event.detail == 1) {
          let pos = el.getMousePos(event);
          if (el.inside(pos)) {
            pos = el.getTextPos(event);
            el.rich_text.mouseDown({ x: pos.x, y: pos.y });
            event.preventDefault();
            this.mouse_down_edit = true;
          }
        }
        if (event.detail > 1) {
          let pos = el.getMousePos(event);
          pos = el.getTextPos(event);
          {
            let wloc = el.rich_text.editor.getWordLocationForMousePos({ x: pos.x, y: pos.y });
            el.rich_text.editor.selectWord(wloc);
          }
        }
        if (event.detail > 2) {
          el.rich_text.editor.selectall();
        }
      }
    }
    rich_text_editor_mouse_move(event) {
      if (this.mouse_down_edit) {
        let el = this.active_element;
        el.mouseMove(event);
      }
    }
    rich_text_editor_mouse_up(event) {
      this.mouse_down_edit = false;
      let el = this.active_element;
      let pos = el.getMousePos(event);
      if (!el.inside(pos)) {
        g_typograph_document.canvas_view.canvas_element.style.cursor = "grab";
      }
    }
    exit_text_edit() {
      gCurrentFocus = false;
      gRichTextEditor.setFocus(false);
      gRichTextEditor = null;
      this.active_element.selected = false;
      document.removeEventListener("mousedown", this._rich_text_editor_mouse_down);
      document.removeEventListener("mousemove", this._rich_text_editor_mouse_move);
      document.removeEventListener("mouseup", this._rich_text_editor_mouse_up);
      this.start_mouse_tracking();
      g_typograph_document.redraw();
    }
    do_grab() {
    }
    grab_end() {
      g_typograph_document.canvas_view.canvas_element.removeEventListener("grab_end", this._grab_end);
      let offset_x = g_typograph_document.grab.moved_x;
      let offset_y = g_typograph_document.grab.moved_y;
      if (offset_x == 0 && offset_y == 0) {
        let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
        page2.deselectAllElements();
        this.active_element = null;
      }
      this.start_mouse_tracking();
    }
    move_selection() {
    }
    async copy_text() {
      if (this.active_element) {
        let el = this.active_element;
        let text = el.rich_text.editor.export("text", true);
        if (text) {
          let result = await navigator.clipboard.writeText(text);
        }
      }
    }
    async paste_text() {
      if (this.active_element) {
        let el = this.active_element;
        el.rich_text.textInput(await navigator.clipboard.readText());
        g_typograph_document.redraw();
      }
    }
    select_all_text() {
      if (this.active_element) {
        let el = this.active_element;
        el.rich_text.editor.selectall();
      }
    }
    do_copy() {
      let selected_items = g_typograph_document.pages[g_typograph_document.active_page_index].selectedElements();
      gPasteboard = [];
      for (let i = 0; i < selected_items.length; i++) {
        gPasteboard.push(this.duplicate(selected_items[i]));
      }
    }
    duplicate(e) {
      return e.copy();
    }
    do_paste() {
      let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
      page2.deselectAllElements();
      this.duplicates(gPasteboard, page2);
      g_typograph_document.redraw();
    }
    duplicates(items, thepage) {
      let elements = [];
      for (let e = 0; e < items.length; e++) {
        let element3 = items[e];
        let new_element = this.duplicate(element3);
        thepage.add_page_element(new_element);
        elements.push(new_element);
      }
      return elements;
    }
    point_in_auto_layout(point) {
      let x = this.active_element.screen_rect.position.x + this.active_element.screen_rect.size.width - (30 + 10);
      let y = this.active_element.screen_rect.position.y - 24;
      let r = new Rect(new Point(x, y), new Size(30, 24));
      if (r.point_in_rect(point))
        return 0;
      let gr = this.active_element;
      for (let c = 0; c < gr.auto_layout.clips.length; c++) {
        let clip = gr.auto_layout.clips[c];
        let x2 = clip.screen_rect.position.x + clip.screen_rect.size.width;
        let y2 = clip.screen_rect.position.y + 10;
        let r2 = new Rect(new Point(x2, y2), new Size(24, 30));
        if (r2.point_in_rect(point))
          return c + 1;
      }
      return -1;
    }
    point_in_auto_layout_clip(point) {
      let gr = this.active_element;
      for (let c = 0; c < gr.auto_layout.clips.length; c++) {
        let clip = gr.auto_layout.clips[c];
        if (clip.screen_rect.point_in_rect(point))
          return c;
      }
      return -1;
    }
  };

  // src/menushortcut.ts
  var MenuShortCuts = class {
    constructor() {
      this._keydown = this.keydown.bind(this);
      this._keypress = this.keypress.bind(this);
      this._keyup = this.keyup.bind(this);
      window.addEventListener("keydown", this._keydown);
      window.addEventListener("keypress", this._keypress);
      window.addEventListener("keyup", this._keyup);
    }
    start_listling() {
      window.addEventListener("keydown", this._keydown);
      window.addEventListener("keypress", this._keypress);
      window.addEventListener("keyup", this._keyup);
    }
    stop_listling() {
      window.removeEventListener("keydown", this._keydown);
      window.removeEventListener("keypress", this._keypress);
      window.removeEventListener("keyup", this._keyup);
    }
    keydown(event) {
      if (gRichTextEditor != null && !gRichTextEditor.editor.ignore_input) {
        if (event.metaKey) {
          if (event.key != "Meta")
            this.MetaKeys(event);
          return;
        }
        let keyCode = event.keyCode;
        let keyText = "";
        if (keyCode === 13)
          keyText = "Enter";
        else if (keyCode === 8)
          keyText = "Backspace";
        else if (keyCode === 37)
          keyText = "ArrowLeft";
        else if (keyCode === 38)
          keyText = "ArrowUp";
        else if (keyCode === 39)
          keyText = "ArrowRight";
        else if (keyCode === 40)
          keyText = "ArrowDown";
        else if (keyCode === 46)
          keyText = "Delete";
        if (keyText) {
          gRichTextEditor.keyDown(keyText);
          event.preventDefault();
          g_typograph_document.redraw();
        }
      } else {
      }
      if (event.key === "Backspace" || event.key === "Delete") {
        if (gRichTextEditor)
          return;
      }
      if (event.key === " " && gRichTextEditor == null) {
      }
    }
    keypress(event) {
      if (gRichTextEditor != null && !gRichTextEditor.editor.ignore_input) {
        let ev = event;
        let code = window.event ? ev.code : event.which;
        if (code !== 8 && code !== 13 && code != 9) {
          let text = ev.key;
          gRichTextEditor.textInput(text);
          g_typograph_document.redraw();
        }
        event.preventDefault();
      } else {
        if (event.key === "Backspace" && !gRichTextEditor.editor.ignore_input) {
        }
      }
    }
    MetaKeys(e) {
      if (e.key == "n") {
        if (e.metaKey) {
          e.preventDefault();
          g_typograph_document.new_document();
        }
      }
      if (e.key == "o") {
        if (e.metaKey) {
        }
      }
      if (e.key == "s") {
        if (e.metaKey) {
        }
      }
      if (e.key === "c") {
        if (e.metaKey) {
          e.preventDefault();
          if (g_typograph_document.document_mouse_manager.active_element) {
            if (g_typograph_document.document_mouse_manager.active_element.type == "rich") {
              let el = g_typograph_document.document_mouse_manager.active_element;
              if (el.rich_text.hasFocus() && !el.rich_text.editor.ignore_input) {
                e.preventDefault();
                g_typograph_document.document_mouse_manager.copy_text();
                return;
              }
            }
          }
          g_typograph_document.document_mouse_manager.do_copy();
        }
      }
      if (e.key === "v") {
        if (e.metaKey) {
          e.preventDefault();
          if (g_typograph_document.document_mouse_manager.active_element) {
            if (g_typograph_document.document_mouse_manager.active_element.type == "rich") {
              let el = g_typograph_document.document_mouse_manager.active_element;
              if (el.rich_text.hasFocus() && !el.rich_text.editor.ignore_input) {
                e.preventDefault();
                g_typograph_document.document_mouse_manager.paste_text();
                return;
              }
            }
          }
          if (gPasteboard.length > 0)
            g_typograph_document.document_mouse_manager.do_paste();
        }
      }
      if (e.key === "z") {
        if (e.metaKey && e.shiftKey) {
        } else {
        }
      }
      if (e.key === "Backspace") {
        if (e.metaKey) {
        }
        return;
      }
      if (e.key === "a" && e.metaKey) {
        if (g_typograph_document.document_mouse_manager.active_element) {
          if (g_typograph_document.document_mouse_manager.active_element.type == "rich") {
            let el = g_typograph_document.document_mouse_manager.active_element;
            if (el.rich_text.hasFocus() && !el.rich_text.editor.ignore_input) {
              e.preventDefault();
              g_typograph_document.document_mouse_manager.select_all_text();
              return;
            }
          }
        }
        let page2 = g_typograph_document.pages[g_typograph_document.active_page_index];
        if (page2.page_elements.length > 0)
          this.selectAll();
        return;
      }
      if (e.key === "-") {
        if (e.metaKey) {
          e.preventDefault();
          g_typograph_document.zoom_out();
        }
        return;
      }
      if (e.key === "=") {
        if (e.metaKey) {
          e.preventDefault();
          g_typograph_document.zoom_in();
        }
        return;
      }
      if (e.key === "0") {
        if (e.metaKey) {
          e.preventDefault();
          g_typograph_document.resize_main_canvas();
        }
        return;
      }
      if (e.key === "1") {
        if (e.metaKey) {
          e.preventDefault();
          g_typograph_document.set_zoom(1);
        }
        return;
      }
      if (e.key === "r") {
      }
      if (e.key === ";") {
      }
      if (e.key === "h") {
        if (e.ctrlKey && e.metaKey) {
          e.preventDefault();
          g_typograph_document.toggle_show_frames();
        }
      }
      if (e.key === "]") {
      }
      if (e.key === "[") {
      }
      if (e.key === "]") {
      }
      if (e.key === "[") {
      }
      if (e.key === "g") {
      }
      if (e.key === "g") {
      }
    }
    selectAll() {
    }
    remove() {
    }
    keyup(e) {
    }
  };

  // src/pagepreviewlist.ts
  var PagePreviewList = class {
    constructor(container, options, pages) {
      this.container = container;
      this.options = options;
      this.pages = pages;
      this.previews = [];
      this.selectedids = [];
      this.call = null;
      this.addPages();
      this.selectedids.push("selector_0");
      let id = this.selectedids[0].split("_")[1];
      let element3 = document.getElementById(`selector_${id}`);
      element3.style.backgroundColor = "aliceblue";
      element3.style.borderColor = "deepskyblue";
    }
    addPages() {
      let html = "";
      this.container.innerHTML = "";
      for (let p = 0; p < this.pages.length; p++) {
        let page2 = this.pages[p];
        let id = page2.id;
        let size = this.calcSize(new Size(page2.width, page2.height));
        let prefix = this.container.id;
        prefix = prefix.split("_")[0];
        html += `<div id='selector_${p}' style='background-color:white; padding:10px; cursor:pointer; border-style:solid; border-width:1px; border-color:white'>`;
        html += `<div id='preview_${p}'>`;
        html += `<canvas id='canvas_${p}' width="${size.width}px" height="${size.height}px" style='border-style: solid; border-color: black; border-width: 1px; border-bottom-width: 0px; border-right-width: 0px;'>Your browser does not support the HTML canvas tag</canvas>`;
        if (this.options.show_page_number)
          html += `<div id='page_number'>${p + 1}</div>`;
        if (this.options.show_page_name)
          html += `<div id='page_name' style="font-family:'Source Sans Pro'" >${page2.name}</div>`;
        html += "</div></div>";
      }
      this.container.innerHTML += html;
      for (let p = 0; p < this.pages.length; p++) {
        let canvasid2 = `canvas_${p}`;
        let preview = new CanvasView(document.getElementById(canvasid2));
        preview.show_guids = false;
        preview.show_handels = false;
        preview.show_bleed = false;
        preview.zoom = this.calcScale(new Size(this.pages[p].width, this.pages[p].height));
        let dpr = window.devicePixelRatio || 1;
        preview.setupCanvas();
        preview.hidpi = true;
        this.previews.push(preview);
        if (this.options.select_page) {
          let id = `selector_${p}`;
          let element3 = document.getElementById(id);
          element3.addEventListener("click", this.page_preview_selected.bind(this));
        }
      }
      this.draw();
    }
    page_preview_selected(mouse) {
      let e = mouse.target;
      if (this.options.single_select) {
        this.selectedids = [];
        this.selectedids.push(e.id);
      } else {
        if (mouse.shiftKey) {
          this.selectedids.push(e.id);
        } else {
          this.selectedids = [];
          this.selectedids.push(e.id);
        }
      }
      let id = e.id.split("_")[1];
      for (let p = 0; p < this.pages.length; p++) {
        let id2 = `selector_${p}`;
        let element3 = document.getElementById(id2);
        element3.style.backgroundColor = "white";
        element3.style.borderColor = "white";
      }
      for (let s = 0; s < this.selectedids.length; s++) {
        let id2 = this.selectedids[s].split("_")[1];
        let element3 = document.getElementById(`selector_${id2}`);
        element3.style.backgroundColor = "aliceblue";
        element3.style.borderColor = "deepskyblue";
      }
    }
    draw() {
      for (let p = 0; p < this.pages.length; p++) {
        g_typograph_document.DefaultBrandColors();
        this.redraw(this.pages[p]);
      }
    }
    redraw(page2) {
      let view = this.previews[page2.id];
      view.context.clearRect(0, 0, view.canvas_element.width, view.canvas_element.height);
      page2.draw(view);
    }
    calcSize(size) {
      let s = this.calcScale(size);
      let w_p = size.width * gMMtoPixel;
      let h_p = size.height * gMMtoPixel;
      return new Size(w_p * s, h_p * s);
    }
    calcScale(size) {
      let max_w = 300;
      let max_h = 300;
      let w_p = size.width * gMMtoPixel;
      let h_p = size.height * gMMtoPixel;
      let s = max_w / w_p;
      if (max_h / h_p < s)
        s = max_h / h_p;
      return s;
    }
    do_ok() {
    }
    do_cancel() {
    }
  };

  // src/selecttemplatedialog.ts
  var SelectTemplateDialog = class extends DMDialog {
    constructor(type, id, dialog_manager, autolayout) {
      super(type, id, dialog_manager);
      this.type = type;
      this.id = id;
      this.dialog_manager = dialog_manager;
      this.autolayout = autolayout;
      this.templates = [];
      this.pagepreviewlist = null;
      if (this.dialog_manager.clear()) {
        this.dialog_content = this.dialog_manager.add_dialog(this, "modal", "dialog", 360, 0.6);
        this.dialog_content.style.display = "flex";
        this.dialog_content.style.flexDirection = "column";
        this.dialog_content.style.overflowY = "scroll";
        this.set_content();
        this.dialog_manager.show();
      }
    }
    set_content() {
      for (let g = 0; g < this.autolayout.templates.length; g++) {
        let group = this.autolayout.templates[g];
        let page2 = new Page(g, "single", group.native_rect.size.width, group.native_rect.size.height);
        page2.name = group.name;
        page2.add_page_element(group);
        this.templates.push(page2);
      }
      let options = { show_page_number: false, show_page_name: true, select_page: true, single_select: true };
      this.pagepreviewlist = new PagePreviewList(this.dialog_content, options, this.templates);
    }
    do_ok() {
      g_typograph_document.ok_SelectTemplateDialog(this.pagepreviewlist.selectedids);
    }
    do_cancel() {
    }
  };

  // src/typograph_document.ts
  var canvasid = "typograph_canvas";
  var document_url = "";
  var userid = "1";
  var startupscreen_html = '<div id="quotecontainer">\n<h1 id="quote_text"></h1>\n<br></br>\n<h2 id="quote_author"></h2>\n<h2 id="continue" style="opacity: 0;">Click to continue</h2>\n</div>';
  var zoomcontrol_html = '<div  id="zoomout" title="Zoom out"><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path class="primary" d="M2 8v2h14V8" fill="#fff" fill-rule="evenodd"></path></svg></div>\n<input id="zoomtext" type="text"></input>\n<div id="zoomin" title="Zoom in"><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path class="primary" d="M8 2v6H2v2h6v6h2v-6h6V8h-6V2H8z" fill="#fff" fill-rule="evenodd"></path></svg>\n</div>';
  var g_docuemnt_version = "1.0.0";
  var g_asset_url = "https://typograph.nl/";
  var g_show_element_frames = false;
  var place_holder = class {
    //let g_place_holder:place_holder = {name:"logowhite", carousel:false, images_show:[], range_limit:false, allow_upload:false, range_select:false, style_prefix:false, images_hidden:[], range_required:[], image_library_id:"logowhite", image_library_scope:"vermaat", generated_cut_contour:false, hide_placeholder_input:true, duplicate_images_forbidden:false};
    constructor() {
      this.name = "logowhite";
      this.carousel = false;
      this.images_show = [];
      this.range_limit = false;
      this.allow_upload = false;
      this.range_select = false;
      this.style_prefix = false;
      this.images_hidden = [];
      this.range_required = [];
      this.image_library_id = "logowhite";
      this.image_library_scope = "vermaat";
      this.generated_cut_contour = false;
      this.hide_placeholder_input = true;
      this.duplicate_images_forbidden = false;
    }
  };
  var g_place_holder = new place_holder();
  var typograph_document = class {
    constructor(setup) {
      this.setup = setup;
      this.canvas_view = null;
      this.pages = [];
      this.show_element_frames = false;
      this.offset_left = 0;
      this.header_height = 0;
      this.drag_selection = null;
      this.active_page_index = -1;
      this.font_manager = null;
      this.document_manager = null;
      this.dialog_manager = null;
      this.document_mouse_manager = null;
      this.menu_short_cuts = null;
      this.start_up_screen = null;
      this.vscrollbar = null;
      this.hscrollbar = null;
      this.grab = null;
      this.resize_observer = null;
      this._scroll = this.scroll.bind(this);
      if (setup.canvas_id === "")
        setup.canvas_id = canvasid;
      if (setup.document_url === "")
        setup.document_url = document_url;
      if (setup.user_id === "")
        setup.user_id = userid;
      this.DefaultBrandColors();
      this.drag_selection = new DragSelection(0, 0, 0, 0);
      this.canvas_view = new CanvasView(document.getElementById(setup.canvas_id));
      this.canvas_view.setupCanvas();
      if (setup.viewer_only) {
        this.canvas_view.canvas_element.addEventListener("mousedown", this.mouse_down.bind(this));
      }
      let element3 = document.getElementById(setup.canvas_id);
      this.offset_left = element3.clientLeft;
      this.header_height = element3.clientTop;
      let width = element3.clientWidth;
      let height = element3.clientHeight;
      let startupscreen_element = document.createElement("div");
      startupscreen_element.innerHTML = startupscreen_html;
      startupscreen_element.id = "startupscreen";
      startupscreen_element.style.position = "absolute";
      startupscreen_element.style.left = "0px";
      startupscreen_element.style.top = "0px";
      startupscreen_element.style.width = width + "px";
      startupscreen_element.style.height = height + "px";
      startupscreen_element.style.backgroundColor = "#f2f2f2";
      startupscreen_element.style.textAlign = "center";
      startupscreen_element.style.zIndex = "1000";
      startupscreen_element.style.opacity = "1.0";
      startupscreen_element.style.transition = "1.2s";
      element3 = document.getElementById("typograph_viewer");
      element3.appendChild(startupscreen_element);
      let quotecontainer_element = document.getElementById("quotecontainer");
      quotecontainer_element.style.display = "flex";
      quotecontainer_element.style.flexDirection = "column";
      quotecontainer_element.style.justifyContent = "center";
      quotecontainer_element.style.alignItems = "center";
      quotecontainer_element.style.height = "auto";
      quotecontainer_element.style.width = "60%";
      quotecontainer_element.style.margin = "auto";
      let quote_text_element = document.getElementById("quote_text");
      quote_text_element.style.fontFamily = "Source Sans Pro";
      quote_text_element.style.fontSize = "28px";
      quote_text_element.style.fontWeight = "600";
      quote_text_element.style.margin = "0px";
      quote_text_element.style.color = "#9a9fa7";
      let quote_author_element = document.getElementById("quote_author");
      quote_author_element.style.fontFamily = "Source Sans Pro";
      quote_author_element.style.fontSize = "16px";
      quote_author_element.style.fontStyle = "italic";
      quote_author_element.style.fontWeight = "400";
      quote_author_element.style.color = "black";
      quote_author_element.style.margin = "0px";
      quote_author_element.style.marginBottom = "20px";
      let continue_element = document.getElementById("continue");
      continue_element.style.fontFamily = "Source Sans Pro";
      continue_element.style.fontSize = "16px";
      continue_element.style.fontStyle = "italic";
      continue_element.style.fontWeight = "400";
      continue_element.style.color = "black";
      continue_element.style.margin = "0px";
      continue_element.style.marginBottom = "20px";
      this.start_up_screen = new Startupscreen("startupscreen", "0", false);
      let h = quotecontainer_element.clientHeight;
      if (h < height) {
        let margin = (height - h) / 3;
        quotecontainer_element.style.marginTop = margin + "px";
      }
      window.addEventListener("resize", this.resize_main_canvas.bind(this));
    }
    set_assets_url(url) {
      g_asset_url = url;
    }
    get_assets_url() {
      return g_asset_url;
    }
    setup_fontmanager() {
      this.font_manager = new FontManager(this.start_up_screen._finishedloading);
    }
    setup_documentmanager() {
      this.document_manager = new DocumentManager();
    }
    setup_dialogmanager(parent_id) {
      try {
        this.dialog_manager = new DialogManger(parent_id);
      } catch (err) {
        console.log("Error setup_dialog_manager: " + err);
      }
    }
    setup_documentmousemanager() {
      try {
        this.document_mouse_manager = new DocumentMouseManager();
      } catch (err) {
        console.log("Error setup_documentmousemanager: " + err);
      }
    }
    setup_menushortcuts() {
      try {
        this.menu_short_cuts = new MenuShortCuts();
      } catch (err) {
        console.log("Error setup_menushortcuts: " + err);
      }
    }
    setup_scollbars() {
      if (!document.getElementById("vertical")) {
        let scrollelement = document.createElement("div");
        scrollelement.id = "vertical";
        scrollelement.style.position = "absolute";
        scrollelement.style.top = "0px";
        scrollelement.style.right = "4px";
        scrollelement.style.width = "8px";
        scrollelement.style.bottom = "15px";
        scrollelement.style.opacity = "0.3";
        scrollelement.style.display = "none";
        let thumbelement = document.createElement("div");
        thumbelement.id = "v_thumb";
        thumbelement.style.position = "absolute";
        thumbelement.style.top = "0px";
        thumbelement.style.left = "0px";
        thumbelement.style.width = "100%";
        thumbelement.style.height = "50%";
        thumbelement.style.borderRadius = "4px";
        thumbelement.style.backgroundColor = "#000";
        thumbelement.style.border = "1px solid #fff";
        scrollelement.appendChild(thumbelement);
        document.getElementById("typograph_viewer").appendChild(scrollelement);
      }
      if (!document.getElementById("horizontal")) {
        let scrollelement = document.createElement("div");
        scrollelement.id = "horizontal";
        scrollelement.style.position = "absolute";
        scrollelement.style.left = "0px";
        scrollelement.style.right = "0px";
        scrollelement.style.height = "8px";
        scrollelement.style.bottom = "4px";
        scrollelement.style.opacity = "0.3";
        scrollelement.style.display = "none";
        let thumbelement = document.createElement("div");
        thumbelement.id = "h_thumb";
        thumbelement.style.position = "absolute";
        thumbelement.style.left = "0px";
        thumbelement.style.top = "0px";
        thumbelement.style.width = "50%";
        thumbelement.style.height = "100%";
        thumbelement.style.borderRadius = "4px";
        thumbelement.style.backgroundColor = "#000";
        thumbelement.style.border = "1px solid #fff";
        scrollelement.appendChild(thumbelement);
        document.getElementById("typograph_viewer").appendChild(scrollelement);
      }
      let v_size = 100 * gMMtoScreen * this.canvas_view.zoom;
      let vs = document.getElementById("vertical");
      let vs_t = document.getElementById("v_thumb");
      this.vscrollbar = new Scrollbar(vs, vs_t, document.getElementById("typograph_viewer"), v_size, "v", 100);
      let h_size = 100 * gMMtoScreen * this.canvas_view.zoom;
      let hs = document.getElementById("horizontal");
      let hs_t = document.getElementById("h_thumb");
      this.hscrollbar = new Scrollbar(hs, hs_t, document.getElementById("typograph_viewer"), h_size, "h", 100);
      this.grab = new Grab([this.vscrollbar, this.hscrollbar]);
      this.start_scroll();
    }
    start_scroll() {
      document.getElementById("typograph_viewer").addEventListener("wheel", this._scroll);
    }
    stop_scroll() {
      document.getElementById("typograph_viewer").removeEventListener("wheel", this._scroll);
    }
    setup_zoom() {
      let zoomcontrol = document.createElement("div");
      zoomcontrol.id = "zoomcontrol";
      zoomcontrol.innerHTML = zoomcontrol_html;
      zoomcontrol.title = "Zoom tool";
      zoomcontrol.style.position = "absolute";
      zoomcontrol.style.right = "40px";
      zoomcontrol.style.bottom = "40px";
      zoomcontrol.style.height = "40px";
      zoomcontrol.style.display = "flex";
      zoomcontrol.style.borderRadius = "4px";
      zoomcontrol.style.backgroundColor = "#282c33";
      zoomcontrol.style.boxShadow = "0 2px 10px 0 rgbs(0,0,0,0.2)";
      zoomcontrol.style.alignItems = "center";
      zoomcontrol.style.justifyContent = "center";
      zoomcontrol.style.opacity = "0.4";
      zoomcontrol.style.transition = "0.5s";
      {
        zoomcontrol.addEventListener("mouseenter", this.mouse_enter_zoomcontrol.bind(this));
        zoomcontrol.addEventListener("mouseleave", this.mouse_leave_zoomcontrol.bind(this));
      }
      let element3 = document.getElementById("typograph_viewer");
      element3.appendChild(zoomcontrol);
      let zoomout = document.getElementById("zoomout");
      zoomout.style.cursor = "pointer";
      zoomout.style.marginLeft = "10px";
      zoomout.style.marginRight = "10px";
      zoomout.style.marginTop = "6px";
      let zoomcontrolinput = document.getElementById("zoomtext");
      zoomcontrolinput.style.borderRadius = "4px";
      zoomcontrolinput.style.border = "1px solid #a9afb8";
      zoomcontrolinput.style.backgroundColor = "#282c33";
      zoomcontrolinput.style.color = "#fff";
      zoomcontrolinput.style.fontFamily = "Source Sans Pro, sans-serif";
      zoomcontrolinput.style.fontSize = "14px";
      zoomcontrolinput.style.height = "22px";
      zoomcontrolinput.style.width = "42px";
      zoomcontrolinput.style.textAlign = "center";
      zoomcontrolinput.style.margin = "0px";
      {
        let css = ":focus{ outline: 0}";
        let style = document.createElement("style");
        style.appendChild(document.createTextNode(css));
        zoomcontrolinput.appendChild(style);
      }
      let zoomin = document.getElementById("zoomin");
      zoomin.style.cursor = "pointer";
      zoomin.style.marginLeft = "10px";
      zoomin.style.marginRight = "10px";
      zoomin.style.marginTop = "6px";
      zoomcontrolinput.addEventListener("change", this.get_zoom.bind(this));
      zoomout.addEventListener("click", this.zoom_out.bind(this));
      zoomin.addEventListener("click", this.zoom_in.bind(this));
    }
    set_canvas_id(canvas_id) {
      this.setup.canvas_id = canvas_id;
    }
    set_url(url) {
      this.setup.document_url = url;
    }
    set_user_id(user_id) {
      this.setup.user_id = user_id;
    }
    get_canvas_id() {
      return this.setup.canvas_id;
    }
    get_url() {
      return this.setup.document_url;
    }
    get_user_id() {
      return this.setup.user_id;
    }
    DefaultBrandColors() {
      this.brand_colors = new colorlibrary();
      this.brand_colors.colors = [];
      this.brand_colors.addNameRGBA("COLOR_1", "#fff");
      this.brand_colors.addNameRGBA("COLOR_2", "#000");
      this.brand_colors.addNameRGBA("COLOR_3", "#ddd");
    }
    /**
     *
     *
     *
     * @param callback
     * callback gets the document data as string
     *
     * @description
     * call with callback to get the document data as string...
     */
    create_document_data(f) {
      let data = this.get_document_data_as_string();
      f(data);
    }
    /**
     * @description
     * document_data is for creating pdf...
     * @param creation_date default current data (as iso)
     * @param name default is 'untitled'
     * @param author default is 'jsgd_viewer'
     *
     *
     * @returns document_data as string
     */
    get_document_data_as_string(creation_date = (/* @__PURE__ */ new Date()).toISOString(), name = "untitled", author = "jsgd_viewer") {
      let meta_data = { name, creation_date, modification_date: creation_date, author };
      let backuprichtext_data = this.backup_richtext();
      let menuez_user_data = { "image_placeholder": g_place_holder };
      this.set_user_data("logoWhite", menuez_user_data);
      let document_data = { version: g_docuemnt_version, meta_data, font_data: this.font_manager.fontdata, brand_colors: this.brand_colors, pages: this.pages, active_page_index: this.active_page_index };
      let data = JSON.stringify(document_data);
      this.restore_richtext(backuprichtext_data);
      return data;
    }
    set_user_data(search_name, value) {
      search_name = search_name.toLowerCase();
      for (let p = 0; p < this.pages.length; p++) {
        let page2 = this.pages[p];
        for (let e = 0; e < page2.page_elements.length; e++) {
          let element3 = page2.page_elements[e];
          let name = element3.name.toLowerCase();
          if (name == search_name) {
            element3.user_data = value;
          }
        }
      }
    }
    backup_richtext() {
      let backup_temp_richtext = [];
      let backup_temp_group_richtext = [];
      let backup_richtext = [];
      let backup_group_richtext = [];
      let backup_auto_layout = [];
      for (let p = 0; p < this.pages.length; p++) {
        let page2 = this.pages[p];
        for (let e = 0; e < page2.temp_page_elements.length; e++) {
          let element3 = page2.temp_page_elements[e];
          if (element3.type == "rich") {
            let richtextelement = element3;
            richtextelement.save_elements = [];
            richtextelement.save_lines = [];
            let rich_text = richtextelement.rich_text;
            let backup = { page_index: p, page_element_index: e, rich_text };
            backup_temp_richtext.push(backup);
            let text = "";
            for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
              let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
              for (let w = 0; w < rtel.words.length; w++) {
                rtel.words[w].element = null;
              }
              richtextelement.save_elements.push(rtel);
              text += richtextelement.rich_text.editor.elementmanager.elements[t].text;
              if (t + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                text += "\r";
            }
            richtextelement.text = text;
            for (let l = 0; l < richtextelement.rich_text.editor.lines.length; l++) {
              let line3 = richtextelement.rich_text.editor.lines[l];
              richtextelement.save_lines.push(line3);
            }
            richtextelement.rich_text = null;
          }
          if (element3.type == "group") {
            let group = element3;
            for (let e2 = 0; e2 < group.page_elements.length; e2++) {
              let el = group.page_elements[e2];
              if (el.type == "rich") {
                let richtextelement = el;
                richtextelement.save_elements = [];
                let rich_text = richtextelement.rich_text;
                let backup = { element: richtextelement, rich_text };
                backup_temp_group_richtext.push(backup);
                let text = "";
                for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
                  let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
                  for (let w = 0; w < rtel.words.length; w++) {
                    rtel.words[w].element = null;
                  }
                  richtextelement.save_elements.push(rtel);
                  text += richtextelement.rich_text.editor.elementmanager.elements[t].text;
                  if (t + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                    text += "\r";
                }
                richtextelement.text = text;
                for (let l = 0; l < richtextelement.rich_text.editor.lines.length; l++) {
                  let line3 = richtextelement.rich_text.editor.lines[l];
                  richtextelement.save_lines.push(line3);
                }
                richtextelement.rich_text = null;
              }
            }
            if (group.auto_layout != null) {
              let auto_layout = group.auto_layout;
              let template_element = [];
              let template_richtext = [];
              let clip_element = [];
              let clip_richtext = [];
              for (let t = 0; t < auto_layout.templates.length; t++) {
                let gr = auto_layout.templates[t];
                for (let e2 = 0; e2 < gr.page_elements.length; e2++) {
                  let el = gr.page_elements[e2];
                  if (el.type == "rich") {
                    let richtextelement = el;
                    richtextelement.save_elements = [];
                    let richtext = richtextelement.rich_text;
                    template_element.push(richtextelement);
                    template_richtext.push(richtext);
                    let text = "";
                    for (let t2 = 0; t2 < richtextelement.rich_text.editor.elementmanager.elements.length; t2++) {
                      let rtel = richtextelement.rich_text.editor.elementmanager.elements[t2];
                      for (let w = 0; w < rtel.words.length; w++) {
                        rtel.words[w].element = null;
                      }
                      richtextelement.save_elements.push(rtel);
                      text += richtextelement.rich_text.editor.elementmanager.elements[t2].text;
                      if (t2 + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                        text += "\r";
                    }
                    richtextelement.text = text;
                    richtextelement.rich_text = null;
                  }
                }
              }
              for (let t = 0; t < auto_layout.clips.length; t++) {
                let gr = auto_layout.clips[t];
                for (let e2 = 0; e2 < gr.page_elements.length; e2++) {
                  let el = gr.page_elements[e2];
                  if (el.type == "rich") {
                    let richtextelement = el;
                    richtextelement.save_elements = [];
                    let richtext = richtextelement.rich_text;
                    clip_element.push(richtextelement);
                    clip_richtext.push(richtext);
                    let text = "";
                    for (let t2 = 0; t2 < richtextelement.rich_text.editor.elementmanager.elements.length; t2++) {
                      let rtel = richtextelement.rich_text.editor.elementmanager.elements[t2];
                      for (let w = 0; w < rtel.words.length; w++) {
                        rtel.words[w].element = null;
                      }
                      richtextelement.save_elements.push(rtel);
                      text += richtextelement.rich_text.editor.elementmanager.elements[t2].text;
                      if (t2 + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                        text += "\r";
                    }
                    richtextelement.text = text;
                    richtextelement.rich_text = null;
                  }
                }
              }
              backup_auto_layout.push({ template_element, template_richttext: template_richtext, clip_element, clip_richttext: clip_richtext });
            }
          }
        }
        for (let e = 0; e < page2.page_elements.length; e++) {
          let element3 = page2.page_elements[e];
          element3.rotation_cx = 0;
          element3.rotation_cy = 0;
          if (element3.type == "rich") {
            let richtextelement = element3;
            richtextelement.save_elements = [];
            richtextelement.save_lines = [];
            let rich_text = richtextelement.rich_text;
            let backup = { page_index: p, page_element_index: e, rich_text };
            backup_richtext.push(backup);
            let text = "";
            for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
              let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
              for (let w = 0; w < rtel.words.length; w++) {
                rtel.words[w].element = null;
              }
              richtextelement.save_elements.push(rtel);
              text += richtextelement.rich_text.editor.elementmanager.elements[t].text;
              if (t + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                text += "\r";
            }
            richtextelement.text = text;
            for (let l = 0; l < richtextelement.rich_text.editor.lines.length; l++) {
              let line3 = richtextelement.rich_text.editor.lines[l];
              richtextelement.save_lines.push(line3);
            }
            richtextelement.rich_text = null;
          }
          if (element3.type == "group") {
            let group = element3;
            for (let e2 = 0; e2 < group.page_elements.length; e2++) {
              let el = group.page_elements[e2];
              el.rotation_cx = 0;
              el.rotation_cy = 0;
              if (el.type == "rich") {
                let richtextelement = el;
                richtextelement.save_elements = [];
                richtextelement.save_lines = [];
                let rich_text = richtextelement.rich_text;
                let backup = { element: richtextelement, rich_text };
                backup_group_richtext.push(backup);
                let text = "";
                for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
                  let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
                  for (let w = 0; w < rtel.words.length; w++) {
                    rtel.words[w].element = null;
                  }
                  richtextelement.save_elements.push(rtel);
                  text += richtextelement.rich_text.editor.elementmanager.elements[t].text;
                  if (t + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                    text += "\r";
                }
                richtextelement.text = text;
                for (let l = 0; l < richtextelement.rich_text.editor.lines.length; l++) {
                  let line3 = richtextelement.rich_text.editor.lines[l];
                  richtextelement.save_lines.push(line3);
                }
                richtextelement.rich_text = null;
              }
            }
            if (group.auto_layout != null) {
              let auto_layout = group.auto_layout;
              let template_element = [];
              let template_richtext = [];
              let clip_element = [];
              let clip_richtext = [];
              for (let t = 0; t < auto_layout.templates.length; t++) {
                let gr = auto_layout.templates[t];
                gr.rotation_cx = 0;
                gr.rotation_cy = 0;
                for (let e2 = 0; e2 < gr.page_elements.length; e2++) {
                  let el = gr.page_elements[e2];
                  el.rotation_cx = 0;
                  el.rotation_cy = 0;
                  if (el.type == "rich") {
                    let richtextelement = el;
                    richtextelement.save_elements = [];
                    richtextelement.save_lines = [];
                    let richtext = richtextelement.rich_text;
                    template_element.push(richtextelement);
                    template_richtext.push(richtext);
                    let text = "";
                    for (let t2 = 0; t2 < richtextelement.rich_text.editor.elementmanager.elements.length; t2++) {
                      let rtel = richtextelement.rich_text.editor.elementmanager.elements[t2];
                      for (let w = 0; w < rtel.words.length; w++) {
                        rtel.words[w].element = null;
                      }
                      richtextelement.save_elements.push(rtel);
                      text += richtextelement.rich_text.editor.elementmanager.elements[t2].text;
                      if (t2 + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                        text += "\r";
                    }
                    richtextelement.text = text;
                    for (let l = 0; l < richtextelement.rich_text.editor.lines.length; l++) {
                      let line3 = richtextelement.rich_text.editor.lines[l];
                      richtextelement.save_lines.push(line3);
                    }
                    richtextelement.rich_text = null;
                  }
                }
              }
              for (let t = 0; t < auto_layout.clips.length; t++) {
                let gr = auto_layout.clips[t];
                gr.rotation_cx = 0;
                gr.rotation_cy = 0;
                for (let e2 = 0; e2 < gr.page_elements.length; e2++) {
                  let el = gr.page_elements[e2];
                  el.rotation_cx = 0;
                  el.rotation_cy = 0;
                  if (el.type == "rich") {
                    let richtextelement = el;
                    richtextelement.save_elements = [];
                    richtextelement.save_lines = [];
                    let richtext = richtextelement.rich_text;
                    clip_element.push(richtextelement);
                    clip_richtext.push(richtext);
                    let text = "";
                    for (let t2 = 0; t2 < richtextelement.rich_text.editor.elementmanager.elements.length; t2++) {
                      let rtel = richtextelement.rich_text.editor.elementmanager.elements[t2];
                      for (let w = 0; w < rtel.words.length; w++) {
                        rtel.words[w].element = null;
                      }
                      richtextelement.save_elements.push(rtel);
                      text += richtextelement.rich_text.editor.elementmanager.elements[t2].text;
                      if (t2 + 1 < richtextelement.rich_text.editor.elementmanager.elements.length)
                        text += "\r";
                    }
                    richtextelement.text = text;
                    for (let l = 0; l < richtextelement.rich_text.editor.lines.length; l++) {
                      let line3 = richtextelement.rich_text.editor.lines[l];
                      richtextelement.save_lines.push(line3);
                    }
                    richtextelement.rich_text = null;
                  }
                }
              }
              backup_auto_layout.push({ template_element, template_richttext: template_richtext, clip_element, clip_richttext: clip_richtext });
            }
          }
        }
      }
      let backuprichtext_data = { backup_temp_richtext, backup_temp_group_richtext, backup_richtext, backup_group_richtext, backup_autolayout_richtext: backup_auto_layout };
      return backuprichtext_data;
    }
    restore_richtext(backuprichtext_data) {
      let backup_temp_richtext = backuprichtext_data.backup_temp_richtext;
      let backup_temp_group_richtext = backuprichtext_data.backup_temp_group_richtext;
      let backup_richtext = backuprichtext_data.backup_richtext;
      let backup_group_richtext = backuprichtext_data.backup_group_richtext;
      let backup_auto_layout = backuprichtext_data.backup_autolayout_richtext;
      for (let b = 0; b < backup_temp_richtext.length; b++) {
        let backup = backup_temp_richtext[b];
        let richtextelement = this.pages[backup.page_index].temp_page_elements[backup.page_element_index];
        richtextelement.rich_text = backup.rich_text;
        for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
          let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
          for (let w = 0; w < rtel.words.length; w++) {
            rtel.words[w].element = rtel;
          }
        }
      }
      for (let b = 0; b < backup_temp_group_richtext.length; b++) {
        let backup = backup_temp_group_richtext[b];
        let richtextelement = backup.element;
        richtextelement.rich_text = backup.rich_text;
        for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
          let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
          for (let w = 0; w < rtel.words.length; w++) {
            rtel.words[w].element = rtel;
          }
        }
      }
      for (let b = 0; b < backup_richtext.length; b++) {
        let backup = backup_richtext[b];
        let richtextelement = this.pages[backup.page_index].page_elements[backup.page_element_index];
        richtextelement.rich_text = backup.rich_text;
        for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
          let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
          for (let w = 0; w < rtel.words.length; w++) {
            rtel.words[w].element = rtel;
          }
        }
      }
      for (let b = 0; b < backup_group_richtext.length; b++) {
        let backup = backup_group_richtext[b];
        let richtextelement = backup.element;
        richtextelement.rich_text = backup.rich_text;
        for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
          let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
          for (let w = 0; w < rtel.words.length; w++) {
            rtel.words[w].element = rtel;
          }
        }
      }
      for (let b = 0; b < backup_auto_layout.length; b++) {
        let backup = backup_auto_layout[b];
        for (let e = 0; e < backup.template_element.length; e++) {
          let richtextelement = backup.template_element[e];
          richtextelement.rich_text = backup.template_richttext[e];
          for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
            let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
            for (let w = 0; w < rtel.words.length; w++) {
              rtel.words[w].element = rtel;
            }
          }
        }
        for (let e = 0; e < backup.clip_richttext.length; e++) {
          let richtextelement = backup.clip_element[e];
          richtextelement.rich_text = backup.clip_richttext[e];
          for (let t = 0; t < richtextelement.rich_text.editor.elementmanager.elements.length; t++) {
            let rtel = richtextelement.rich_text.editor.elementmanager.elements[t];
            for (let w = 0; w < rtel.words.length; w++) {
              rtel.words[w].element = rtel;
            }
          }
        }
      }
    }
    get_brand_colors() {
      return this.brand_colors;
    }
    set_show_element_frames(show) {
      this.show_element_frames = show;
    }
    get_show_element_frames() {
      return this.show_element_frames;
    }
    get_drag_selection() {
      return this.drag_selection;
    }
    get_view() {
      return this.canvas_view;
    }
    get_active_page() {
      return this.pages[this.active_page_index];
    }
    get_font_manager() {
      return this.font_manager;
    }
    new_document() {
      this.pages = this.document_manager.NewDocument();
      this.active_page_index = 0;
      this.resize_main_canvas();
      this.redraw();
    }
    open_document(documentjson) {
      this.document_manager.OpenDocument(documentjson);
      this.active_page_index = 0;
    }
    load_document(filename) {
      this.document_manager.LoadDocument(filename);
    }
    create_group() {
    }
    remove_group(group) {
    }
    create_temp_selection() {
    }
    /**
     * Posible recuresive moving of a group (should be in PagElementGroup...)
     * @param offset_x
     * @param offset_y
     */
    offset_group_elements(group_element, offset_x, offset_y) {
      group_element.native_rect.position.x += offset_x;
      group_element.native_rect.position.y += offset_y;
      for (let e = 0; e < group_element.page_elements.length; e++) {
        let element3 = group_element.page_elements[e];
        element3.native_rect.position.x += offset_x;
        element3.native_rect.position.y += offset_y;
        if (element3.type == "image") {
          let image_element = element3;
          image_element.native_image_rect.position.x += offset_x;
          image_element.native_image_rect.position.y += offset_y;
        }
        if (element3.type == "group") {
          this.offset_group_elements(element3, offset_x, offset_y);
        }
      }
    }
    resize_main_canvas(entries = null, observer = null) {
      let canvas_div = document.getElementById("typograph_viewer");
      if (this.canvas_view.hidpi) {
        this.canvas_view.canvas_element.style.width = canvas_div.clientWidth + "px";
        this.canvas_view.canvas_element.style.height = canvas_div.clientHeight + "px";
        this.canvas_view.setupCanvas();
      } else {
        this.canvas_view.canvas_element.width = canvas_div.clientWidth;
        this.canvas_view.canvas_element.height = canvas_div.clientHeight;
      }
      let page2 = this.pages[this.active_page_index];
      let localpagewidth = page2.native_size.width * gMMtoScreen + 100;
      let localpageheight = page2.native_size.height * gMMtoScreen + 80;
      let zoom = 1;
      if (this.canvas_view.hidpi) {
        zoom = this.canvas_view.canvas_element.width / 2 / localpagewidth;
        if (zoom > this.canvas_view.canvas_element.height / 2 / localpageheight)
          zoom = this.canvas_view.canvas_element.height / 2 / localpageheight;
      } else {
        zoom = this.canvas_view.canvas_element.width / localpagewidth;
        if (zoom > this.canvas_view.canvas_element.height / localpageheight)
          zoom = this.canvas_view.canvas_element.height / localpageheight;
      }
      this.canvas_view.zoom = zoom;
      let w = page2.native_size.width * gMMtoScreen * zoom;
      let h = page2.native_size.height * gMMtoScreen * zoom;
      if (this.canvas_view.hidpi) {
        this.canvas_view.offset_x = (this.canvas_view.canvas_element.width / 2 - w) / 2;
        this.canvas_view.offset_y = (this.canvas_view.canvas_element.height / 2 - h) / 2;
      } else {
        this.canvas_view.offset_x = (this.canvas_view.canvas_element.width - w) / 2;
        this.canvas_view.offset_y = (this.canvas_view.canvas_element.height - h) / 2;
      }
      page2.setScreenSize(this.canvas_view);
      this.set_zoom(zoom);
    }
    redraw() {
      let view = this.canvas_view;
      view.context.fillStyle = "#979ea8";
      view.context.fillRect(0, 0, view.canvas_element.width, view.canvas_element.height);
      view.context.fillStyle = "#fff";
      let page2 = this.pages[this.active_page_index];
      page2.draw(view);
    }
    scroll(e) {
      if (!e.ctrlKey) {
        if (e.deltaY != 0)
          this.vscrollbar.scroll(e);
        if (e.deltaX != 0)
          this.hscrollbar.scroll(e);
      } else {
        this.zoom_chrome(e.deltaY);
      }
    }
    mouse_down(e) {
      let clickX = e.clientX;
      let clickY = e.clientY;
      let point = new Point(e.clientX, e.clientY);
      this.canvas_view.canvas_element.style.cursor = "grab";
      this.grab.mouseDown(point, clickX, clickY);
    }
    mouse_enter_zoomcontrol(e) {
      let zoomcontrol = document.getElementById("zoomcontrol");
      zoomcontrol.style.opacity = "1.0";
    }
    mouse_leave_zoomcontrol(e) {
      let zoomcontrol = document.getElementById("zoomcontrol");
      zoomcontrol.style.opacity = "0.4";
    }
    zoom_chrome(scale) {
    }
    zoom(e) {
      this.set_zoom(e.scale);
    }
    zoom_out() {
      let element3 = document.getElementById("typograph_viewer");
      let scx = element3.clientWidth / 2;
      let spcx = scx - this.canvas_view.offset_x;
      let scy = element3.clientHeight / 2;
      let spcy = scy - this.canvas_view.offset_y;
      this.canvas_view.offset_x = this.canvas_view.offset_x + spcx / 2;
      this.canvas_view.offset_y = this.canvas_view.offset_y + spcy / 2;
      this.set_zoom(this.canvas_view.zoom / 2);
    }
    zoom_in() {
      let element3 = document.getElementById("typograph_viewer");
      let scx = element3.clientWidth / 2;
      let spcx = scx - this.canvas_view.offset_x;
      let scy = element3.clientHeight / 2;
      let spcy = scy - this.canvas_view.offset_y;
      this.canvas_view.offset_x = this.canvas_view.offset_x - spcx;
      this.canvas_view.offset_y = this.canvas_view.offset_y - spcy;
      this.set_zoom(this.canvas_view.zoom * 2);
    }
    /**
     *
     * @description Set the zoom value of the canvasview and also the Zoom Control text ('zoomtext')
     * @param z range (1.0 is 100%)
     */
    set_zoom(z) {
      this.canvas_view.zoom = z;
      let zoom = document.getElementById("zoomtext");
      zoom.value = this.canvas_view.zoom * 100 + "";
      let page2 = this.pages[this.active_page_index];
      for (let i = 0; i < page2.page_elements.length; i++) {
        let element3 = page2.page_elements[i];
        element3.rotation_cx *= this.canvas_view.zoom;
        element3.rotation_cy *= this.canvas_view.zoom;
      }
      if (this.vscrollbar) {
        let vsize = page2.native_size.height * gMMtoScreen * this.canvas_view.zoom;
        this.vscrollbar.resize(vsize);
      }
      if (this.hscrollbar) {
        let hsize = page2.native_size.width * gMMtoScreen * this.canvas_view.zoom;
        this.hscrollbar.resize(hsize);
      }
      this.redraw();
    }
    /**
     * @description Set the zoom of the canvasview based on the Zoom Control 'zoomtext'
     *
     * @returns current zoom value of the canvasview
     */
    get_zoom() {
      let zoom = document.getElementById("zoomtext");
      this.canvas_view.zoom = parseFloat(zoom.value);
      this.canvas_view.zoom /= 100;
      this.redraw();
      return this.canvas_view.zoom;
    }
    toggle_show_frames() {
      if (g_show_element_frames)
        g_show_element_frames = false;
      else
        g_show_element_frames = true;
      this.redraw();
    }
    do_select_template(group, ok_callback = this.ok_SelectTemplateDialog) {
      let dialog = new SelectTemplateDialog("dialog", "1", this.dialog_manager, group.auto_layout);
      this.grab.stop();
    }
    ok_SelectTemplateDialog(ids) {
      let group = this.document_mouse_manager.active_element;
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i].split("_")[1];
        group.add_clip(+id);
      }
      this.redraw();
    }
    set_undo() {
    }
    set_redo() {
    }
    generateUUID() {
      var d = (/* @__PURE__ */ new Date()).getTime();
      var d2 = typeof performance !== "undefined" && performance.now && performance.now() * 1e3 || 0;
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : r & 3 | 8).toString(16);
      });
    }
  };

  // src/typograph.ts
  // var g_typograph_document = null;
  // var g_dialog_layer;
  // window.addEventListener("load", typograph_init);
  // function typograph_init() {
  //   let canvas_div = document.getElementById("typograph_viewer");
  //   let canvas_element = document.createElement("canvas");
  //   canvas_element.id = "typograph_canvas";
  //   canvas_element.style.width = canvas_div.clientWidth + "px";
  //   canvas_element.style.height = canvas_div.clientHeight + "px";
  //   canvas_div.appendChild(canvas_element);
  //   let setup = { canvas_id: "", document_url: "", user_id: "", viewer_only: false };
  //   g_typograph_document = new typograph_document(setup);
  //   g_typograph_document.setup_fontmanager();
  //   g_typograph_document.setup_documentmanager();
  //   g_typograph_document.setup_scollbars();
  //   g_typograph_document.setup_zoom();
  //   g_typograph_document.setup_documentmousemanager();
  //   g_typograph_document.setup_menushortcuts();
  //   g_typograph_document.setup_dialogmanager("typograph_viewer");
  //   window.typograph_document = g_typograph_document;
  // }
// })();
//!el.horizontal
export { typograph_document };
